import { Injectable } from '@angular/core';
import {environment} from '../../../../../../environments';
import {HttpClient} from '@angular/common/http';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {
  MeterActiveCheck,
  MeterAddUpdateModel,
  MeterDetailsV4Model,
  MeterV4Model
} from '../../../_modules/portfolio/_model/portfolio-meters.model';
import {ActivationCheck, DeleteCheck} from '../../_model/customer-v4.model';
import {MeterZoneChangeRequest} from '../../_model/meter-zone.model';
import {MeterRateClassChangeRequest} from '../../_model/meter-rate-class.model';

@Injectable({
  providedIn: 'root'
})
export class MetersV4Service {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient, private orgMgtSvc: OrganizationManagementService) { }

  getMeterDetailsById(customerId: number, propertyId: number, meterId: number): Observable<MeterDetailsV4Model> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/properties/${propertyId}/meters/${meterId}/details`;
    return this.http.get<MeterDetailsV4Model>(url).pipe(take(1));
  }

  getMeterById(customerId: number, propertyId: number, meterId: number): Observable<MeterV4Model> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/properties/${propertyId}/meters/${meterId}`;
    return this.http.get<MeterV4Model>(url).pipe(take(1));
  }

  getAllMetersInProperty(customerId: number, propertyId: number): Observable<MeterV4Model[]> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/properties/${propertyId}/meters`;
    return this.http.get<MeterV4Model[]>(url).pipe(take(1));
  }

  addMeter(customerId: number, propertyId: number, data: MeterAddUpdateModel): Observable<MeterDetailsV4Model> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/properties/${propertyId}/meters`;
    return this.http.post<MeterDetailsV4Model>(url, data).pipe(take(1));
  }

  updateMeter(customerId: number, propertyId: number, meterId: number, data: MeterAddUpdateModel): Observable<MeterDetailsV4Model> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/properties/${propertyId}/meters/${meterId}`;
    return this.http.put<MeterDetailsV4Model>(url, data).pipe(take(1));
  }

  deleteMeterById(customerId: number, propertyId: number, meterId: number): Observable<void> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/properties/${propertyId}/meters/${meterId}`;
    return this.http.delete<void>(url).pipe(take(1));
  }

  bulkDeleteCheckMeter(customerId: number, propertyId: number, ids: number[]): Observable<DeleteCheck> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/properties/${propertyId}/meters/bulk/delete-check`;
    return this.http.post<DeleteCheck>(url, { ids }).pipe(take(1));
  }

  bulkDeleteMeters(customerId: number, propertyId: number, ids: number[]): Observable<DeleteCheck> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/properties/${propertyId}/meters/bulk/delete`;
    return this.http.post<DeleteCheck>(url, { ids }).pipe(take(1));
  }

  /**
   * Bulk Activation Check Meters - Check to see if provided Meters can be Reactivated
   * Payload -> {customerId: number, propertyId: number, ids: string[]} -> where applicable (Meters)
   * @param customerId
   * @param propertyId
   * @param ids
   */
  bulkActivationCheckMeters(customerId: number, propertyId: number, ids: number[]): Observable<ActivationCheck> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/customers/${customerId}/properties/${propertyId}/meters/bulk/activation-check`;
    return this.http.post<ActivationCheck>(url, { ids }).pipe(take(1));
  }

  /**
   * Bulk change status meters - Deactivate or reactivate meters using the table or row action toggles
   * Payload -> {customerId: number, propertyId: number, ids: number[]} -> where applicable (properties)
   * @param customerId
   * @param propertyId
   * @param meterIds
   * @param active
   */
  bulkChangeStatusMeters(customerId: number, propertyId: number, meterIds: number[], active: boolean) {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/customers/${customerId}/properties/${propertyId}/meters/bulk/activation`;
    return this.http.put(url, {ids: meterIds, active: active}).pipe(take(1));
  }

  checkActiveMeterExistsByCustomerId(customerId: number): Observable<MeterActiveCheck> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/active-meters-check`;
    return this.http.get<MeterActiveCheck>(url).pipe(take(1));
  }

  checkActiveMeterExistsAtOrgLevel(): Observable<MeterActiveCheck> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/active-meters-check`;
    return this.http.get<MeterActiveCheck>(url).pipe(take(1));
  }

  /**
   * This service used to make bulk updates to the meter's rate class according to grouping and utility
   * @param customerId
   * @param updateRequest
   */
  bulkChangeRateClassMeters(customerId: number, updateRequest: MeterRateClassChangeRequest): Observable<MeterDetailsV4Model[]> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/meters/bulk/rate-schedule`;
    return this.http.put<MeterDetailsV4Model[]>(url, updateRequest).pipe(take(1));
  }

  /**
   * This service used to make both single and bulk updates to the meter's zone according to grouping and property zip code
   * The applyToAllMatchingZipCodes flag is used to determing whether it is a single or bulk update
   * @param customerId
   * @param updateRequest
   */
  bulkChangeZoneMeters(customerId: number, updateRequest: MeterZoneChangeRequest): Observable<MeterDetailsV4Model[]> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/meters/bulk/zone`;
    return this.http.put<MeterDetailsV4Model[]>(url, updateRequest).pipe(take(1));
  }
}
