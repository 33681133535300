import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  ButtonActionTypes,
  ButtonTypes,
  ZenDialogActionButton,
  ZenDialogComponent,
  ZenDialogDataModel
} from '../zen-dialog/zen-dialog.component';
import {UntypedFormControl} from '@angular/forms';
import {MatrixPricingDataService} from '../../../_modules/zen-market-pulse/_services/matrix-pricing-data.service';
import {ZenBaseComponent} from '../../_components/zen-base/zen-base.component';
import {MeterAddUpdateModel} from '../../../_modules/portfolio/_model/portfolio-meters.model';
import {MetersV4Service} from '../../_services/v4/meters-v4.service';
import {DataFlowChildrenDataTableModified, DataFlowChildrenDataV4Model} from '../../_model/data-flow-hierarchy-v4.model';
import {ZenRcReqFlowHelperService} from '../../../_modules/zen-rate-check-request-v2/_services/zen-rc-req-flow-helper.service';
import {ZenDialogMsgService} from '../../_services/zen-dialog-msg.service';
import {ZenErrorMsgEnum} from '../../_enums/zen-error-msg.enum';
import {ItemListV4Model} from '../../_model/item-list-v4.model';
import {ZenDialogSizeEnum} from '../../_enums/zen-dialogs.enum';
import {RcReqMeterBulkUpdateHelperService} from '../../_services/helpers/rc-req-meter-bulk-update-helper-service';
import {ZenHierarchicalGrouping} from '../../_enums/zen-hierarchical-grouping.enum';
import {ZenCustomerBulkImportHelperService} from '../../../_modules/zen-bulk-import/_services/zen-customer-bulk-import-helper.service';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {BulkUpdateType} from '../../_enums/zen-bulk-update-type.enum';
import {MeterRateClassChangeRequest} from '../../_model/meter-rate-class.model';

@Component({
  selector: 'app-rc-req-meter-rate-class-update-dialog',
  templateUrl: './rc-req-meter-rate-class-update-dialog.component.html',
  styleUrls: ['./rc-req-meter-rate-class-update-dialog.component.scss']
})
export class RcReqMeterRateClassUpdateDialogComponent extends ZenBaseComponent implements OnInit {
  flyout: boolean;
  rateClassControl = new UntypedFormControl();
  meterRow: DataFlowChildrenDataTableModified;
  loading: boolean;
  bulkUpdateType: BulkUpdateType;
  currentGrouping: ZenHierarchicalGrouping;
  commodityType: CommodityType;

  rateSchedules: ItemListV4Model[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              public dialogRef: MatDialogRef<RcReqMeterRateClassUpdateDialogComponent>,
              private matrixPricingDataSvc: MatrixPricingDataService,
              public rcReqHelpSvc: ZenRcReqFlowHelperService,
              public zenDialogSvc: ZenDialogMsgService,
              public dialog: MatDialog,
              private meterV4Svc: MetersV4Service,
              public rcReqMeterBulkUpdateHelpSvc: RcReqMeterBulkUpdateHelperService,
              public bulkImportHelpSvc: ZenCustomerBulkImportHelperService) {
    super();
    this.meterRow = this.data.data.meterRow;
    this.rateClassControl.setValue(this.meterRow.rateScheduleId);
    this.bulkUpdateType = this.data.data.bulkUpdateType;
    this.currentGrouping = this.data.data.dataGrouping;
    this.commodityType = this.data.data.commodityType;
    this.loadRateSchedules();
  }

  ngOnInit(): void {
  }

  loadRateSchedules() {
    this.loading = true;
    this.matrixPricingDataSvc.getRateScheduleListWithDescriptions(this.meterRow.meterUtilityId)
      .subscribe({
        next: res => {
          this.rateSchedules = res.rateSchedules.map(rs => ({key: rs.id, value: rs.code + ' - ' + rs.description}));
          this.loading = false;
        },
        error: err => {
          this.loading = false;
          this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        }
      });
  }

  submit(btn: ZenDialogActionButton) {
    if (btn.actionType === ButtonActionTypes.CANCEL) {
      this.dialogRef.close(null);
    } else {
      this.rateClassControl.markAllAsTouched(); // to trigger mat-error
      if (this.rateClassControl.valid) {
        if (this.data.checkBox.formCtrl.value) {
          this.bulkUpdateMetersRateSchedule()
        } else {
          if (this.checkIfShowDataEntryAssistant()) {
            this.showDataEntryAssistanceDialog();
          } else {
            this.updateMetersRateSchedule()
          }
        }
      }
    }
  }

  /** Method used to update Rate Class of a Meter. */
  updateMetersRateSchedule() {
    let _data = {
      utilityRateScheduleId: this.rateClassControl.value,
      // Below fields are required to perform meter update.
      propertyId: this.meterRow.propertyId,
      utilityAccountNum1: this.meterRow.utilityAccountNum1,
      utilityId: this.meterRow.meterUtilityId
    } as MeterAddUpdateModel;

    this.loading = true;
    this.meterV4Svc.updateMeter(this.rcReqHelpSvc.customerIdParam, this.meterRow.propertyId, this.meterRow.meterId, _data)
      .subscribe({
        next: updatedMeter => {
          this.dialogRef.close(updatedMeter);
          this.zenDialogSvc.openToast(true);
          this.loading = false;
        },
        error: err => {
          this.loading = false;
          this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        }
      });
  }

  /** Method used to bulk update Rate Class of multiple Meters. */
  bulkUpdateMetersRateSchedule() {
    const meterIds = this.rcReqMeterBulkUpdateHelpSvc.getMeterIdsByCurrentGrouping(this.currentGrouping, this.meterRow, this.bulkUpdateType)

    const request: MeterRateClassChangeRequest = {
      meterId: this.meterRow.meterId,
      meterIds: meterIds,
      rateScheduleId: this.rateClassControl.value,
    };
    this.loading = true;
    this.meterV4Svc.bulkChangeRateClassMeters(this.rcReqHelpSvc.customerIdParam, request)
      .subscribe({
        next: updatedMeters => {
          this.dialogRef.close(updatedMeters);
          if (this.bulkUpdateType === BulkUpdateType.BULK_IMPORT_RECENTLY_ADDED_METERS) {
            this.bulkImportHelpSvc.getRecentlyImportedData(this.currentGrouping, this.commodityType);
          } else {
            this.rcReqHelpSvc.getRateCheckMetersGroupedDataById(this.currentGrouping);
          }
          this.zenDialogSvc.openToast(true);
          this.loading = false;
        },
        error: err => {
          this.loading = false;
          this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        }
      });
  }

  /**
   * Uses a local storage key composed of the meter's energy plan ID and utility ID,
   * and checks (in local storage) if the user has asked us to not to show the dialog
   * for this energy plan / utility. We also do not show if there are no other properties
   * in this energy plan without a rate class.
   *
   * @param meter the meter that is being modified
   * @returns a boolean representing whether we should show the data entry assistant dialog
   */
  checkIfShowDataEntryAssistant(): boolean {
    const {energyPlanId, meterUtilityId} = this.meterRow;
    const storageKey = `${energyPlanId}:${meterUtilityId}`;
    const doNotShowAgainLog = JSON.parse(localStorage.getItem('doNotShowAgainLog'));
    let propsInEnergyPlanAndUtility: DataFlowChildrenDataV4Model[] = [];
    this.rcReqHelpSvc.groupedRawData.filter(grd => {
      grd.data.forEach(cd => {
        if (cd.contract?.energyPlanId === energyPlanId && cd?.meter?.utilityId === meterUtilityId) {
          propsInEnergyPlanAndUtility.push(cd);
        }
      });
    });
    const numPropsWithoutRateClass = propsInEnergyPlanAndUtility.filter(prop => !prop.meter?.rateScheduleId).length;
    return numPropsWithoutRateClass > 1 && (!doNotShowAgainLog || !doNotShowAgainLog.includes(storageKey));
  }

  // Show popup dialog that asks user if they want to apply change to all meters
  showDataEntryAssistanceDialog(): void {
    const dialogData: ZenDialogDataModel = {
      header: {title: `Data Entry Assistance`},
      onClose: () => dialogRef.close(),
      bodyHtml: `Would you like the same rate class applied to all meters under this energy plan and utility?`,
      actionButtons: [
        {
          label: 'No', command: () => {
            this.updateMetersRateSchedule(); // Update single meter.
            dialogRef.close()
          }
        },
        {
          label: 'Yes', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'accent', command: () => {
            this.bulkUpdateMetersRateSchedule(); // Update multiple meters.
            dialogRef.close()
          }
        }
      ]
    };
    const dialogRef = this.dialog.open(ZenDialogComponent, {
      width: ZenDialogSizeEnum.SMALL,
      data: dialogData, autoFocus: false
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }

}
