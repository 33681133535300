<app-zen-dialog-wrapper [data]="data" (onSubmit)="submit($event)"
                        [hideButton]="(addressFormType || contactFormType)"
                        [errorMsg]="errorMsg" [loading]="disableButton">
  <div [hidden]="addressFormType ||contactFormType">
    <div class="d-flex flex-wrap">
    <div class="col-12 col-md-6 px-4">
      <!-- Hiding Associated with section customerId value known OR while add/edit address. -->
      <div class="dialog-form-sec with-border" [hidden]="lockedCustomerId">
        <div class="d-flex justify-content-center" *ngIf="form" [formGroup]="form">
          <div class="w-100">

            <div class="row mb-3">
              <div class="col-12 d-flex align-items-center justify-content-between">
                <p class="form-title mb-0">Associated with...</p>
                <button *ngIf="lockedCustomer && data.type === ZenDialogDataType.ADD_DIALOG"
                        class="filter-button d-flex align-items-center p-0" (click)="lockedCustomer=null">
                  <span class="small">Show more</span>
                  <i class="material-icons small">expand_more</i>
                </button>
              </div>
            </div>

            <!-- Customer form -->
            <div class="row mt-1 text-start">
              <div class="col-12 mb-2" *ngIf="!customerId || !lockedCustomer">
                <mat-form-field [appearance]="appearance" class="w-100">
                  <mat-label>{{authSvc.customerNameLabel}}*</mat-label>
                  <input type="text"
                         matInput
                         [formControl]="customerCtrl"
                         [matAutocomplete]="auto">
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCustomerFn">
                    <mat-option *ngFor="let option of filteredCustomers | async" [value]="option"
                                (click)="getCustomerDet(option.key)">
                      {{option.value}}
                    </mat-option>
                  </mat-autocomplete>
                  <i class="material-symbols-rounded" matSuffix>expand_more</i>

                  <mat-error *ngIf="customerCtrl.touched && customerCtrl.hasError('required')">Required Field*
                  </mat-error>
                  <mat-error *ngIf="customerCtrl.touched && customerCtrl.hasError('validoption')">Invalid
                    Customer*
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- Locked Customer -->
              <div *ngIf="customerId && lockedCustomer" class="locked-input-value">
                <div class="d-flex align-items-center">
                  <p class="m-0 d-flex align-items-center text-md">
                    <i class="material-symbols-rounded ms-1">language</i>
                    <span class="ms-2">{{lockedCustomer.value}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dialog-form-sec with-border">
        <div class="d-flex justify-content-center" *ngIf="form" [formGroup]="form">
          <div class="justify-content-center">

            <div class="row mb-3">
              <div class="col-12 text-start">
                <p class="form-title mb-0">Legal Entity Name Details
                  <i class="material-icons-outlined help-outline ms-2"
                     [popper]="lenDetailsPopper"
                     [popperHideOnScroll]="true"
                     [popperHideOnClickOutside]="true"
                     popperTrigger="click"
                     popperApplyClass="sm"
                     popperPlacement="bottom">
                    info
                  </i>
                </p>
              </div>
            </div>

            <!-- LEN details form -->
            <div class="row text-start">
              <!-- Hiding this field on - add/edit billing address -->
              <div class="col-12 form-group">
                <mat-form-field [appearance]="appearance">
                  <mat-label>Legal Entity Name*</mat-label>
                  <input matInput formControlName="name">
                  <mat-error *ngIf="hasError('name', 'required')">Required Field*
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-12 form-group">
                <div class="zen-input-group">
                  <mat-form-field class="left-field w-100" [appearance]="'outline'">
                    <mat-label>Billing Address</mat-label>
                    <input type="text" matInput #inputElement
                           [formControl]="addressCtrl"
                           [matAutocomplete]="auto">

                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayAddressFn">
                      <!-- Mat select Action Button -->
                      <mat-option class="action-button" (click)="handleAddAddress()"><i
                        class="material-icons-round">add</i>New Billing Address
                      </mat-option>

                      <mat-option *ngFor="let option of filteredAddresses | async" [value]="option"
                                  (click)="onAddressSelect(option.id, inputElement)">
                        <div class="d-flex align-items-center">
                          <i *ngIf="option.id === customerDefaultAddressIdConstant"
                             class="material-symbols-rounded me-2 font-weight-200">{{ZenIconsEnum.CUSTOMER}}</i>
                          {{option.label}}
                        </div>
                      </mat-option>
                    </mat-autocomplete>

                    <i *ngIf="billingAddress.value?.id === customerDefaultAddressIdConstant"
                       class="material-symbols-rounded font-weight-200 mb-2" matPrefix>{{ZenIconsEnum.CUSTOMER}}</i>

                    <i class="material-symbols-rounded" matSuffix>expand_more</i>
                    <mat-error *ngIf="addressCtrl.touched && addressCtrl.hasError('required')">Required Field*
                    </mat-error>
                  </mat-form-field>

                  <div class="right-field btn-action" *ngIf="isEditable else disabledBtn" (click)="handleEditAddress()">
                    <i class="material-icons blue-1-color">edit</i>
                  </div>
                  <ng-template #disabledBtn>
                    <div class="right-field btn-action btn-action-disabled"
                         matTooltip="The physical address (default) is not editable here"
                         matTooltipPosition="above" matTooltipClass="no-wrap">
                      <i class="material-icons text-color">edit</i>
                    </div>
                  </ng-template>

                </div>
              </div>

              <!-- Hiding this field on - add/edit billing address -->
              <div class="col-12 form-group">
                <mat-form-field [appearance]="appearance">
                  <mat-label>Activation Status*</mat-label>
                  <mat-select formControlName="active" (selectionChange)="handleSelectionChange($event)">
                    <mat-option [value]="true" class="py-2" >Active</mat-option>
                    <mat-option [value]="false" class="py-2">Inactive</mat-option>
                  </mat-select>
                  <mat-hint *ngIf="disableActivationStatus" class="text-color mt-2">
                    Parent must be active in order to activate.</mat-hint>
                  <mat-hint *ngIf="activatedLen" class="text-color mt-2">
                    You are about to <b>reactivate</b> this legal entity name. The platform will also <b>reactivate</b> the underlying records.</mat-hint>
                  <mat-hint *ngIf="deactivatedLen" class="text-color mt-0">
                    You are about to <b>deactivate</b> this legal entity name. Any underlying records associated with your legal entity name will also be <b>deactivated</b>.</mat-hint>
                  <i class="material-symbols-rounded" matSuffix>expand_more</i>
                  <mat-error *ngIf="hasError('active', 'required')">Required Field*</mat-error>
                </mat-form-field>
              </div>

              <!-- For already inactive LENs we are hiding this reason fields form. -->
              <div class="col-12 mt-4" *ngIf="lenDetails  && !form.controls.active.value">
                <app-zen-activation-form [hierarchyLevel]="PortfolioHierarchyLevelEnum.LENS" [activationAudit]="lenDetails?.activationAudit" [form]="activationChange"></app-zen-activation-form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dialog-form-sec with-border">
        <div class="justify-content-center w-100" *ngIf="form" [formGroup]="form">

          <div class="row mb-3">
            <div class="col-12 d-flex align-items-center justify-content-between">
              <p class="form-title mb-0">Tax Information</p>
            </div>
          </div>

          <!-- Tax form -->
          <div class="row mt-1 text-start">
            <div class="col-12 form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label>EIN</mat-label>
                <input matInput formControlName="ein" (focus)="handleEinEntry()" (blur)="onBlurEin()" [imask]=einMask>
                <mat-error *ngIf="hasError('ein', 'pattern')">Invalid Format</mat-error>
              </mat-form-field>
            </div>

            <div class="col-12 form-group">
              <mat-form-field (click)="w9FileInput ? w9FileInput.click() : null"
                              [floatLabel]="!w9Uploading && w9Name ? 'always' : 'never'"
                              [ngClass]="{'ng-invalid':form.controls.w9DocumentTempId.hasError('required')}"
                              appearance="outline"
                              class="input-upload-files">
                <mat-label>{{!w9Uploading ? 'W-9' : ''}}</mat-label>
                <input [placeholder]="!w9Uploading ? (w9Name || '') : ''" matInput>
                <i class="material-symbols-rounded" matSuffix>file_upload</i>
                <mat-spinner *ngIf="w9Uploading" [strokeWidth]="2" class="loading" color="accent" diameter="20"
                             matPrefix></mat-spinner>
              </mat-form-field>

              <div *ngIf="w9DocumentId" class="row">
                <div class="col-6 d-flex justify-content-start">
                  <button (click)="downloadW9()" class="filter-button p-0">
                    <span class="small">Download</span>
                  </button>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <button (click)="deleteW9()" class="filter-button p-0">
                    <span class="small danger-1-color">Delete</span>
                  </button>
                </div>
              </div>
              <input #w9FileInput (change)="onW9FileUpload($event.target.files)" [accept]="'application/pdf, image/*'"
                     [formControl]="w9FileInputCtrl"
                     style="display:none;" type="file"/>
            </div>

            <div class="col-12 form-group">
              <div class="row">
                <div class="col-6 d-flex mt-3">
                  <mat-checkbox color="accent" formControlName="taxExempt" class="ms-1">Tax Exempt</mat-checkbox>
                </div>
                <div class="col-6">
                  <mat-form-field (click)="fileInput ? fileInput.click() : null"
                                  [floatLabel]="!taxCertUploading && taxCertName ? 'always' : 'never'"
                                  [appearance]="appearance"
                                  [ngClass]="{'ng-invalid ng-touched':hasError('taxCertificateDocumentTempId', 'required')}"
                                  class="input-upload-files">
                    <mat-label>{{!taxCertUploading ? 'Tax Certificate (Optional)' : ''}}</mat-label>
                    <input [disabled]="!controls.taxExempt.value" [placeholder]="!taxCertUploading ? (taxCertName || '') : ''" matInput>
                    <i matSuffix class="material-symbols-rounded">file_upload</i>
                    <mat-spinner *ngIf="taxCertUploading" [strokeWidth]="2" class="loading" color="accent" diameter="20"
                                 matPrefix></mat-spinner>
                  </mat-form-field>
                  <mat-error *ngIf="hasError('taxCertificateDocumentTempId', 'required')">
                    Required Field*
                  </mat-error>

                  <button *ngIf="taxCertificateDocumentId" class="filter-button p-0" (click)="downloadTaxCert()">
                    <span class="small">Download</span>
                  </button>
                  <input #fileInput (change)="onTaxCertFileUpload($event.target.files)"
                         [accept]="'application/pdf, image/*'" [formControl]="taxCertFileInputCtrl"
                         style="display:none;" type="file"/>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-12 col-md-6 px-4">
      <!-- Hiding and merging this for now to avoid conflicts -->

      <div class="dialog-form-sec with-border">
        <div class="justify-content-center w-100" *ngIf="form" [formGroup]="form">

          <div class="row mb-3">
            <div class="col-12 d-flex align-items-center justify-content-between">
              <p class="form-title mb-0">Contact Management</p>
            </div>
          </div>

          <div class="form-group" *ngIf="contactList?.length">
            <app-zen-select-search-dropdown [initialOptions]="contactList" [isEditable]="true"
                                           [formCtrl]="form.controls.signatorContactId"
                                           newOptLabel="New Contract Signatory" label="Contract Signatory"
                                           (onAddNew)="showAddNewContactForm(ContactTypes.SIGNATOR)"
                                           (onOptionChange)="showOnOptionSelect($event, ContactTypes.SIGNATOR)"
                                           (onEditClick)="showEditContactForm(ContactTypes.SIGNATOR)"></app-zen-select-search-dropdown>
            <div *ngIf="this.isAdvisor" class="d-flex align-items-center sub-text">
              <span class="float-start ps-1 pe-3">Search Within:</span>
              <mat-checkbox color="accent" formControlName="csCustomers">All Customers</mat-checkbox>
              <mat-checkbox color="accent" formControlName="csAdvisors" class="ms-5 sub-text">Advisors</mat-checkbox>
            </div>
          </div>

          <div class="form-group" *ngIf="billingContactList?.length">
            <app-zen-select-search-dropdown [initialOptions]="billingContactList" [isEditable]="editBillingContact"
                                           [formCtrl]="form.controls.billingContactId"
                                           newOptLabel="New Billing Contact" label="Billing Contact"
                                           (onAddNew)="showAddNewContactForm(ContactTypes.BILLING)"
                                           (onOptionChange)="showOnOptionSelect($event, ContactTypes.BILLING)"
                                           (onEditClick)="showEditContactForm(ContactTypes.BILLING)"></app-zen-select-search-dropdown>
            <div *ngIf="this.isAdvisor" class="d-flex align-items-center sub-text">
              <span class="float-start ps-1 pe-3">Search Within:</span>
              <mat-checkbox color="accent" formControlName="bcCustomers">All Customers</mat-checkbox>
              <mat-checkbox color="accent" formControlName="bcAdvisors" class="ms-5 sub-text">Advisors</mat-checkbox>
            </div>
          </div>
        </div>
      </div>


      <div class="dialog-form-sec with-border">
        <div class="zen-mat-form">
          <app-zen-tags
            [type]="TagType.LEN"
            [tagFilterCtrl]="tagFilterCtrl"
            [tagOptions]="tagOptions"
            [selectedTags]="selectedTags"
            (onAddTag)="handleAddTag($event)"
            (onRemoveTag)="handleRemoveTag($event)"></app-zen-tags>
        </div>
      </div>
    </div>
  </div>
  </div>
  <!-- Add/Edit contact form STARTS -->
  <ng-container *ngIf="contactFormType">
    <app-zen-contact-form-fields [form]="contactForm" [formType]="contactFormType"
                                 (cancelEdit)="cancelContactEdit(); resetContacts();"
                                 (onContactFormSubmit)="onContactFormSubmit()"></app-zen-contact-form-fields>
  </ng-container>
  <!-- Add/Edit contact form ENDS -->

  <!-- Add/Edit billing address form STARTS -->
  <ng-container *ngIf="addressFormType">
    <div class="dialog-form-sec with-border">
      <div class="d-flex justify-content-center" *ngIf="form" [formGroup]="form">
        <div class="justify-content-center">

          <div class="row mb-3">
            <div class="col-12 text-start">
              <p class="form-title mb-0">Billing Address Details</p>
            </div>
          </div>

          <!-- LEN details form -->
          <div class="row text-start">
            <!-- Billing Address form starts -->
            <div class="col-12" *ngIf="billingAddress" [formGroup]="billingAddress">

              <div *ngIf="addressFormType === FORM_TYPES.EDIT" class="mb-5">
                <app-zen-message [type]="ZenMessageType.INFO"
                                 message="You are about to make a global change."></app-zen-message>
              </div>

              <div class="row">
                <div class="col-12 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Billing Address 1*</mat-label>
                    <input matInput formControlName="address1" #address
                           (input)="addressAutocompleteService.handleAddressChange(billingAddress, address)">
                    <mat-error *ngIf="addressHasError('address1', 'required')">Required
                      Field*
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-6 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Billing Address 2</mat-label>
                    <input matInput formControlName="address2">
                  </mat-form-field>
                </div>
                <div class="col-6 col-md-6 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Billing City*</mat-label>
                    <input matInput formControlName="city">
                    <mat-error *ngIf="addressHasError('city', 'required')">Required
                      Field*
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-6 col-md-6 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Billing {{'nomenclature.state' | translate}}*</mat-label>
                    <input matInput [formControl]="billingAddress.controls.state" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [panelWidth]="100" [autoActiveFirstOption]="true">
                      <mat-option *ngFor="let option of filteredStateOptions | async" [value]="option">
                        {{option}}
                      </mat-option>
                    </mat-autocomplete>
                    <i class="material-symbols-rounded" matSuffix>expand_more</i>
                    <mat-error *ngIf="addressHasError('state', 'required')">Required
                      Field*
                    </mat-error>
                    <mat-error *ngIf="addressHasError('state', 'validoption')">Invalid
                      {{'nomenclature.state' | translate}}*
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-6 col-md-6 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Billing Zip*</mat-label>
                    <input matInput formControlName="zip" [mask]="i18nMask.zip">
                    <mat-error *ngIf="addressHasError('zip', 'required')">Required
                      Field*
                    </mat-error>
                    <mat-error *ngIf="addressHasError('zip', 'pattern')">Invalid
                      Format*
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <!-- Showing this section on - add/edit billing address -->
            <div class="col-12 d-flex align-items-center justify-content-end my-3">
              <button mat-button (click)="cancelAddressEdit()">Cancel</button>
              <button mat-raised-button color="accent" class="ms-3" (click)="onAddressFormSubmit()">Save</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Add/Edit billing address form ENDS -->
</app-zen-dialog-wrapper>

<popper-content #lenDetailsPopper>
  <app-zen-popper title="Legal Entity Name" [closeable]="true" [popperContent]="lenDetailsPopper">
    <p class="medium">
      Each Property is tied to a registered Legal Entity Name. Suppliers will reference the Legal Entity Name when
      checking credit and will contract the load under this name.
    </p>
  </app-zen-popper>
</popper-content>
