import {AppRateCheckStatus, RateCheckV2WithProperties} from '../_models/app-rate-check-v2';
import { UntypedFormGroup } from '@angular/forms';
import { Theme, ThemeName } from '../_models/theme.model';
import { FileStatus } from '../_models/file-data';
import { CustomerStatus } from '../zen-common-services/tili-services/models/customer';
import {ZenRoutesEnum} from '../../_enums/zen-routes.enum';

const V1_PRE_BIDDING_STATUSES = [
  'created',
  'review_rate_check',
  'modify_rate_check',
  'create_scenario',
  'review_scenario',
  'modify_scenario',
  'select_suppliers',
  'review_suppliers',
  'modify_suppliers',
  'ready_for_bids',
  'scheduled_for_bids'
];
const V2_PRE_BIDDING_STATUSES = [
  'created',
  'data_collection',
  'pre_bidding_qa',
  'customer_verification'
];

export function isBiddingOpen(rateCheckStatusName: string, version: number): boolean {
  if (version === 1) {
    if (V1_PRE_BIDDING_STATUSES.find((element) => element === rateCheckStatusName) === undefined) {
      return true;
    }
  } else {
    if (V2_PRE_BIDDING_STATUSES.find((element) => element === rateCheckStatusName) === undefined) {
      return true;
    }
  }
  return false;
}


export function getStatusNumber(rateCheckStatus: AppRateCheckStatus): number {
  let statusNumber = 0;

  switch (rateCheckStatus) {
    case 'rejected':
      statusNumber = 0;
      break;
    case 'aborted':
      statusNumber = 1;
      break;
    case 'timed_out':
      statusNumber = 2;
      break;
    case 'declined':
      statusNumber = 3;
      break;
    case 'created':
      statusNumber = 100;
      break;
    case 'data_collection':
      statusNumber = 110;
      break;
    case 'pre_bidding_qa':
      statusNumber = 120;
      break;
    case 'customer_verification':
      statusNumber = 200;
      break;
    case 'bidding_open':
      statusNumber = 300;
      break;
    case 'bidding_closed':
      statusNumber = 310;
      break;
    case 'optimizing':
      statusNumber = 320;
      break;
    case 'post_bidding_qa':
      statusNumber = 330;
      break;
    case 'recommendation_qa':
      statusNumber = 340;
      break;
    case 'customer_deciding':
      statusNumber = 400;
      break;
    case 'requesting_contracts':
      statusNumber = 410;
      break;
    case 'pricing_solution_qa':
      statusNumber = 420;
      break;
    case 'customer_approving_solution':
      statusNumber = 430;
      break;
    case 'pricing_solution_customer_support':
      statusNumber = 440;
      break;
    case 'package_contracts':
      statusNumber = 505;
      break;
    case 'signing_contracts':
      statusNumber = 500;
      break;
    case 'booking_energy_plans':
      statusNumber = 510;
      break;
    case 'awaiting_countersigned_contract':
      statusNumber = 520;
      break;
    case 'complete':
      statusNumber = 1000;
      break;
  }

  return statusNumber;
}

export function getStatusColor(rc: RateCheckV2WithProperties) {
  let statusNumber = getStatusNumber(rc.rateCheckStatus);
  let redStatuses = 100; //  Everything under 100 is a negative closed status
  let blueStatuses = 200; /** BLUE STATUSES: Everything before Customer_Deciding*/
  let greenStatus = 1000;  /** GREEN STATUSES: Everything after Customer_Deciding */

  if (statusNumber < redStatuses) {
    return 'red';
  }

  if (statusNumber <= blueStatuses) {
    return 'grey';
  }

  if (statusNumber > blueStatuses && statusNumber < greenStatus) {
    return 'blue';
  }

  return 'green';
}

export function toMap(array: any[], fieldKey: string) {
  let mappedArray = [];
  array.forEach(value => {
    mappedArray[value[fieldKey]] = value;
  });
  return mappedArray;
}

export function extractForm(form: UntypedFormGroup, excludeFields?: string[]): any {
  let resultObject: any = [];
  Object.keys(form.controls).forEach(controlName => {
    if (!excludeFields || excludeFields.indexOf(controlName) === -1) {
      resultObject[controlName] = form.get(controlName).value;
    }
  });
  return {
    ...resultObject
  };
}

export function extractModifiedFormVal(form: UntypedFormGroup, excludeFields?: string[]): any {
  let resultObject: any = [];
  Object.keys(form.controls).forEach(controlName => {
    if ((!excludeFields || excludeFields.indexOf(controlName) === -1)
      && !form.get(controlName).pristine) {
      resultObject[controlName] = form.get(controlName).value;
    }
  });
  return {
    ...resultObject
  };
}

export function getOwnerPrefix(theme: Theme): string {
  if (theme.getThemeName() === ThemeName.ZENTILITY || theme.getThemeName() === ThemeName.CBRE) {
    return 'Admin';
  }
  return 'Broker';
}

export function getCssVarValue(varName: string): string {
  return document.documentElement.style.getPropertyValue(varName);
}

export function hasError(form: UntypedFormGroup, formControlName: string, formErrorName?: string): boolean {
  const hasAnyError = !form.controls[formControlName].valid &&
    form.controls[formControlName].touched &&
    form.controls[formControlName].errors;
  if (formErrorName) {
    return hasAnyError && form.controls[formControlName].errors[formErrorName];
  } else {
    return !!hasAnyError;
  }
}

export function getFileStatusString(status: FileStatus): string {
  switch (status) {
    case FileStatus.LOADING:
      return 'Loading...';
    case FileStatus.READY_TO_UPLOAD:
      return 'Ready to Upload';
    case FileStatus.UPLOADED:
      return 'Uploaded';
    case FileStatus.UPLOADING:
      return 'Uploading...';
    case FileStatus.ERROR:
      return 'Error Uploading File';
    default:
      return 'Error!';
  }
}


export const smoothScrollToTop = () => {
  const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
  if (currentScroll > 0) {
    window.requestAnimationFrame(smoothScrollToTop);
    window.scrollTo(0, currentScroll - (currentScroll / 5));
  }
}

export function getCustomerOrPropertyStatusColor(status: string) {
  switch (status) {
    case CustomerStatus.Active:
      return 'status-mark-active';
    case CustomerStatus.Pending:
      return 'status-mark-pending';
    default:
      return 'status-mark-inactive';
  }
}

export function saveBlob(data: Blob, type: string, fileName: string) {
  if (!window.navigator.msSaveBlob) {
    const pdfUrl = URL.createObjectURL(data);
    const anchor: any = document.createElement('a');
    anchor.download = fileName;
    anchor.href = pdfUrl;
    anchor.click();
    setTimeout(() => {
      anchor.remove();
    }, 250);
  } else {
    const textFileAsBlob = new Blob([data], { type: type });
    window.navigator.msSaveBlob(textFileAsBlob, fileName);
  }
}

export function getShortRateCheckId(rateCheckId: string) {
  return rateCheckId.substr(rateCheckId.length - 6);
}

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}
