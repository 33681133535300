export enum ZenColumnInfoText {
  // Customers Table
  CUSTOMER_NAME = 'Name of Company',
  LEN = 'Name used to check credit and contract under',
  PROPERTIES = 'Group of meters at a physical location',
  METERS = 'Count of individual account numbers billed by the utility',
  ELEC_USAGE = 'Total annual electricity usage - may be actual or estimated',
  NATGAS_USAGE = 'Total annual natural gas usage - may be actual or estimated',
  RCS = 'Count of active rate checks',
  AT_RISK = 'Based on the expiration window, the percent of meters that have expired or are expiring. See the widget info icon above for more details',
  TODAY = 'Percent of contracted meters based on today’s status. See the widget info icon above for more details',
  FUTURE = 'Percent of contracted meters based on future status. See the widget info icon above for more details',
  // Properties Table
  PROPERTY_NAME = 'Name of the Property',
  // Meters Table
  METER_NAME = 'Name of the meter',
  UTILITY = 'Name of the Utility distributing service to the meter',
  BILLS = 'Count of uploaded bills for the meter',
  USAGE = 'Annual electricity or natural gas usage - may be actual or estimated',
  PROCUREMENT_SNAPSHOT = '36-month procurement snapshot timeline for the meter',
  // Rate Checks Table
  RC_CUSTOMER_NAME = 'Title text reveals the customer’s name while sub-text provides visibility into all associated Legal Entity Names',
  RC_STATUS = 'Indicates the status of active rate checks and those that are no longer in process',
  RC_SERVICE = 'Indicative of the commodity, electricity or natural gas',
  RC_USAGE = 'Total estimated annual usage associated with the Rate Check',
  RC_STATE = 'Indicates the location of the Rate Check',
  RC_DUE_DATE = 'Date the rate check bids are due back from suppliers',
  RC_METERS = 'Indicative of the meters included in the Rate Check, hyperlink provides snapshot into additional meter details',
  RC_ARR = 'Estimated annual reoccurring revenue (ARR) associated with the Rate Check. Calculated by taking the (total margin - platform fee) * total estimated annual usage',
  // Contracts Page Table
  CONTRACT_CUSTOMER_NAME = 'Title text reveals the customer’s name while sub-text provides visibility into all associated Legal Entity Names',
  CONTRACT_STATUS = 'Indicates whether the contract is currently flowing, is set to flow in the future, has expired, or is in the process of being booked',
  CONTRACT_STRATEGY = 'Alongside the rate, this column provides information about the product and source configuration',
  CONTRACT_USAGE = 'Total estimated annual usage associated with the Contract',
  CONTRACT_STATE = 'Indicates the location of the Contract',
  CONTRACT_START_DATE = 'Indicates when the Contract begins',
  CONTRACT_END_DATE = 'Indicates when the Contract ends',
  CONTRACT_ARR = 'Estimated annual reoccurring revenue (ARR) associated with contracts booked through the platform. Calculated by taking the (total margin - platform fee) * total estimated annual usage',
  // Contract Details Page Table
  CONTRACT_DET_UAN = 'Utility account number is a universal name for the unique primary id assigned by the utility',
  CONTRACT_DET_LEGAL_ENTITY_NAME = 'Name used to check credit and contract under',
  CONTRACT_DET_PROPERTY_NAME = 'Name of the property',
  CONTRACT_DET_SERVICE_ADDRESS = 'Actual address of service, noted on the utility bill',
  CONTRACT_DET_UTILITY = 'Entity that provides distribution services to end consumers, often known as the “provider of last resort”',
  CONTRACT_DET_USAGE = 'Total estimated annual usage associated with the Contract',
  CONTRACT_DET_METER_TYPE = 'Further defines the meter at a multi-family property as being either a “unit” or “common area”',
  CONTRACT_DET_START_DATE = 'Indicates when the Contract begins',
  CONTRACT_DET_END_DATE = 'Indicates when the Contract ends',
  CONTRACT_METERS = 'Count of all meters (active and inactive) in the contract',
  CONTRACT_DETAILS = 'Details of the contract',
  CONTRACT_AUTHORIZATION = "Contract authorization",
  // Contract Details Link Meters Page Table
  LINK_METER_PROCUREMENT_SNAPSHOT = '36-month procurement snapshot timeline for the meter',

}
