import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {FeeDetailsError, RateCheckFeeDetails} from '../../_model/rate-check-v4.model';
import {TotalFeeBreakdownRcData} from '../../_model/custom/total-fee-breakdown.model';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {ZenNumberFormat} from '../../_enums/zen-number-format.enum';
import {ZenBaseWithTranslateComponent} from '../zen-base-with-translate/zen-base-with-translate.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-zen-total-fee-breakdown-popper-icon',
  templateUrl: './zen-total-fee-breakdown-popper-icon.component.html',
  styleUrl: './zen-total-fee-breakdown-popper-icon.component.scss'
})
export class ZenTotalFeeBreakdownPopperIconComponent extends ZenBaseWithTranslateComponent {
  @Input() feeDetails: RateCheckFeeDetails = {};
  @Input() feeError: FeeDetailsError = {};
  @Input() rateCheckData: TotalFeeBreakdownRcData;
  @Output() onSubmit = new EventEmitter<RateCheckFeeDetails>();

  ZenIcons = ZenIconsEnum;
  ZenNumberFormat = ZenNumberFormat;
  isElectric: boolean;

  constructor(
    public orgMgtSvc: OrganizationManagementService,
    public translateSvc: TranslateService
  ) {
    super(translateSvc);
  }

}
