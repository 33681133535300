import { Injectable } from '@angular/core';
import {environment} from '../../../../../../environments';
import {HttpClient} from '@angular/common/http';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {MeterUsageProfileV4GetModel, MeterUsageUpdate} from '../../_model/meter-usage-profile-v4.model';
import {EnergyPlanTimeline} from '../../../_modules/portfolio/_model/portfolio-meters.model';
import {getParams} from '../../_zen-legacy-common/_utils/set-param-util';

@Injectable({
  providedIn: 'root'
})
export class MeterUsageProfileV4Service {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient, private orgMgtSvc: OrganizationManagementService) { }

  getUsageProfileByMeterId(customerId: number, propertyId: number, meterId: number): Observable<MeterUsageProfileV4GetModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/properties/${propertyId}/meters/${meterId}/usage-profile`;
    return this.http.get<MeterUsageProfileV4GetModel>(url).pipe(take(1));
  }

  updateUsageProfile(customerId: number, propertyId: number, meterId: number, data: MeterUsageUpdate): Observable<MeterUsageProfileV4GetModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/properties/${propertyId}/meters/${meterId}/usage-profile`;
    return this.http.put<MeterUsageProfileV4GetModel>(url, data).pipe(take(1));
  }

  createUsageProfile(customerId: number, propertyId: number, meterId: number, data: MeterUsageUpdate): Observable<MeterUsageProfileV4GetModel> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/properties/${propertyId}/meters/${meterId}/usage-profile`;
    return this.http.post<MeterUsageProfileV4GetModel>(url, data).pipe(take(1));
  }

  // Procurement Snapshot
  getMeterTimelines(customerId: number, propertyId: number, meterId: number): Observable<EnergyPlanTimeline[]> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/properties/${propertyId}/meters/${meterId}/timeline`;
    return this.http.get<EnergyPlanTimeline[]>(url).pipe(take(1));
  }

  getSimulatedMeterTimelines(customerId: number, propertyId: number, meterId: number, addEnergyPlanIds: number[], removeEnergyPlanIds: number[], assignToUtility = false): Observable<EnergyPlanTimeline[]> {
    let httpParams = getParams({addEnergyPlanIds, removeEnergyPlanIds, assignToUtility});
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/properties/${propertyId}/meters/${meterId}/timeline`;
    return this.http.get<EnergyPlanTimeline[]>(url, {params: httpParams}).pipe(take(1));
  }
}
