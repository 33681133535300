import {Injectable} from '@angular/core';
import {environment} from '../../../../../../environments';
import {HttpClient, HttpParams} from '@angular/common/http';
import {OrganizationManagementService} from '../../../_shared/_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {Observable} from 'rxjs';
import {PortfolioMetersModel} from '../_model/portfolio-meters.model';
import {take} from 'rxjs/operators';
import {getParams} from '../../../_shared/_zen-legacy-common/_utils/set-param-util';
import {PortfolioLensModel} from '../_model/portfolio-lens.model';
import {PortfolioPropertiesModel} from '../_model/portfolio-properties.model';
import {MeterStatusModel, PortfolioContractCountdownModel, SummarySnapShotModel} from '../_model/portfolio-summary.model';
import {InitialPortfolioFilters} from '../_model/portfolio-initial-filter.model';
import {PortfolioUsageModel} from '../_model/portfolio-usage.model';


@Injectable({
  providedIn: 'root'
})
export class PortfolioHierarchyService {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient, private orgMgtSvc: OrganizationManagementService) { }

  getHierarchyUsage(filters?: string, riskWindowDays?: number): Observable<PortfolioUsageModel> {
    let params = {riskWindowDays};
    let httpParams = getParams(params);
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/usage?filters=${encodeURIComponent(this.handleFilterEncoding(filters))}`;
    return this.http.get<PortfolioUsageModel>(url, {params: httpParams}).pipe(take(1));
  }

  handleFilterEncoding(filters?: string) {
    if (filters == null) {
      return null;
    }

    while (filters.includes('%')) {
      filters = decodeURIComponent(filters);

    }
    return filters;
  }

}
