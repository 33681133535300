<app-zen-loader *ngIf="loading"></app-zen-loader>
<app-zen-dialog-wrapper [data]="data" [hideButton]="true">
  <div class="zen-market-pulse-generate-lead-dialog-main">
    <ng-container *ngIf="selectedIndex === 0">
      <div class="d-flex justify-content-center mb-5">
        <div class="col-12 col-sm-10 col-md-7">
          <app-zen-message
            message="Note it’s important to check the contract start date and the rate class are correct, as this could affect your ability to book this price.">
          </app-zen-message>
        </div>
      </div>
    </ng-container>

    <div class="dialog-form-sec with-border mb-0 py-5">
      <div class="steps">
        <div class="step" *ngFor="let step of steps; let i = index;"
             [ngClass]="step.completed ? 'completed' : step.selected ? 'selected' : ''">{{step.title}}</div>
      </div>
      <form *ngIf="selectedIndex === 0" class="mt-5">
        <div class="row">
          <!-- If selectionMode = == single -->
          <div class="col-40-percentage mb-4" *ngIf="svc.selectedPriceEntities.length === 1">
            <div class="d-flex align-items-center justify-content-between form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label class="ms-label">{{'nomenclature.supplier' | translate}} *</mat-label>
                <input matInput [value]="supplierNames.join(', ')" disabled>
              </mat-form-field>
            </div>
          </div>

          <div class="col-20-percentage mb-4" *ngIf="svc.selectedPriceEntities.length === 1">
            <div class="d-flex align-items-center justify-content-between form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label class="ms-label">Price *</mat-label>
                <input matInput name="currentPrice" [value]="prices.join(', ')" disabled>
              </mat-form-field>
            </div>
          </div>

          <div class="col-20-percentage mb-4" *ngIf="svc.selectedPriceEntities.length === 1">
            <div class="d-flex align-items-center justify-content-between form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label class="ms-label">Term *</mat-label>
                <input matInput [value]="terms.join(', ')" disabled>
              </mat-form-field>
            </div>
          </div>

          <div class="col-20-percentage mb-4"
               *ngIf="rateClass.length && stateSupportsRateClass(svc.searchCriteria.state)">
            <div class="d-flex align-items-center justify-content-between form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label class="ms-label">Rate Class *</mat-label>
                <input matInput [value]="rateClass.join(', ')" disabled>
              </mat-form-field>
            </div>
          </div>

          <div class="col-20-percentage mb-4">
            <div class="d-flex align-items-center justify-content-between form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label class="ms-label">Usage (kWh) *</mat-label>
                <input matInput value="{{svc.searchCriteria.annualMwhUsage*1000 | number}}" disabled>
              </mat-form-field>
            </div>
          </div>

          <div class="col-20-percentage mb-4">
            <div class="d-flex align-items-center justify-content-between form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label class="ms-label">{{'nomenclature.state' | translate}} *</mat-label>
                <input matInput name="mp-state" [(ngModel)]="svc.searchCriteria.state" disabled>
              </mat-form-field>
            </div>
          </div>

          <div class="col-20-percentage mb-4">
            <div class="d-flex align-items-center justify-content-between form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label class="ms-label">{{'nomenclature.utilityShort' | translate}}</mat-label>
                <input matInput name="currentUtility" [(ngModel)]="utilityName" disabled>
              </mat-form-field>
            </div>
          </div>

          <div class="col-20-percentage mb-4"
               *ngIf="svc.searchCriteria.zone && stateSupportsZone(svc.searchCriteria.state)">
            <div class="d-flex align-items-center justify-content-between form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label class="ms-label">Zone *</mat-label>
                <input matInput [value]="svc.searchCriteria.zone" disabled>
              </mat-form-field>
            </div>
          </div>

          <div class="col-20-percentage mb-4">
            <div class="d-flex align-items-center justify-content-between form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label class="ms-label">Start Date*</mat-label>
                <input matInput name="contract-start"
                       value="{{svc.searchCriteria.startDate | date : 'MM/yyyy'}}" disabled>
                <mat-datepicker-toggle matIconSuffix></mat-datepicker-toggle>
              </mat-form-field>
            </div>
          </div>

        </div>
        <!-- If selectionMode === multi -->
        <div class="col-12 p-0 mt-3" *ngIf="svc.selectedPriceEntities.length > 1">
          <app-zen-mat-table [data]="rateTblData" [tableConfig]="tableConfig"></app-zen-mat-table>
        </div>
      </form>

      <form *ngIf="stepTwoForm && selectedIndex === 1" [formGroup]="stepTwoForm" class="mt-5">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-4">
            <div class="form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label>Company Name*</mat-label>
                <input matInput formControlName="companyName" placeholder="Acme Inc.">
                <mat-error *ngIf="sTwoHasError('companyName', 'required')">Required Field*
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-4 me-5">
            <div class="form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label>Contact Name*</mat-label>
                <input matInput formControlName="contactName" placeholder="John Smith">
                <mat-error *ngIf="sTwoHasError('contactName', 'required')">Required Field*
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-4">
            <div class="form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label>Contact Email*</mat-label>
                <input matInput formControlName="contactEmail" placeholder="jsmith@acme.com">
                <mat-error *ngIf="sTwoHasError('contactEmail', 'required')">Required Field*
                </mat-error>
                <mat-error *ngIf="sTwoHasError('contactEmail', 'pattern')">Invalid Format*
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-4 me-5">
            <div class="form-group">
              <mat-form-field [appearance]="appearance">
                <mat-label>Contact Phone*</mat-label>
                <input matInput formControlName="contactPhone" [imask]="i18nMask.phone" [unmask]="i18nMask.phoneUnmask">
                <mat-error *ngIf="sTwoHasError('contactPhone', 'required')">Required Field*
                </mat-error>
                <mat-error *ngIf="(sTwoHasError('contactPhone', 'minlength')
                    || sTwoHasError('contactPhone', 'maxlength'))">Invalid Format*
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="col-md-12 mb-2 mt-3 text-start">
            <p class="text-md mb-0 me-3 mt-0">Do you currently have an energy contract with a supplier?*</p>
            <div class="my-3">
              <mat-radio-group class="mat-radio-responsive" formControlName="energyContract">
                <mat-radio-button name="energyContract"
                                  [value]="RadioBtnValues.NO">{{RadioBtnValues.NO}}</mat-radio-button>
                <mat-radio-button name="energyContract"
                                  [value]="RadioBtnValues.YES">{{RadioBtnValues.YES}}</mat-radio-button>
                <mat-radio-button name="energyContract"
                                  value="NotSure">Not Sure
                </mat-radio-button>

                <mat-error *ngIf="sTwoHasError('energyContract', 'required')">Required Field*</mat-error>
              </mat-radio-group>
            </div>
          </div>

          <div class="col-md-12" *ngIf="sTwo.energyContract.value === 'Yes'">
            <div class="row sub-forms">
              <div class="col-md-12">
                <p class="dialog-title mb-3">Energy Contract Details</p>
              </div>
              <div class="col-sm-12 col-md-5" *ngIf="supplierList.length">
                <div class=" form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>{{'nomenclature.supplier' | translate}} Name</mat-label>
                    <mat-select formControlName="currentSupplier" placeholder="Choose">
                      <mat-option *ngFor="let opt of supplierList" [value]="opt.label"
                                  class="py-2">{{opt.label}}</mat-option>
                    </mat-select>
                    <i class="material-symbols-rounded" matSuffix>expand_more</i>
                    <mat-error *ngIf="sTwoHasError('currentSupplier', 'required')">Required Field*</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Current {{'nomenclature.rate' | translate}} ({{_translations?.currency?.symbol}}/kWh)</mat-label>
                    <input matInput formControlName="contractRateCents" type="number" placeholder="0.05">
                    <mat-error *ngIf="sTwoHasError('contractRateCents', 'required')">Required Field*
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Contract End Date</mat-label>
                    <input matInput name="contract-end" [matDatepicker]="dp" readonly (click)="dp.open()"
                           formControlName="contractEndDate" placeholder="Choose a date">
                    <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                    <mat-datepicker #dp startView="year"></mat-datepicker>
                    <mat-error *ngIf="sTwoHasError('contractEndDate', 'required')">Required Field*</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 mb-2 text-start" *ngIf="sTwo.energyContract.valid">
            <p class="text-md me-3">Do you have more than one property or account?*</p>
            <div class="my-3">
              <mat-radio-group class="mat-radio-responsive" formControlName="multipleBills">
                <mat-radio-button name="multipleBills"
                                  [value]="false">{{RadioBtnValues.NO}}</mat-radio-button>
                <mat-radio-button name="multipleBills"
                                  [value]="true">{{RadioBtnValues.YES}}</mat-radio-button>

                <mat-error *ngIf="sTwoHasError('multipleBills', 'required')">Required Field*</mat-error>
              </mat-radio-group>
            </div>
          </div>

          <ng-container *ngIf="sTwo.multipleBills.value">
            <div class="col-md-12 mb-3 text-start">
              <p class="text-md me-3">Do you have bills for all accounts?*</p>
              <div class="my-3">
                <mat-radio-group class="mat-radio-responsive" formControlName="allAccountsBill">
                  <mat-radio-button name="allAccountsBill"
                                    [value]="RadioBtnValues.NO">{{RadioBtnValues.NO}}</mat-radio-button>
                  <mat-radio-button name="allAccountsBill"
                                    [value]="RadioBtnValues.YES">{{RadioBtnValues.YES}}</mat-radio-button>

                  <mat-error *ngIf="sTwoHasError('allAccountsBill', 'required')">Required Field*</mat-error>
                </mat-radio-group>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-5" *ngIf="sTwo.allAccountsBill.value === 'Yes'">
              <ng-template [ngTemplateOutlet]="fileUploadTemplate"></ng-template>
            </div>
          </ng-container>

          <ng-template #fileUploadTemplate>
            <div class="form-group">
              <mat-form-field (click)="fileInput.click()"
                              [appearance]="appearance" [ngClass]="{'ng-invalid ng-touched': fileInputCtrl.touched && fileInputCtrl?.invalid}"
                              class="input-upload-files">
                <mat-label>{{billsToUpload?.length ? billsToUpload[0].fileName : 'Upload your file(s)'}}</mat-label>
                <input matInput>
                <i matSuffix class="material-symbols-rounded">file_upload</i>
              </mat-form-field>

              <mat-error *ngIf="fileInputCtrl.touched && fileInputCtrl?.hasError('required')">
                Required Field*
              </mat-error>

              <button *ngIf="fileInputCtrl?.value" class="filter-button p-0" (click)="downloadBill(billsToUpload[0])">
                <span class="small">Download</span>
              </button>
              <input #fileInput type="file" [formControl]="fileInputCtrl" (change)="onFileInput($event.target.files)"
                     style="display:none;" accept="application/pdf, image/*"/>
            </div>
          </ng-template>

          <ng-container *ngIf="!sTwo.multipleBills.value">
            <div class="col-md-12 text-start">
              <p class="text-md me-3">Do you have a bill?*</p>
              <div class="my-3">
                <mat-radio-group class="mat-radio-responsive" formControlName="doYouHaveBill">
                  <mat-radio-button name="doYouHaveBill" [value]="RadioBtnValues.NO"
                  >{{RadioBtnValues.NO}}</mat-radio-button>
                  <mat-radio-button name="doYouHaveBill" [value]="RadioBtnValues.YES"
                  >{{RadioBtnValues.YES}}</mat-radio-button>

                  <mat-error *ngIf="sTwoHasError('doYouHaveBill', 'required')">Required Field*</mat-error>
                </mat-radio-group>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-5" *ngIf="sTwo.doYouHaveBill.value === 'Yes'">
              <ng-template [ngTemplateOutlet]="fileUploadTemplate"></ng-template>
            </div>

            <div class="col-md-12" *ngIf="sTwo.doYouHaveBill.value === 'No'">
              <div class="row sub-forms" formGroupName="serviceAddress">
                <div class="col-md-12">
                  <p class="dialog-title mb-3">Property Account Details</p>
                </div>

                <div class="col-20-percentage">
                  <div class="form-group">
                    <mat-form-field [appearance]="appearance">
                      <mat-label>Service Address 1*</mat-label>
                      <input matInput formControlName="address1" #addressOne
                             (keyup)="addressAutocompleteService.handleAddressChange(serviceAddress, addressOne)">
                      <mat-error *ngIf="svcAddressHasError('address1', 'required')">Required Field*
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="col-20-percentage">
                  <div class="form-group">
                    <mat-form-field [appearance]="appearance">
                      <mat-label>Service Address 2</mat-label>
                      <input matInput formControlName="address2">
                    </mat-form-field>
                  </div>
                </div>

                <div class="col-20-percentage">
                  <div class="form-group">
                    <mat-form-field [appearance]="appearance">
                      <mat-label>City*</mat-label>
                      <input matInput formControlName="city">
                      <mat-error *ngIf="svcAddressHasError('city', 'required')">Required Field*
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="col-20-percentage me-0 me-lg-5">
                  <div class="form-group">
                    <mat-form-field [appearance]="appearance">
                      <mat-label>{{'nomenclature.state' | translate}}</mat-label>
                      <input matInput [value]="svc.searchCriteria.state" disabled>
                    </mat-form-field>
                  </div>
                </div>

                <div class="col-20-percentage">
                  <div class="form-group">
                    <mat-form-field [appearance]="appearance">
                      <mat-label>Zip*</mat-label>
                      <input matInput formControlName="zip" [mask]="i18nMask.zip">
                      <mat-error *ngIf="svcAddressHasError('zip', 'required')">Required Field*</mat-error>
                      <mat-error *ngIf="svcAddressHasError('zip', 'pattern')">Invalid Format*</mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="col-40-percentage">
                  <div class="form-group">
                    <mat-form-field [appearance]="appearance">
                      <mat-label>{{'nomenclature.utilityShort' | translate}} Acc. Number*</mat-label>
                      <input matInput [formControl]="sTwo.utilityAccountNo">
                      <mat-error *ngIf="sTwoHasError('utilityAccountNo', 'required')">Required Field*</mat-error>
                    </mat-form-field>
                  </div>
                </div>

              </div>
            </div>
          </ng-container>

          <div class="col-12 p-0" *ngIf="sTwo.multipleBills.valid">
            <div class="col-sm-12 col-md-5 mt-4">
              <mat-form-field [appearance]="appearance" class="zen-mat-textarea">
                <mat-label class="">Comments (Optional)</mat-label>
                <textarea matInput formControlName="comments"></textarea>
              </mat-form-field>
            </div>
          </div>

        </div>
      </form>

      <div *ngIf="selectedIndex === 2" class="text-start mx-2">
        <h3 class="my-5">What happens next?</h3>
        <ng-container
          *ngIf="(sTwo.multipleBills.value && sTwo.allAccountsBill.value === RadioBtnValues.NO) else secondCondition">
          <div class="d-flex col-md-12 p-0 align-items-center text-start">
            <div class="col-3 col-sm-2 col-md-1 p-0">
              <div class="zen-circle-bg green-1 medium">1</div>
            </div>
            <p class="col-9 col-sm-10 col-md-11 ms-3 mb-0">One of our team will be in contact shortly to confirm your
              property
              account details.</p>
          </div>
          <div class="d-flex col-md-12 p-0 align-items-center text-start my-4">
            <div class="col-3 col-sm-2 col-md-1 p-0">
              <div class="zen-circle-bg green-1 medium">2</div>
            </div>
            <p class="col-9 col-sm-10 col-md-11 ms-3 mb-0">Once confirmed a pricing report will be sent for you to
              instantly
              request your contact.</p>
          </div>
        </ng-container>
        <ng-template #secondCondition>
          <div class="d-flex col-md-12 p-0 align-items-center text-start">
            <div class="col-3 col-sm-2 col-md-1 p-0">
              <div class="zen-circle-bg green-1 medium">1</div>
            </div>
            <p class="col-9 col-sm-10 col-md-11 ms-3 mb-0">You will be sent an email containing a link to your price
              report.</p>
          </div>
          <div class="d-flex col-md-12 p-0 align-items-center text-start my-4">
            <div class="col-3 col-sm-2 col-md-1 p-0">
              <div class="zen-circle-bg green-1 medium">2</div>
            </div>
            <p class="col-9 col-sm-10 col-md-11 ms-3 mb-0">Confirm the price and instantly request the contract.</p>
          </div>
        </ng-template>
        <div class="d-flex col-md-12 p-0 align-items-center text-start">
          <div class="col-3 col-sm-2 col-md-1 p-0">
            <div class="zen-circle-bg green-1 medium">3</div>
          </div>
          <p class="col-9 col-sm-10 col-md-11 ms-3 mb-0">eSign the contract on any device and submit for booking. Once
            confirmed by the {{'nomenclature.supplier' | translate}}
            you will receive a booking confirmation email.</p>
        </div>
        <p *ngIf="orgSvc.organization" class="mt-5 mb-0 text-start">Any questions please
          contact {{orgSvc.organization.companyName}}
          at
          <b>{{orgSvc.organization.defaultEmailFrom}}</b></p>
      </div>

      <div *ngIf="selectedIndex === 0" class="d-flex justify-content-end mt-4">
        <button mat-raised-button color="accent" [disabled]="loading"
                (click)="handlePagination(LEAD_STEPS.STEP_2, LEAD_STEPS.STEP_1)">Next
        </button>
      </div>
      <div *ngIf="selectedIndex === 1" class="d-flex justify-content-end align-items-center mt-5">
        <button mat-button (click)="handlePagination()" [disabled]="loading">Cancel</button>
        <button mat-raised-button color="accent" class="ms-5" [disabled]="loading" (click)="handleComplete()">Complete</button>
      </div>

    </div>
  </div>
</app-zen-dialog-wrapper>
