import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '../../../../../../../../environments';
import {AuthenticationService} from '../authentication.service';
import { HttpClient } from '@angular/common/http';
import {take} from 'rxjs/operators';
import {RateCheckReportTabToggle} from '../../../../_model/custom/rcr-toggle.model';
import {RateCheckReportOverride} from '../../../_models/rate-check-report-override';
import {OrganizationManagementService} from '../organization-management.service';
import {RateCheckStatusEnum} from '../../../_models/rate-checks/rate-check-status';
import {RateCheckV3Model, RefreshPricingModel} from '../../../../../_modules/zen-rate-checks/_model/rate-check-v3.model';
import {ZenRequestRefreshDialogModel} from '../../../../_model/zen-request-refresh-dialog.model';

@Injectable({
  providedIn: 'root',
})
export class RateCheckV3Service {

  private baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient,
              private authService: AuthenticationService,
              private orgMgtSvc: OrganizationManagementService) {
  }

  createOrUpdateRateCheckReportOverride(customerId: number, rateCheckReportOverride: RateCheckReportOverride): Observable<RateCheckReportOverride> {
    if (rateCheckReportOverride.id) {
      return this.updateOverrideRateCheckReport(customerId, rateCheckReportOverride);
    } else {
      return this.createOverrideRateCheckReport(customerId, rateCheckReportOverride);
    }
  }

  createOverrideRateCheckReport(customerId: number, rateCheckReportOverride: RateCheckReportOverride): Observable<RateCheckReportOverride> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/rate-checks/${rateCheckReportOverride.rateCheckId}/rate_check_report_override`;
    return this.http
      .post<RateCheckReportOverride>(url, rateCheckReportOverride)
      .pipe(take(1));
  }

  updateOverrideRateCheckReport(customerId: number, rateCheckReportOverride: RateCheckReportOverride): Observable<RateCheckReportOverride> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/rate-checks/${rateCheckReportOverride.rateCheckId}/rate_check_report_override/${rateCheckReportOverride.id}`;
    return this.http
      .put<RateCheckReportOverride>(url, rateCheckReportOverride)
      .pipe(take(1));
  }

  updateAnonymizeSuppliers(customerId: number, rateCheckId: string, anonymizeSuppliers: boolean): Observable<boolean> {
    customerId = customerId || this.authService.getCurrentCustomerId();
    const url = `${this.baseUrl}/v3/customers/${customerId}/rate-checks/${rateCheckId}/anonymize-suppliers-toggle?enabled=${anonymizeSuppliers}`;
    return this.http.put<boolean>(url, {}).pipe(take(1));
  }

  /**
   * This API used to show/hide different report tabs.
   * @param customerId
   * @param rcId
   * @param toggleRequest
   */
  toggleRcReports(customerId: number, rcId: string, toggleRequest: RateCheckReportTabToggle): Observable<RateCheckReportTabToggle> {
    const url = `${this.baseUrl}/v3/customers/${customerId}/rate-checks/${rcId}/rate-check-report-toggle`;
    return this.http.put<RateCheckReportTabToggle>(url, toggleRequest).pipe(take(1));
  }


  /**
   * This should only be called after users complete step 1 in the rc request form
   * This will create an empty rate check object on the back-end with status = 50 (completed step 1)
   * @param {string} customerId
   * @param {string} organizationId
   * @param {string} customerContact The UUID of the customer contact selected
   * */
  createRateCheck(customerId: number, organizationId: string, customerContactId: string, step: RateCheckStatusEnum, rebookEnergyPlanId = null)
    : Observable<RateCheckV3Model> {
    const newRateCheck = {
      customerId,
      organizationId,
      customerContactId,
      statusCode: step,
      commodityType: 'GAS',
      rebookingEnergyPlanId: rebookEnergyPlanId
    };

    const url = `${environment.apiBaseUrl}/v3/customers/${customerId}/rate-checks`;
    return this.http.post<RateCheckV3Model>(url, newRateCheck).pipe(take(1));
  }

  /**
   * Refresh Pricing Request.
   * @param {string} CORE rateCheckId
   * @param {RateCheckStatusEnum} newStatus - new status for the rate check
   * @param {Date} requestedDueDate - requested due date for the refreshed rate check (optional)
   * @param {string} comments - comments provided by the user during rate check refresh
   * */
  refreshPricing(rateCheckId: string, newStatus: RateCheckStatusEnum, request: ZenRequestRefreshDialogModel): Observable<RefreshPricingModel> {
    return this.rateCheckStatusSync(rateCheckId, newStatus, request);
  }

  /**
   * To Sync the RC status between NatGas and CORE databases.
   * @param {string} CORE rateCheckId
   * @param {RateCheckStatusEnum} newStatus - new status for the rate check
   * @param {Date} requestedDueDate - requested due date for the refreshed rate check (optional)
   * @param {string} comments - comments provided by the user during rate check refresh
   * */
  rateCheckStatusSync(rateCheckId: string, newStatus: RateCheckStatusEnum, request: ZenRequestRefreshDialogModel): Observable<RefreshPricingModel> {
    let requestObj = {newStatus, requestDueDate: request?.dueDate, ...request};
    const url = `${environment.apiBaseUrl}/v2/organizations/${this.authService.getOrganizationId()}/rate-checks/${rateCheckId}/status`;
    return this.http.put<RefreshPricingModel>(url, requestObj).pipe(take(1));
  }
}
