import {Component, Inject, OnInit} from '@angular/core';
import {ZenDialogMsgService} from '../../_services/zen-dialog-msg.service';
import {ZenDialogActionButton, ZenDialogDataModel, ZenDialogDataType} from '../zen-dialog/zen-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {combineLatest, Observable, of, ReplaySubject} from 'rxjs';
import {LabelledTagOption, Tag, TagType} from '../../../_modules/portfolio/_model/tag.model';
import {map, startWith, take} from 'rxjs/operators';
import {isValidItemList, isValidOption} from '../../_zen-legacy-common/_utils/validator-utils';
import {AddressAutocompleteService, AddressFormType} from '../../_services/address-autocomplete.services';
import {TagService} from '../../_services/tag.service';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {PropertyV4Service} from '../../_services/v4/property-v4.service';
import StateUtility, {Utility} from '../../_zen-legacy-common/_models/properties-settings';
import {orderBy} from '../../_zen-legacy-common/_utils/orderby.utils';
import {zenRemoveDuplicates} from '../../_utils/zen-remove-duplicates.util';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {ItemListV4Service} from '../../_services/v4/item-list-v4.service';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {ItemListV4Model} from '../../_model/item-list-v4.model';
import {CustomerServiceV4} from '../../_services/v4/customer-v4.service';
import {PortfolioHelperService} from '../../../_modules/portfolio/_services/_helpers/portfolio-helper.service';
import {PortfolioLensHelperService} from '../../../_modules/portfolio/_services/_helpers/portfolio-lens-helper.service';
import {zenHasError} from '../../_utils/zen-has-error.util';
import {AuthenticationService} from '../../_zen-legacy-common/zen-common-services/_services/authentication.service';
import {CustomerAndPropertyType, PropertySubType} from '../../_zen-legacy-common/_enums/entity-types.enum';
import {EntityTypeService} from '../../_zen-legacy-common/zen-common-services/_services/entity-type.service';
import {MatSelectChange} from '@angular/material/select';
import {ChangeStatusObjectType} from '../../../_modules/portfolio/_enums/portfolio-tabs.enum';
import {ZenDialogPanelClassEnum} from '../../_enums/zen-dialogs.enum';
import {LenV4FormModel} from '../../../_modules/portfolio/_model/portfolio-lens.model';
import {PropertyAddUpdateDTO, PropertyDetailsV4Model} from '../../_model/property-v4.model';
import {PortfolioHierarchyLevelEnum} from '../../../_modules/portfolio/_enums/portfolio-hierarchy-level.enum';
import {ZenTableMenuOption} from '../../_components/zen-mat-table/zen-mat-table.component';
import {ZenErrorMsgEnum} from '../../_enums/zen-error-msg.enum';
import {TranslateService} from '@ngx-translate/core';
import {ZenBaseWithTranslateComponent} from '../../_components/zen-base-with-translate/zen-base-with-translate.component';


export const ISO_ZONE_OPTIONAL_STATE = ['TX', 'NY', 'MA'];
export const STATES_WITH_NO_RATE_CLASS = ['TX'];


@Component({
  selector: 'app-zen-edit-property-dialog',
  templateUrl: './zen-edit-property-dialog.component.html',
  styleUrls: ['./zen-edit-property-dialog.component.scss']
})
export class ZenEditPropertyDialogComponent extends ZenBaseWithTranslateComponent implements OnInit {
  form: UntypedFormGroup;
  activationChange: UntypedFormGroup;
  ZenDialogDataType = ZenDialogDataType;
  disableButton: boolean;
  appearance: MatFormFieldAppearance = 'outline';
  AddressFormType = AddressFormType; // to support google.maps.api autocomplete
  isEditMode: boolean;
  ZenIcons = ZenIconsEnum;

  // state
  stateOptions: string[] = [];
  filteredStateOptions: Observable<string[]>;
  propertyTypeOptions: Map<CustomerAndPropertyType, PropertySubType[]> = EntityTypeService.PROPERTY_TYPE_TO_SUBTYPE_MAP;

  // property type / subtype - used to set preselected value
  preselectedSubtypeString: string;

  // tags
  tagFilterCtrl = new UntypedFormControl('');
  tagOptions: LabelledTagOption[] = [];
  selectedTags: Partial<Tag>[] = [];
  TagType = TagType;

  // Customer search
  searchableCustomers: ItemListV4Model[] = [];
  customerCtrl = new UntypedFormControl(null, [Validators.required, isValidItemList()]);
  public filteredCustomers: ReplaySubject<ItemListV4Model[]> = new ReplaySubject();

  // LEN search
  typedLenName: string;
  lenCtrl = new UntypedFormControl(null, [Validators.required]);
  searchableLens: ItemListV4Model[] = [];
  public filteredLens: ReplaySubject<ItemListV4Model[]> = new ReplaySubject();

  // Utilities
  allStateUtilities: StateUtility[] = [];
  electricUtilities: Utility[] = [];
  natGasUtilities: Utility[] = [];

  // Error Msg
  errorMsg: string;

  // Show more
  showMore: boolean;
  lockedCustomerId: number;
  lockedLenId: string;
  propertyDet: PropertyDetailsV4Model;
  disableActivationStatus = false;
  activatedProperty = false;
  deactivatedProperty = false;

  isOutSidePortfolio: boolean; // Flag used to know if this edit dialog used inside/outside the portfolio flow.

    protected readonly PortfolioHierarchyLevelEnum = PortfolioHierarchyLevelEnum;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              public dialogRef: MatDialogRef<ZenEditPropertyDialogComponent>,
              private zenDialogSvc: ZenDialogMsgService,
              private tagService: TagService,
              private formBuilder: UntypedFormBuilder,
              private itemListV4Svc: ItemListV4Service,
              private orgMgtSvc: OrganizationManagementService,
              private propV4Svc: PropertyV4Service,
              private custV4Svc: CustomerServiceV4,
              private pfHelpSvc: PortfolioHelperService,
              private pfLenHelpSvc: PortfolioLensHelperService,
              public authSvc: AuthenticationService,
              public addressAutocompleteService: AddressAutocompleteService,
              public translateSvc: TranslateService
  ) {
    super(translateSvc);
  }

  ngOnInit(): void {
    this.init();
    this.orgMgtSvc.getStateUtilities(false).subscribe({
      next: res => {
        this.allStateUtilities = res;
        this.stateOptions = res.map(s => s.stateId);
        this.form.controls.state.setValidators([Validators.required, isValidOption(this.stateOptions)]);
      }, error: err => {
        this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      }
    });
  }

  init() {
    this.loadForm();
    if (this.data.data) {
      this.isEditMode = Boolean(this.data.data && this.data.data.id);
      this.lockedCustomerId = Number(this.data.data.lockedCustomerId);
      this.lockedLenId = this.data.data.lockedLenId;
      this.isOutSidePortfolio = this.data.data.isOutSidePortfolio;
      this.showMore = !this.isEditMode || !this.data.data.lockedLenId;
    }

    this.loadCustomers();

    if (!this.lockedCustomerId) {
      this.customerCtrl.valueChanges
        .subscribe((customer: ItemListV4Model) => {
          this.filterCustomers();
          if (customer && customer.key) {
            this.onCustomerChange(customer.key);
          }
        });
    }

    this.lenCtrl.valueChanges
      .subscribe((len: ItemListV4Model) => {
        this.filterLens();
        if (len && len.key) {
          this.showMore = false;
          this.enableFormCheck();
        }
      });

    // listen to changes in service Electric checkbox
    combineLatest([this.controls.isSupportElectric.valueChanges,
      this.controls.state.valueChanges])
      .subscribe(([checked, state]) => {
        if (checked) {
          this.controls.electricUtilityId.setValidators([Validators.required]);
          this.controls.isoZone.setValidators(ISO_ZONE_OPTIONAL_STATE.includes(state) ? [] : [Validators.required]);
        } else {
          this.controls.electricUtilityId.setValue(null);
          this.controls.isoZone.setValue(null);
        }
        this.controls.electricUtilityId.updateValueAndValidity();
        this.controls.isoZone.updateValueAndValidity();

        // Handle utilities w.r.t state changes
        this.getStateBasedUtilities();
      });

    // listen to changes in service NatGas checkbox
    this.controls.isSupportGas.valueChanges
      .subscribe(checked => {
        this.controls.gasUtilityId.setValidators(checked ? [Validators.required] : []);
        if (!checked) {
          this.controls.gasUtilityId.setValue(null);
        }
        this.controls.gasUtilityId.updateValueAndValidity();
      });

    if (this.isEditMode) {
      this.getPropertyDetails();
    }
  }

  enableFormCheck() {
    if (this.customerCtrl.value && this.lenCtrl.value) {
      this.form.enable();
      if (this.disableActivationStatus) {
        this.controls.active.disable();
      }
    } else {
      this.form.disable();
    }
  }

  // To support edit mode
  getPropertyDetails() {
    this.disableDialogActions(true);
    combineLatest([
      this.propV4Svc.getPropertyDetails(this.data.data.customerId, this.data.data.id),
      this.propV4Svc.bulkActivationCheckProperties(this.data.data.customerId, [this.data.data.id])
    ]).pipe(take(1)).subscribe(([res, activationCheck]) => {
      this.disableActivationStatus = !activationCheck.activatable;
      this.fillPropertyDetails(res);
      if (this.disableActivationStatus) {
        this.controls.active.disable();
      }
      if (res.natGasUtility != null || res.electricUtility != null) {
        this.controls.state.disable();
      }
    }, error => {
      console.log('Error: getPropertyDetails ', error);
      this.disableDialogActions(false);
      this.zenDialogSvc.openToast(false);
    });
  }

  fillPropertyDetails(res: PropertyDetailsV4Model) {
    this.form.patchValue(res);
    this.propertyDet = res;
    this.selectedTags = res.tags ? res.tags : [];
    if (res.type && res.subType) {
      this.preselectedSubtypeString = res.type + '||' + res.subType;
    }
    this.setPropertyTypeAndSubtype(res.type, res.subType);
    this.disableDialogActions(false);
    if (res.lenId && res.lenName) {
      this.lenCtrl.setValue({key: res.lenId, value: res.lenName});
    }
  }

  getStateBasedUtilities() {
    const state = this.controls.state.value;
    this.electricUtilities = [];
    this.natGasUtilities = [];
    this.allStateUtilities.filter(stUtil => stUtil.stateId === state && stUtil.utilities.forEach(u => {
      if (u.elecProvider) {
        this.electricUtilities.push(u);
      }
      if (u.gasProvider) {
        this.natGasUtilities.push(u);
      }
    }));
    this.electricUtilities = zenRemoveDuplicates(orderBy(this.electricUtilities, 'utilityName'), 'utilityId');
    this.natGasUtilities = zenRemoveDuplicates(orderBy(this.natGasUtilities, 'utilityName'), 'utilityId');
  }

  displayFn(item: ItemListV4Model): string {
    return item && item.value ? item.value : '';
  }

  protected filterCustomers() {
    let search = this.customerCtrl.value;
    setTimeout(() => {
      this.loadCustomers(search);
    }, 100);
  }

  protected filterLens() {
    let search = this.lenCtrl.value;
    if (typeof search === 'string') {
      this.loadCustomerLens(this.customerId, search);
    }
  }

  loadCustomers(search?: string): void {
    if (this.authSvc.isAdvisor() && !this.lockedCustomerId) {
      this.itemListV4Svc.getOrgCustomerItemList(search).subscribe(result => {
        this.searchableCustomers = result;
        this.filteredCustomers.next(this.searchableCustomers);
        this.handleLockedValues();
      }, error => {
        console.log('Error: Get org customer item list ', error);
        this.disableDialogActions(false);
        this.zenDialogSvc.openToast(false);
      });
    } else {
      // Customer item-list API wont work for customers. So, adding locked customer into list to support UI.
      this.addLockedCustomerInSearchList();
    }
  }

  addLockedCustomerInSearchList() {
    this.custV4Svc.getCustomerDetails(this.lockedCustomerId).subscribe(customer => {
      const _customer = {key: customer.customerId, value: customer.companyName} as ItemListV4Model
      this.searchableCustomers.push(_customer);
      this.customerCtrl.setValue(_customer);
      this.filteredCustomers.next(this.searchableCustomers);
      this.onCustomerChange(this.lockedCustomerId);
    }, error => {
      console.log('Error: Get locked customer det ', error);
      this.disableDialogActions(false);
      this.zenDialogSvc.openToast(false);
    });
  }

  loadCustomerLens(customerId: number, search?: string): void {
    if (this.customerId) {
      this.itemListV4Svc.getCustomerLenItemList(this.customerId, [this.lockedLenId], search).subscribe(result => {
        this.searchableLens = result;
        this.filteredLens.next(this.searchableLens);
        this.handleLockedValues();
      }, error => {
        console.log('Error: Get customer lens ', error);
        this.zenDialogSvc.openToast(false);
      });
    }
  }

  toggleShowMore() {
    this.showMore = !this.showMore;
  }

  // Handling locked customer to support customer details page - Add/Edit property option
  handleLockedValues() {
    if (this.data.data) {
      setTimeout(() => {
        if (this.lockedCustomerId && !this.customerCtrl.value) {
          const _customer = this.searchableCustomers.find(c => c.key === this.lockedCustomerId);
          this.customerCtrl.setValue(_customer);
        }
        if (this.lockedLenId && !this.lenCtrl.value) {
          const _len = this.searchableLens.find(l => l.key === this.lockedLenId);
          this.lenCtrl.setValue(_len);
        }
      }, 100); // adding this delay to support tags and address api calls
    }
  }

  get controls() {
    return this.form.controls;
  }

  get customerId() {
    return this.customerCtrl.value ? this.customerCtrl.value?.key : null;
  }

  get lenId() {
    return this.lenCtrl.value ? this.lenCtrl.value?.key : null;
  }

  loadForm(): void {
    this.form = this.formBuilder.group({
      active: new UntypedFormControl(true, []),
      label: new UntypedFormControl(null, [Validators.required]),
      type: new UntypedFormControl(null, [Validators.required]),
      subType: new UntypedFormControl(null, [Validators.required, isValidOption(Object.values(PropertySubType))]),
      // if service -> Electricity
      isSupportElectric: new UntypedFormControl(null, []),
      electricUtilityId: new UntypedFormControl(null, []),
      isoZone: new UntypedFormControl(null, []),
      // if service -> NatGas
      isSupportGas: new UntypedFormControl(null, []),
      gasUtilityId: new UntypedFormControl(null, []),

      // service address
      street1: new UntypedFormControl(null, [Validators.required]),
      street2: new UntypedFormControl(null, []),
      state: new UntypedFormControl(null, [Validators.required, isValidOption(this.stateOptions)]),
      city: new UntypedFormControl(null, [Validators.required]),
      zip: new UntypedFormControl(null, [Validators.required]),
      // if taxExempt checked -> taxCertDocumentTempId is required.
      taxExempt: new UntypedFormControl(null, []),
      taxCertificateDocumentTempId: new UntypedFormControl(null, [])
    });

    // This is FormGroup is a model of Partial<ActivationChangeBulkV4Model>
    this.activationChange = this.formBuilder.group({
      reasonId: new UntypedFormControl(null, [Validators.required]),
      assumption: new UntypedFormControl(null),
      assumptionDt: new UntypedFormControl(null),
      reasonCustom: new UntypedFormControl(null, [Validators.maxLength(100)])
    });

    this.form.disable();

    // listen to changes in State, and filter options accordingly
    this.filteredStateOptions = this.controls.state.valueChanges.pipe(
      startWith(''),
      map(query => this.filterStates(query)),
    );
  }

  filterTypes(list: Map<CustomerAndPropertyType, PropertySubType[]>, search: string): Observable<Map<CustomerAndPropertyType, PropertySubType[]>> {
    if (!search) {
      return of(list);
    } else {
      let filteredTypes = new Map<CustomerAndPropertyType, PropertySubType[]>();
      search = search.toLowerCase();
      for (const [type, subtype] of list.entries()) {
        if (type.toLowerCase().includes(search)) {
          filteredTypes.set(type as CustomerAndPropertyType, subtype);
        } else {
          const filteredSubtypes = subtype.filter(s => s.toLowerCase().includes(search));
          if (filteredSubtypes.length) {
            filteredTypes.set(type as CustomerAndPropertyType, filteredSubtypes);
          }
        }
      }
      return of(filteredTypes);
    }
  }

  onCustomerChange(customerId: number) {
    this.controls.active.setValue(true);
    this.enableFormCheck();
    this.initTagOptions();
    this.loadCustomerLens(customerId);
  }

  initTagOptions() {
    this.tagService.getTagOptions(TagType.PROPERTY, this.customerId).subscribe(options => {
      this.tagOptions = options.map(opt => {
        return {
          ...opt,
          label: this.tagService.getTagOptionLabel(opt),
        }
      }).sort((a, b) => a.label.localeCompare(b.label));
    }, err => {
      console.log(err);
      return [];
    });
  }

  handleAddTag(tag: Partial<Tag>) {
    this.selectedTags.push(tag);
    this.tagFilterCtrl.reset('');
  }

  handleRemoveTag(tag: Partial<Tag>) {
    this.selectedTags = this.selectedTags.filter(t => t.name !== tag.name || t.tagGroup?.name !== tag.tagGroup?.name);
  }

  handleSelectionChange(event: MatSelectChange) {
    if (this.isEditMode) {
      if (event.source.value === false) {
        this.activatedProperty = false;
        if (this.propertyDet === undefined || this.propertyDet?.active === true) {
          this.deactivatedProperty = true;
        }
      } else {
        this.deactivatedProperty = false;
        if (this.propertyDet === undefined || this.propertyDet?.active === false) {
          this.activatedProperty = true;
        }
      }
    }
  }

  filterStates(query: string) {
    let filteredStates = [...this.stateOptions];
    let search = this.controls.state.value;
    if (!search) {
      return filteredStates;
    } else {
      search = search.toLowerCase();
      return this.stateOptions.filter(s => s.toLowerCase().includes(search));
    }
  }

  submit(action: ZenDialogActionButton | ZenTableMenuOption) {
    this.errorMsg = null; // to clear error message
    const _formData = {
      ...this.form.value,
      state: this.form.controls.state.value,
      customerId: this.customerId,
      lenId: this.lenId,
      tags: this.selectedTags,
    } as PropertyAddUpdateDTO;
    if (typeof _formData.taxCertificateDocumentTempId !== 'string') {
      delete _formData.taxCertificateDocumentTempId;
    }
    if (action.label === 'Cancel') {
      this.dialogRef.close();
    } else {
      this.customerCtrl.markAllAsTouched();
      this.lenCtrl.markAllAsTouched();
      this.form.markAllAsTouched(); // to trigger mat-error

      _formData.activationChange = {active: _formData.active, id: this.propertyDet?.activationAudit?.id || null, ...this.activationChange.value };

      // For already inactive Properties we are hiding this reason fields form.
      // Only for the active Properties we are showing this reason fields
      if (!this.propertyDet?.active || (_formData.active || (!_formData.active && this.activationChange.valid))) {
        if (this.form.valid && this.customerCtrl.valid && this.lenCtrl.valid) {
          if (this.isEditMode) {
            // updating a property
            this.handleSubscribe(this.propV4Svc.updateProperty(this.customerId, this.data.data.id, _formData), action);
          } else if (!this.isEditMode) {
            // creating a property
            this.handleSubscribe(this.propV4Svc.createProperty(this.customerId, _formData), action);
          }
        }
      }
    }
  }

  handleSubscribe(sub: Observable<PropertyDetailsV4Model>, action: ZenDialogActionButton | ZenTableMenuOption) {
    this.disableDialogActions(true);
    sub.subscribe({
      next: property => {

        if (this.isOutSidePortfolio) {
          this.zenDialogSvc.openToast(true);
        } else {
          if (this.activatedProperty) {
            this.pfHelpSvc.openSuccessGuidance(this.pfHelpSvc.getReactivateSuccessBodyText([property], ChangeStatusObjectType.PROPERTY));
          } else if (this.deactivatedProperty) {
            this.pfHelpSvc.openSuccessGuidance(this.pfHelpSvc.getDeactivateSuccessBodyText([property], ChangeStatusObjectType.PROPERTY));
          } else {
            this.zenDialogSvc.openToast(true);
          }
        }

        this.pfHelpSvc.handleDetailsEntityStatusUpdate(this.controls.active.value);

        this.disableDialogActions(false);
        action.command(property);
      }, error: e => {
        console.log(e);
        if (e.error && e.error.status === 400 && e.error.message) {
          this.errorMsg = e.error.message
        }
        this.disableDialogActions(false);
        this.zenDialogSvc.openToast(false);
      }
    });
  }

  disableDialogActions(disable: boolean) {
    this.disableButton = disable;
  }

  copyLenName(lenName: string) {
    this.typedLenName = lenName;
  }

  handleAddNewLen() {
    this.dialogRef.addPanelClass(ZenDialogPanelClassEnum.HIDE_DIALOG);
    this.pfLenHelpSvc.handleAddEditLen(null, this.customerId,
      (addedLen) => this.handleAfterAddLen(addedLen), () => this.onAddLenCancel(), true);
  }

  handleAfterAddLen(addedLen: LenV4FormModel) {
    this.lenCtrl.setValue({key: addedLen.id, value: addedLen.name});
    this.zenDialogSvc.openToast(true);
  }

  onAddLenCancel() {
    setTimeout(() => this.dialogRef.removePanelClass(ZenDialogPanelClassEnum.HIDE_DIALOG), 100);
  }

  hasError(formControlName: string, formErrorName: string): boolean {
    return zenHasError(this.form, formControlName, formErrorName);
  }

  setPropertyTypeAndSubtype(propertyType: CustomerAndPropertyType, propertySubtype: PropertySubType) {
    this.form.controls.type.setValue(propertyType);
    this.form.controls.subType.setValue(propertySubtype);
  }
}
