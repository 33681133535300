export enum ZenHierarchicalGrouping {
  LEN = 'LEN',
  PROPERTY = 'PROPERTY',
  CONTRACT = 'CONTRACT',
  CONTRACT_STATUS = 'CONTRACT_STATUS',
  INCLUDE_TAGS = 'INCLUDE_TAGS',
  EXCLUDE_TAGS = 'EXCLUDE_TAGS',
  INCLUDE_TYPES = 'INCLUDE_TYPES',
  EXCLUDE_TYPES = 'EXCLUDE_TYPES',
}
