import {EventEmitter, Inject, Injectable} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {environment} from '../../../../../../../environments';
import {User} from '../../_models/user';
import {LoginResult} from '../../_models/login-result';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Customer} from '../../_models/customer';
import {catchError, flatMap, map, take, tap} from 'rxjs/operators';
import {CacheService} from './cache.service';
import {WixAnswersService} from './wix.service';
import {OrgMemberSetting} from '../tili-services/models/org-member-setting';
import {ZenRoutesEnum} from '../../../_enums/zen-routes.enum';
import {PersistState} from '@datorama/akita';
import {GenerateTokenRes} from '../tili-services/models/rate-check';


export interface CustomerInfo {
  companyName: string;
  userName: string;
}

export interface SkipTacInfo {
  skipTac: string;
}

const CURRENT_OWNER_CUSTOMER_KEY = 'currentOwnedCustomer';
const CURRENT_USER_KEY = 'currentUser';

const ERROR_MSG = 'Oops we seem to be experiencing an issue. Please try again later.';

@Injectable()
export class AuthenticationService {
  static TILI_ROUTE = 'advisor';
  static ZEN_ROUTE = 'customer';
  static ADVISOR_TOS_SIGNED = 'advisorTosSigned';
  static CUSTOMER_TAC_APPROVED = 'customerTacApproved';
  toggleTacApprovalPopup: EventEmitter<boolean> = new EventEmitter();

  // used to bring user back to their desired URL after authenticating
  public redirectUrl: string;

  // holds the currently authenticated customer in memory
  private currentUser: User;
  private currentOrganizationCustomerSubject: Subject<Customer> = new Subject<Customer>();
  private currentOrganizationCustomer: Customer;
  public onCustomerChange: Subject<Customer> = new Subject<Customer>();
  public customerInfo: Subject<CustomerInfo> = new Subject<CustomerInfo>();
  public currentUserSub: Subject<User> = new Subject<User>();

  // Used across the platform to set customer/company name label w.r.t user role.
  customerNameLabel: string;

  private static handleLoginError(error: any) {
    return of(
      <LoginResult>{
        success: false,
        signupComplete: false,
        reason: ((error && error.status >= 500 && error.status <= 600) || (!error.error || !error.error.message)) ? ERROR_MSG : error.error.message,
        roles: [],
        status: error.status
      });
  }

  private static _mapAuthUserResponseToAuthResponse(r: AuthUserResponse, c: Customer): AuthResponse {
    return {
      customer: c,
      customerIds: r.customerIds,
      organizationId: r.organizationId,
      userId: r.userId,
      properties: [],
      token: r.token,
      roles: r.roles,
      setting: r.setting
    } as AuthResponse
  }

  private static authHeaders(username: string, password: string): any {
    const encodedPassword = btoa(password.trim()).replace(/\=/g, '.');
    return {
      'Content-Type': 'application/json',
      'X-Auth-Username': username.trim(),
      'X-Auth-Password': encodedPassword,
      'X-Auth-Domain': 'zentility'
    };
  }


  constructor(private http: HttpClient,
              private router: Router,
              private route: ActivatedRoute,
              private cacheService: CacheService,
              private wixAnswersService: WixAnswersService,
              @Inject('persistStorage') private persistStorage: PersistState
  ) {
    // set token if saved in local storage
    try {
      this.currentUser = JSON.parse(localStorage.getItem(CURRENT_USER_KEY)) as User;
      if (this.currentUser && !this.isUserValid(this.currentUser)) {
        throw new Error('invalid user');
      }
    } catch (e) {
      console.warn('failed to recover stored user information', e);
      this.logout();
    }
    this.currentOrganizationCustomerSubject.subscribe(customer => {
      this.currentOrganizationCustomer = customer;
      localStorage.setItem(CURRENT_OWNER_CUSTOMER_KEY, JSON.stringify(customer));
      this.onCustomerChange.next(customer);
    });
  }

  isUserValid(u: User): boolean {
    return u && (u.customerId !== undefined
      && u.userId !== undefined
      && u.roles !== undefined
      && u.token !== undefined);
  }


  loginWithToken(token: string): Observable<LoginResult> {
    const url = `${environment.apiBaseUrl}/v2/app/identity?user_token=${token}`;
    return this.http.get<AuthResponse>(url)
      .pipe(
        map(response => {
          return this.mapAuthResponseToLoginResult(response);
        }),
        catchError((error: any) => {
          return AuthenticationService.handleLoginError(error);
        }));
  }

  public getCustomer(customerId: number): Observable<Customer> {
    const url = `${environment.apiBaseUrl}/v1/customers/${customerId}`;
    return this.http.get<Customer>(url).pipe(take(1));
  }

  private populateAuthResponse(r: AuthUserResponse, specificCustomerId?: number): Observable<AuthResponse> {
    const isAdvisor = (r.roles.filter(x => x.toString().includes('ROLE_BROKER')).length > 0);
    if (r.customerIds != null && (r.customerIds.length === 1 || specificCustomerId) && !isAdvisor) {
      let finalCustomerId = specificCustomerId ? specificCustomerId : r.customerIds[0];
      const customerObs: Observable<Customer> = this.getCustomer(finalCustomerId);
      return customerObs.pipe(take(1),
        map((customer) => {
          return AuthenticationService._mapAuthUserResponseToAuthResponse(r, customer);
        })
      );
    } else {
      return of(AuthenticationService._mapAuthUserResponseToAuthResponse(r, null));
    }
  }

  authenticate(username: string, passwd: string, organizationId?: string, specificCustomerId?: number) {
    const password = btoa(passwd.trim()).replace('=', '.');
    const req = {
      email: username.trim(),
      ...{password, organizationId, customerId: specificCustomerId}
    };

    return this.http.post<AuthUserResponse>(`${environment.apiBaseUrl}/v1/authenticate_user`, req)
      .pipe(
        tap(r => {
          this.currentUser = {
            organizationId: r.organizationId,
            customerId: r.customerId,
            customerIds: r.customerIds,
            userId: r.userId,
            token: r.token,
            roles: r.roles,
            setting: r.setting
          };
        }),
        flatMap((r) => this.populateAuthResponse(r, specificCustomerId)),
        map((res) => {
          return this.mapAuthResponseToLoginResult(res, specificCustomerId);
        }),
        catchError((error: any) => {
          return AuthenticationService.handleLoginError(error);
        }));
  }

  public logoutServiceCall(userId: string) {
    const url = `${environment.apiBaseUrl}/v1/users/${userId}/logout`;
    return this.http.post<Customer>(url, {}).pipe(take(1));
  }

  login(username: string, password: string): Observable<LoginResult> {
    const headers = AuthenticationService.authHeaders(username, password);
    this.cacheService.clearAllCache();
    return this.http
      .post<AuthResponse>(environment.apiBaseUrl + '/v1/authenticate', '', {
        headers: headers
      }).pipe(map((res) => {
          return this.mapAuthResponseToLoginResult(res);
        }),
        catchError((error: any) => {
          return AuthenticationService.handleLoginError(error);
        }));
  }

  public getMultiCustomerLoginResult(res: AuthResponse): LoginResult {
    return <LoginResult>{
      userId: res.userId,
      customerIds: res.customerIds,
      success: true,
      reason: '',
      roles: null,
      signupComplete: true
    };
  }


  public isMultiCustomerResult( specificCustomerId: number,  customerIds: number[], isAdvisor: boolean) {
    return specificCustomerId == null &&  customerIds != null && customerIds.length > 1 && !isAdvisor;
  }

  public mapAuthResponseToLoginResult(res: AuthResponse, specificCustomerId?: number): LoginResult {
    const isAdvisor = (res.roles.filter(x => x.toString().includes('ROLE_BROKER')).length > 0);

    // If this isa multi-customer login, we will return the LoginResult and redirect to a second screen to pick a customer.
    if (this.isMultiCustomerResult(specificCustomerId, res.customerIds, isAdvisor)) {
      return this.getMultiCustomerLoginResult(res);
    }

    let customerId = null;
    const roles = res.roles;
    const setting = res.setting;
    // Set current user based on advisor or customer user
    if (res.customer && !isAdvisor) {
      // you should only be here if you are a customer
      const customer = res.customer;
      customerId = specificCustomerId ?? customer.customerId;
      this.setUserApprovalStatus(AuthenticationService.CUSTOMER_TAC_APPROVED, res.customer.tacApproved);
      this.setCurrentUser(res.organizationId, customerId, res.userId, res.token, roles, setting);
    } else {
      customerId = null;
      this.setCurrentUser(res.organizationId, customerId, res.userId, res.token, roles, setting);
    }

    // Return login result object
    return <LoginResult>{
      success: true,
      reason: '',
      roles: roles,
      signupComplete: true // isSignupComplete
    };
  }

  setCurrentUser(organizationId: string, customerId: number, userId: string, token: string, roles: any[], setting?: any) {
    this.currentUser = {
      organizationId: organizationId,
      customerId: customerId,
      userId: userId,
      token: token,
      roles: roles,
      setting: setting
    };
    this.currentUserSub.next(this.currentUser); // allow subscribe to this
    localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(this.currentUser));
    this.setCustomerLabel();
  }

  logout(): void {
    if (this.currentUser) {
      this.logoutServiceCall(this.currentUser.userId).subscribe(res => {
        this.handleAfterLogout();
      }, (err) => {
        console.log('ERR: logoutServiceCall ', err);
        this.handleAfterLogout();
      });
    } else {
      this.handleAfterLogout();
    }
  }

  handleAfterLogout() {
    this.removeUserApprovalStatus();
    this.currentUser = null;
    // Clearing out the current redirect url so that system does not attempt to redirect
    // a new user to a page of a previous user that it does not have access to in the system
    this.redirectUrl = null;
    localStorage.removeItem(CURRENT_USER_KEY);
    localStorage.removeItem(CURRENT_OWNER_CUSTOMER_KEY);
    this.cacheService.clearAllCache();
    this.wixAnswersService.hideWixAnswers();
    this.persistStorage.clearStore();
    localStorage.clear();
    this.router.navigate(['/']); // Goto login page
  }

  clearStoredCurrentCustomer() {
    localStorage.removeItem(CURRENT_OWNER_CUSTOMER_KEY);
  }

  public getStoredCurrentOwnedCustomer(): Customer {
    if (localStorage.getItem(CURRENT_OWNER_CUSTOMER_KEY) === null) {
      return null;
    } else {
      try {
        return JSON.parse(localStorage.getItem(CURRENT_OWNER_CUSTOMER_KEY)) as Customer;
      } catch (e) {
        console.log('stored owned customer is invalid');
        this.clearStoredCurrentCustomer();
        return null;
      }
    }
  }

  getCurrentCustomerId(): number {
    if (!this.currentUser) {
      this.router.navigate(['/']);
      this.clearStoredCurrentCustomer();
      return null;
    } else {
      if (this.currentOrganizationCustomer) {
        return this.currentOrganizationCustomer.customerId;
      } else if (this.getStoredCurrentOwnedCustomer() !== null) {
        return this.getStoredCurrentOwnedCustomer().customerId;
      }
      return this.currentUser.customerId;
    }
  }

  getCurrentUserId(): string {
    if (!this.currentUser) {
      return null;
    } else {
      return this.currentUser.userId;
    }
  }

  getCurrentAuthToken(): string {
    if (!this.currentUser) {
      this.router.navigate(['/']);
      return null;
    } else {
      return this.currentUser.token;
    }
  }

  hasRole(role: ROLE): boolean {
    return this.getCurrentRoles().includes(role.valueOf());
  }

  hasAnyRole(roles: ROLE[]): boolean {
    return this.getCurrentRoles().some(r => roles.includes(r as ROLE));
  }

  isAdmin(): boolean {
    return this.hasAnyRole(ADMIN_ROLES);
  }

  isCustomer(): boolean {
    return this.hasAnyRole(CUSTOMER_ROLES);
  }

  isAdvisor(): boolean {
    return this.isAdmin() || this.hasAnyRole(ADVISOR_ROLES);
  }

  getUserBaseRoute() {
    return this.isCustomer() ? ZenRoutesEnum.CUSTOMER : ZenRoutesEnum.ADVISOR;
  }

  getCurrentRoles(): string[] {
    if (this.currentUser) {
      return this.currentUser.roles;
    } else {
      return [];
    }
  }

  getAjaxHttpHeaders(): HttpHeaders {
    const headers = {'Content-Type': 'application/json'};
    if (this.currentUser && this.currentUser.token) {
      headers['X-Auth-Token'] = this.currentUser.token;
    }
    return new HttpHeaders(headers);
  }

  isCurrentlyLoggedIn(): boolean {
    return this.currentUser != null;
  }

  validateAuthToken(): Observable<boolean | HttpClient> {
    if (!this.currentUser) {
      return of(false);
    }
    return this.http
      .get(environment.apiBaseUrl + '/v1/check_legacy_mobile_authenticated', {
        headers: {
          'X-Auth-Token': this.currentUser.token
        }
      })
      .pipe(map((response: Response) => {
          return response.status === 200;
        }),
        catchError((error: any) => {
          this.currentUser = undefined;
          return of(false);
        })
      );
  }

  public userIsCustomer(): boolean {
    return this.isCustomer();
  }

  public userHasRole(role: string): boolean {
    if (!role) {
      return false;
    }
    return this.getCurrentRoles().indexOf(role) !== -1;
  }

  public getCurrentUser(): Observable<User> {
    return of(this.currentUser);
  }

  public viewCustomerInOrganization(customer: Customer): void {
    if (this.currentUser) {
      // set next customer only if customer is not the same as existing to block duplicate calls
      if (!this.currentOrganizationCustomer ||
        !customer ||
        this.currentOrganizationCustomer.customerId !== customer.customerId) {
        this.currentOrganizationCustomerSubject.next(customer);
      }
    }
  }

  getOrganizationId() {
    return this.currentUser.organizationId;
  }

  setUserApprovalStatus(key, status: boolean) {
    if (status) {
      localStorage.setItem(key, status.toString());
    }
  }

  getUserApprovalStatus(key) {
    return (localStorage.getItem(key) === 'true');
  }

  removeUserApprovalStatus() {
    localStorage.removeItem(AuthenticationService.CUSTOMER_TAC_APPROVED);
    localStorage.removeItem(AuthenticationService.ADVISOR_TOS_SIGNED);
  }

  skipTac(params: SkipTacInfo) {
    if (params.skipTac === 'true') {
      this.hideTacDialog();
    }
  }

  hideTacDialog() {
    this.setUserApprovalStatus(AuthenticationService.CUSTOMER_TAC_APPROVED, true);
    this.toggleTacApprovalPopup.next(true);
    if (this.route.snapshot && this.route.snapshot && this.route.snapshot.queryParams &&
      this.route.snapshot.routeConfig && this.route.snapshot.routeConfig.path.toLowerCase().includes('sign')) {
      this.router.navigate(['..']);
    }
  }

  /**
   * Supports the ?auth query param to set the current user
   */
  loginIfAuthInUrl() {
    // check the angular route
    let auth: string = this.route.snapshot.queryParamMap.get('auth');
    let authResp = null;
    if (auth !== null && auth !== '') {
      authResp = JSON.parse(atob(auth)) as AuthUserResponse;
    } else {
      // auth is null, see if we can still get it via the URL
      // its often null on the route snapshot on a fresh redirect
      auth = this.getParameterByName('auth');
      if (auth !== null && auth !== '') {
        authResp = JSON.parse(atob(auth)) as AuthUserResponse;
      }
    }
    if (authResp !== null) {
      // set current user
      this.setCurrentUser(authResp.organizationId, authResp.customerId, authResp.userId, authResp.token, authResp.roles);
    }
  }

  getParameterByName(name) {
    let match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }


  /**
   * Generates a time-sensitive customer token to access the rate check report without logging in
   * @param customerId
   * @param organizationId
   */
  generateTemporalCustomerToken(customerId: number): Observable<GenerateTokenRes> {
    let url = `${environment.apiBaseUrl}/v2/organizations/${this.getOrganizationId()}/customers/${customerId}/generate-token`;
    return this.http.post<GenerateTokenRes>(url, {}).pipe(take(1));
  }

  setCustomerLabel() {
    this.customerNameLabel = this.isAdvisor() ? 'Customer Name' : 'Company Name';
  }
}

interface AuthResponse {
  customerIds: number[]; // List of customer ids associated with this user, can be 1 to many
  customer: any;
  organizationId: string;
  userId: string;
  properties: any;
  token: string;
  roles: string[];
  setting: OrgMemberSetting;
}

export interface AuthUserResponse {
  customerIds: number[]; // List of customer ids associated with this user, can be 1 to many
  customerId: number;
  organizationId: string;
  userId: string;
  organizationRole: string;
  token: string;
  roles: string[];
  setting: OrgMemberSetting;
}


export enum ROLE {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_ADMIN_SUPER = 'ROLE_ADMIN_SUPER',
  ROLE_ADMIN_ACCOUNT_MGR = 'ROLE_ADMIN_ACCOUNT_MGR',
  ROLE_ADMIN_PAYMENTS = 'ROLE_ADMIN_PAYMENTS',
  ROLE_ADMIN_SUPPLIERS = 'ROLE_ADMIN_SUPPLIERS',

  ROLE_ORG_ADMIN = 'ROLE_ORG_ADMIN',
  ROLE_ADVISOR = 'ROLE_BROKER',
  ROLE_ADVISOR_FINANCE = 'ROLE_BROKER_FINANCE',
  ROLE_DOMAIN_USER = 'ROLE_DOMAIN_USER'
}

// @ts-ignore
export const ADMIN_ROLES = [ROLE.ROLE_ADMIN, ROLE.ROLE_ADMIN_SUPER, ROLE.ROLE_ADMIN_ACCOUNT_MGR,
                            ROLE.ROLE_ADMIN_PAYMENTS, ROLE.ROLE_ADMIN_SUPPLIERS];
export const ADVISOR_ROLES = [ROLE.ROLE_ADVISOR, ROLE.ROLE_ADVISOR_FINANCE, ROLE.ROLE_ORG_ADMIN];
export const CUSTOMER_ROLES = [ROLE.ROLE_DOMAIN_USER];
