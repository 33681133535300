import {CommodityType} from '../_zen-legacy-common/_models/commodity';
import {RateCheckStatusEnum} from '../_zen-legacy-common/_models/rate-checks/rate-check-status';
import {ZenNatGasPaymentTermEnum} from '../../_modules/zen-rate-check-request-v2/_enums/rc-req-natural-gas.enum';
import {ContractBillingMethod} from '../_enums/zen-contract.enum';
import {BasisPricingLocation} from './contract-v4.model';
import {ZenRcReqRenewableOptionsEnum} from '../../_modules/zen-rate-check-request-v2/_enums/rc-req-electricity.enum';

export interface RateCheckRequestV4FlowModel {
  // creation
  commodityType: CommodityType;
  state: string;
  customerId: number;
  statusCode: RateCheckStatusEnum;
  canTransact: boolean; // default true
  timeQuoteDue: Date;
  disableEmailNotifications: boolean; // default to false
  isMatrixPricing: boolean; // default to false
  specialRequirements: string;
  startingTerms: number[];
  startDate: string;
  // after creation need special methods to handle these
  productModel: IRateCheckRequestModel;
  totalFee: number; // requires meters to be set first to create the pricing scenarios
  meterChanges: RateCheckMeterModificationDto;
  // after creation
  id: string;
  validations: RateCheckValidationResponse;
  dateCalculations: {
    maxStartDate: Date
    minStartDate: Date;
    monthGapBetweenDates: number;
    recommendedStartDate: string;
    state: string;
  }
  rcVersion: number; // Default 2, for the new RCF it will be 4. Used to VIEW_AND_MODIFY_REQUEST
  csa: boolean;
  incumbentSupplierScenarioIds?: string[]
}

export interface StaggeredStartProjection {
  meterCount: number;
  startDate: string;
  statuses: string[];
  totalUsage: number;
  unit: string;
  utilityNames: string[];
}

export interface RateCheckValidationResponse {
  csaAllowed: boolean;
  minTerm: number;
  valid: boolean;
  validations: RateCheckValidation[];
}

export interface RateCheckValidation {
  message: string;
  details: string;
  showDetails: boolean;
  validationType: ValidationType;
  commodityScenario: CommodityScenario;
}

export enum ValidationType {
  ERROR = 'ERROR',
  WARNING = 'WARNING'
}

export enum CommodityScenario {
  ElectricMatrix = 'ElectricMatrix',
  ElectricCustom = 'ElectricCustom',
  GasCustom = 'GasCustom',
}
export interface RateCheckMeterModificationDto {
  ids: (string | number)[];
  objectType: RcRequestObjectType; // Default METER
  modificationType: RateCheckMeterModificationType;
}


export enum RcRequestObjectType {
  LEN = 'LEN', METER = 'METER', UTILITY = 'UTILITY', ENERGY_PLAN = 'ENERGY_PLAN',
  PROPERTY  = 'PROPERTY', BULK_IMPORT = 'BULK_IMPORT', OPPORTUNITY = 'OPPORTUNITY'
}

export enum RateCheckMeterModificationType {
  ADD = 'ADD', REMOVE = 'REMOVE'
}

export interface IRateCheckRequestModel {
  // Base fields
  paymentTerms: ZenNatGasPaymentTermEnum;
  billingMethod: ContractBillingMethod;
  requirements: string;
  renewable: ZenRcReqRenewableOptionsEnum;

  // Gas fields
  swing: number;
  contractTerms: number[];
  totalFee: number;
  basisFixed: boolean;
  basisPricingLocation: BasisPricingLocation;
  commodityFixed: boolean;

  // Electric fields
  capacityPassthrough: boolean;
  transmissionPassthrough: boolean;
  energyPassthrough: boolean;
}
