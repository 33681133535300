import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {EnergyPlanTimeline, EnergyPlanTimelineModified, TimelineStatus} from '../../../_modules/portfolio/_model/portfolio-meters.model';
import * as moment from 'moment';
import {PortfolioMetersHelperService} from '../../../_modules/portfolio/_services/_helpers/portfolio-meters-helper.service';
import {ZenBaseComponent} from '../zen-base/zen-base.component';
import {ZenContractListHelperService} from '../../../_modules/zen-contracts/_services/zen-contract-list-helper.service';
import {ZenContractTblRowModel, ZenContractV4ListModel} from '../../_model/contract-v4.model';
import {PortfolioHelperService} from '../../../_modules/portfolio/_services/_helpers/portfolio-helper.service';
const MAX_MONTH = 36;

@Component({
  selector: 'app-zen-procurement-snapshot',
  templateUrl: './zen-procurement-snapshot.component.html',
  styleUrls: ['./zen-procurement-snapshot.component.scss']
})
export class ZenProcurementSnapshotComponent extends ZenBaseComponent implements OnInit, OnChanges {
  @Input() timelines: EnergyPlanTimeline[] = [];
  @Input() mode: 'datatable' | 'details-page' | 'static'; // datatable -> Datatable || details-page -> meter details
  @Input() enableTooltip: boolean;

  @Output() onHover: EventEmitter<EnergyPlanTimeline> = new EventEmitter<EnergyPlanTimeline>();

  firstContractLabel: string;

  modifiedTimelineData: EnergyPlanTimelineModified[] = [];
  selectedIndex = 0;
  tooltipDates = {startDate: null, endDate: null};
  clicked: boolean;


  showLinkLabels = false;
  isDraft = false;
  isUndefined = false;

  constructor(public pfMeterHelpSvc: PortfolioMetersHelperService,
              private pfContractHelpSvc: ZenContractListHelperService,
              private pfHelpSvc: PortfolioHelperService) {
    super();
  }

  ngOnInit() {
    this.handleTimelineModification();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.handleTimelineModification();
  }

  handleTimelineModification() {
    this.modifiedTimelineData = [];
    if (this.timelines && this.timelines.length) {
      this.showLinkLabels = this.mode !== 'details-page';

      // If we are on the eligible meters pop-up, see if we have a show link label override so we can hide on that specific pop-up
      const hideLinkLabeOverride  = this.timelines.filter(x => x.showLinkLabel != null && x.showLinkLabel === false).length > 0;
      if (hideLinkLabeOverride) {
        this.showLinkLabels = false;
      }

      this.timelines.forEach((t) => {
        this.modifiedTimelineData.push({statusCls: this.pfMeterHelpSvc.convertStatusToCls(t.timelineStatus), percent: t.percent + '% '});
      });
      this.handleSmallLabel();
    }
  }

  handleSmallLabel() {
    const _timeline = this.timelines[0];
    const _length = this.timelines.length;
    // if there is > 1 record MR -> first record percent / MAX_MONTH
    let _monthsRemaining = _timeline.monthsRemaining !== 0 ? _timeline.monthsRemaining : Math.round(_timeline.percent / MAX_MONTH);

    switch (_timeline.timelineStatus) {
      case TimelineStatus.DRAFT:
        this.isDraft = true; // Used to display link
        this.firstContractLabel = _timeline.timelineStatus + `${_timeline.excludedFromOpportunities ? ' (Strategic)' : ''}`;
        break;
      case TimelineStatus.UNDEFINED:
        this.isUndefined = true; // Used to display link for undefined
        this.firstContractLabel = _timeline.timelineStatus + `${_timeline.excludedFromOpportunities ? ' (Strategic)' : ''}`;
        break;
      case TimelineStatus.ON_UTILITY:
      case TimelineStatus.PROCESSING:
        this.firstContractLabel = _timeline.timelineStatus + `${_timeline.excludedFromOpportunities ? ' (Strategic)' : ''}`;
        break;
      case TimelineStatus.EXPIRED:
        this.firstContractLabel = `Expired ${_timeline.endDate ? moment(_timeline.endDate).format('MMM YYYY') : ''}`;
        break;
      case TimelineStatus.EXPIRING:
      case TimelineStatus.EXPIRING_SOON:
        this.firstContractLabel = `Expiring ${_timeline.endDate ? moment(_timeline.endDate).format('MMM YYYY') : ''}`;
        break;
      default:
        this.firstContractLabel = this.defaultLabel(_monthsRemaining);
        break;
    }
  }

  defaultLabel(mr: number) {
    let monthTxt = mr === 1 ? ' month' : ' months';
    return `${mr > MAX_MONTH ? (MAX_MONTH + monthTxt + ' +') : mr + monthTxt}`
  }

  handleMouseOver(index: number) {
    const t = this.timelines[index];
    this.onHover.emit(t)
    this.selectedIndex = index;
    this.tooltipDates.startDate = moment(t.startDate).format('MMM YYYY');
    this.tooltipDates.endDate = t.endDate ? moment(t.endDate).format('MMM YYYY') : moment(t.startDate).add(36, 'months').format('MMM YYYY');
  }

  handleFinalizeDraftContract() {
    this.pfContractHelpSvc.handleViewContract({energyPlanId: this.timelines[0].energyPlanId, customerId: this.timelines[0].customerId} as ZenContractTblRowModel, true);
  }

  handleAddContract() {
    const t = this.timelines[0];
    this.pfContractHelpSvc.handleAddEditContracts(
      null,
      t.customerId,
      t.commodityType,
      t.state,
      (createdContract: ZenContractV4ListModel) =>
        createdContract ? this.pfHelpSvc.refreshCurrentPortfolioPage(t.customerId, t.lenId, t.propertyId) : null,
      t.propertyId,
      t.meterId,
    );
  }


}
