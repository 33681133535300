<app-zen-dialog-wrapper [data]="data" (onSubmit)="submit($event)" [errorMsg]="errorMsg"
                        [hideButton]="pfProcurementHelpSvc.contractFormType"
                        [disableButton]="pfProcurementHelpSvc.loading" [errorMsgClass]="'error-margin'">
  <div class="d-flex flex-wrap">
    <div class="col-12 px-4">
      <div class="dialog-form-sec with-border">

        <div class="mb-5" *ngIf="!pfProcurementHelpSvc.contractFormType">
          <!-- Only show this checkbox show if 1st timeline status -> If undefined or expired-->
          <div class="on-utility-action-wrapper" *ngIf="showOnUtilityCheckbox">
            <div class="d-flex align-items-center me-5">
              <mat-checkbox [formControl]="pfProcurementHelpSvc.assignToUtility" class="ms-0"
                            [disabled]="pfProcurementHelpSvc.disableAssignToUtility"
                            (change)="handleOnUtilityChange()">
                <p class="text-md font-weight-600 me-3">On Utility</p>
              </mat-checkbox>
            </div>

            <ng-container *ngIf="pfProcurementHelpSvc.assignToUtility.value && !pfProcurementHelpSvc.disableAssignToUtility">
              <div class="d-flex align-items-center me-4">
                <p class="text-md font-weight-600 me-3 {{pfProcurementHelpSvc.excludeFromOpportunities.value ? 'text-color-dark' : 'text-color-light'}}">Strategic</p>
                <mat-slide-toggle [formControl]="pfProcurementHelpSvc.excludeFromOpportunities"></mat-slide-toggle>
              </div>

              <div class="" *ngIf="pfProcurementHelpSvc.excludeFromOpportunities.value">
                <app-zen-datepicker [formCtrl]="pfProcurementHelpSvc.excludeUntilDate"></app-zen-datepicker>
              </div>
            </ng-container>

          </div>

          <app-zen-procurement-snapshot mode="details-page" [enableTooltip]="true"
                                        (onHover)="hoveredTimeline=$event"
                                        [timelines]="localTimelineData"></app-zen-procurement-snapshot>

          <div class="d-flex align-items-center justify-content-center">
            <div class="col-6 text-start p-0">{{snapShotDates?.minDate}}</div>
            <div class="col-6 text-end p-0">{{snapShotDates?.maxDate}}</div>
          </div>
        </div>

        <div class="">
          <div id="zen-add-procurement-snapshot" class="pf-tabs-main-wrapper">
            <div class="row" *ngIf="!pfProcurementHelpSvc.contractFormType">
              <div class="col-12 col-sm-12 col-md-12 col-lg-5 mt-3">
                <div class="position-relative" *ngIf="pfProcurementHelpSvc.linkedContractTableTopConfig">
                  <app-zen-tab-top-action [config]="pfProcurementHelpSvc.linkedContractTableTopConfig"></app-zen-tab-top-action>
                </div>

                <mat-tab-group disableRipple  class="v1">
                  <mat-tab>
                    <ng-template mat-tab-label>{{'Linked Contracts (' + (pfProcurementHelpSvc.linkedContracts?.length || 0) + ')'}}</ng-template>
                    <div class="wrapper">
                      <div class="no-data-section" *ngIf="!pfProcurementHelpSvc.linkedContracts || pfProcurementHelpSvc.linkedContracts?.length === 0">
                        <p class="text-md font-weight-600 mb-2">Contract Management</p>
                        <p class="text-md">You have no linked contracts. Choose a contract from the eligible contracts table.</p>
                      </div>
                      <ng-container *ngFor="let lc of pfProcurementHelpSvc.linkedContracts">
                        <app-zen-contract-info-card [contract]="lc" [selectedEpId]="hoveredTimeline?.energyPlanId"
                                                    (onClick)="handleInfoCardClick($event)"></app-zen-contract-info-card>
                      </ng-container>
                    </div>
                  </mat-tab>
                </mat-tab-group>

              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-1 pt-3 pb-5">
                <i class="material-symbols-rounded sync-icon">sync_alt</i>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                <div class="position-relative"
                     *ngIf="!pfProcurementHelpSvc.contractFormType && pfProcurementHelpSvc.tableTopConfig && pfProcurementHelpSvc.eligibleContracts?.length">
                  <app-zen-tab-top-action [config]="pfProcurementHelpSvc.tableTopConfig"></app-zen-tab-top-action>
                </div>
                <mat-tab-group disableRipple  class="v1">
                  <mat-tab>
                    <ng-template
                      mat-tab-label>{{'Eligible Contracts (' + (pfProcurementHelpSvc.eligibleContracts?.length || 0) + ')'}}</ng-template>
                    <div *ngIf="statusBeforeOnUtilityCheck === 'Draft'" class="wrapper">
                      <div class="no-data-section" >
                        <p class="text-md font-weight-600 mb-2">Contract Management</p>
                        <p class="text-md">While your meter is assigned to a draft contract with an unknown end date, no other contracts can be assigned. Please complete the contract details.</p>
                      </div>
                    </div>

                    <app-zen-mat-table  *ngIf="statusBeforeOnUtilityCheck !== 'Draft'"[tableConfig]="pfProcurementHelpSvc.psTableConfig"
                                       [data]="pfProcurementHelpSvc.eligibleContracts"
                                       [loading]="pfProcurementHelpSvc.loading" multiselectType="swap"
                                       (onSwapBtnClick)="handleEligibleContractClick($event)"
                    ></app-zen-mat-table>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>

            <!-- Contract add form -->
            <mat-tab-group disableRipple  class="v1" *ngIf="pfProcurementHelpSvc.contractFormType === FormTypes.ADD">
              <!-- Add a new contract -->
              <mat-tab>
                <ng-template mat-tab-label>Create a new contract</ng-template>
                <div class="row w-100 m-0">
                  <app-zen-contract-form [data]="pfProcurementHelpSvc.contractFormData" [errorMsg]="errorMsg"
                                         [fieldsToHide]="['customerName', 'state', 'service']"
                                         [isProcurementSnapshot]="true"
                                         (onCancel)="pfProcurementHelpSvc.closeContractForm()"
                                         (afterSubmit)="pfProcurementHelpSvc.handleContractFormAfterSubmit($event)"
                                         (errorResponse)="bubbleUpError($event)"
                  >
                  </app-zen-contract-form>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-zen-dialog-wrapper>
