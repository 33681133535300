import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {EnergyPlanV2, PropertyEnergyPlansV2} from '../_models/energyplan-v2';
export interface HasUnsavedData {
  energyPlans: EnergyPlanV2[],
  loading: boolean
}

export class HasUnsavedDataGuard implements CanDeactivate<HasUnsavedData> {
  canDeactivate(component: HasUnsavedData,
                route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): boolean {
    let pendingPropertyList = [];
    if (component.energyPlans && component.energyPlans.length > 0) {
      component.energyPlans.map((energyPlan: EnergyPlanV2) => {
        if (energyPlan.propertyEnergyPlans && energyPlan.propertyEnergyPlans.length > 0) {
          energyPlan.propertyEnergyPlans.map((propEngPlan: PropertyEnergyPlansV2) => {
            if (propEngPlan.property && propEngPlan.property.status === 'Pending') {
              pendingPropertyList.push({energyPlanId: energyPlan.energyPlanId, ...propEngPlan.property});
            }
          });
        }
      });
    }
    if (pendingPropertyList && pendingPropertyList.length > 0) {
      // tslint:disable-next-line:max-line-length
      let unsaved = confirm(`Are you sure are you want to navigate away?\n\nYou have added bill data to 1 or more Energy Plans which require a refresh to update all data metrics for this customer.`);
      component.loading = false;
      return unsaved;
    }
    return true;
  }
}
