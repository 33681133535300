import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {environment} from '../../../../../../environments';
import {HttpClient} from '@angular/common/http';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {ActivationCheck, DeleteCheck} from '../../_model/customer-v4.model';
import {ActivationChangeBulkModel} from '../../_model/activation-change-bulk.model';
import {PropertyAddUpdateDTO, PropertyDetailsV4Model} from '../../_model/property-v4.model';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {CommodityValidationV4Model} from '../../_model/commodity-validation-v4.model';

@Injectable({
  providedIn: 'root'
})
export class PropertyV4Service {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient, private orgMgtSvc: OrganizationManagementService) { }

  getPropertyDetails(customerId: number, propertyId: number): Observable<PropertyDetailsV4Model> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/properties/${propertyId}/details`;
    return this.http.get<PropertyDetailsV4Model>(url).pipe(take(1));
  }

  /**
   * To fetch available commodities of the procurable utility meters with energy profile by property ids.
   * If !commodities.length - then there is no eligible meters available for the selected propertyIds.
   * @param customerId
   * @param ids
   */
  getAvailableCommodityTypesByIds(customerId: number, propertyIds: number[]): Observable<CommodityValidationV4Model> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/property-procurable-commodities?propertyIds=${propertyIds}`;
    return this.http.get<CommodityValidationV4Model>(url).pipe(take(1));
  }

  getPropertyById(customerId: number, propertyId: number): Observable<PropertyDetailsV4Model> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/properties/${propertyId}`;
    return this.http.get<PropertyDetailsV4Model>(url).pipe(take(1));
  }

  createProperty(customerId: number, data): Observable<PropertyDetailsV4Model> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/properties`;
    return this.http.post<PropertyDetailsV4Model>(url, data).pipe(take(1));
  }

  updateProperty(customerId: number, propertyId: number, data: PropertyAddUpdateDTO): Observable<PropertyDetailsV4Model> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/properties/${propertyId}`;
    return this.http.put<PropertyDetailsV4Model>(url, data).pipe(take(1));
  }

  deleteProperty(customerId: number, propertyId: number): Observable<PropertyDetailsV4Model> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/properties/${propertyId}`;
    return this.http.delete<PropertyDetailsV4Model>(url).pipe(take(1));
  }

  bulkDeleteCheckProperty(customerId: number, ids: number[]): Observable<DeleteCheck> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/properties/bulk/delete-check`;
    return this.http.post<DeleteCheck>(url, { ids }).pipe(take(1));
  }

  bulkDeleteProperties(customerId: number, ids: number[]): Observable<PropertyDetailsV4Model> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customers/${customerId}/properties/bulk/delete`;
    return this.http.post<PropertyDetailsV4Model>(url, {ids}).pipe(take(1));
  }

  /**
   * Bulk Activation Check Properties - Check to see if provided Properties can be Reactivated
   * Payload -> {customerId: number, ids: string[]} -> where applicable (Properties)
   * @param customerId
   * @param ids
   */
  bulkActivationCheckProperties(customerId: number, ids: number[]): Observable<ActivationCheck> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/customers/${customerId}/properties/bulk/activation-check`;
    return this.http.post<ActivationCheck>(url, { ids }).pipe(take(1));
  }

  /**
   * Bulk change status properties - Deactivate or reactivate properties using the table or row action toggles
   * Payload -> {customerId: number, ids: number[]} -> where applicable (properties)
   * @param customerId
   * @param propertyIds
   * @param active
   */
  bulkChangeStatusProperties(customerId: number, activationBulkChange: ActivationChangeBulkModel) {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/customers/${customerId}/properties/bulk/activation`;
    return this.http.put(url, activationBulkChange).pipe(take(1));
  }
}
