import {FeeError} from '../../_modules/zen-market-pulse/_services/matrix-pricing-helper.service';
import {RateCheckFeeDetails} from '../_model/rate-check-v4.model';

export function feeErrorCheck(feeDetails: RateCheckFeeDetails): FeeError {
  let feeError: FeeError = {hasError: false};
  if (+feeDetails?.totalFee > feeDetails?.maxFeeDollarsLimit) {
    feeError.valueHigh = true;
    feeError.hasError = true;
  } else if (+feeDetails?.totalFee < 0) {
    feeError.belowZero = true;
    feeError.hasError = true;
  } else if (+feeDetails?.totalFee < feeDetails?.minFeeDollarsLimit) {
    feeError.valueLow = true;
    feeError.hasError = true;
  }
  return feeError;
}
