import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TagService } from '../../_services/tag.service';
import { LabelledTagOption, Tag, TagType } from '../../../_modules/portfolio/_model/tag.model';
import {ReplaySubject} from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { AuthenticationService } from '../../_zen-legacy-common/zen-common-services/_services/authentication.service';
import { equalsIgnoreCase } from '../../_zen-legacy-common/_utils/compare-utils';
import {CustomerIdSessionService} from '../../_services/customer-id/customer-id-session.service';

@Component({
  selector: 'app-zen-tags',
  templateUrl: './zen-tags.component.html',
  styleUrls: ['./zen-tags.component.scss']
})
export class ZenTagsComponent implements OnInit, OnChanges {

  @Input() tagOptions: LabelledTagOption[] = [];
  @Input() tagFilterCtrl: UntypedFormControl;
  @Input() selectedTags: Tag[] = [];

  @Input() type: TagType;
  TagType = TagType;
  customerId: number;
  @Input() lenId?: string;
  @Input() propertyId?: number;
  @Input() meterId?: number;

  @Output() onAddTag: EventEmitter<Partial<Tag>> = new EventEmitter();
  @Output() onRemoveTag: EventEmitter<Partial<Tag>> = new EventEmitter();

  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) autocomplete: MatAutocompleteTrigger;
  @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;

  organizationId: string;

  // state based on the TYPE of entity this component is tagging
  friendlyType: string;

  filteredTagOptions: ReplaySubject<LabelledTagOption[]> = new ReplaySubject();

  popperTitle: string;

  constructor(private tagService: TagService, private authService: AuthenticationService,
              private customerIdSvc: CustomerIdSessionService) {
    // customerId store updated from CustomerIdSessionStore using RouterQuery
    this.customerId = customerIdSvc.getCustomerId();
  }

  ngOnInit(): void {
    this.organizationId = this.authService.getOrganizationId();
    this.friendlyType = this.tagService.getFriendlyName(this.type);

    // listen to changes in the input, perform initial filter, and then re-filter on any changes
    this.tagFilterCtrl.valueChanges.subscribe(query => this.filterOptions(query));

    // Handling popper content title.
    switch (this.type) {
      case TagType.CUSTOMER:
        this.popperTitle = (this.authService.isAdvisor() ? 'Customer' : 'Company') + ' Tags';
        break;
      case TagType.LEN:
        this.popperTitle = 'Legal Entity Name Tags';
        break;
      case TagType.PROPERTY:
        this.popperTitle = 'Property Tags';
        break;
      case TagType.METER:
        this.popperTitle = 'Meter Tags';
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // Init/Reset tag dropdown options
    this.filteredTagOptions.next(this.tagOptions);
    if (this.inputElement && this.inputElement.nativeElement) {
      this.inputElement.nativeElement.blur();
      this.autocomplete?.closePanel();
    }
  }

  getOptionText(option) {
    return option.label;
  }

  private filterOptions(query: string): any[] {
    let filteredOptions = [...this.tagOptions];

    if (query === null || query === undefined) {
      return filteredOptions;
    }

    filteredOptions = filteredOptions.filter((opt) => this.optionMatchesQueryFilter(opt, query));

    filteredOptions = filteredOptions.filter((opt) => this.optionNotYetAppliedFilter(opt));

    // mat-autocomplete will send in an object on select, but we quickly reset that back to a string
    if (query && typeof query === 'string') {
      const trimmedQuery = query.trim();
      const tokens = trimmedQuery.split(':');
      if (tokens.length > 1) {
        const [tagGroupName, tagName] = tokens;
        const hasMatchingOption = filteredOptions.some(opt => equalsIgnoreCase(opt.tagName, tagName) && equalsIgnoreCase(opt.tagGroupName, tagGroupName));
        const hasMatchingTag = this.selectedTags.some(tag => equalsIgnoreCase(tag.name, tagName) && equalsIgnoreCase(tag.tagGroup.name, tagGroupName));
        if (!hasMatchingOption && !hasMatchingTag) {
          // we are creating a tag within a group
          filteredOptions.unshift({ tagGroupName, tagName, label: 'Create tag "' + (tagName ? tagName.trim() : '') + '" in group "' + (tagGroupName ? tagGroupName.trim() : '') + '"', isNew: true, disabled: !tagName.length });
        }
      } else {
        const hasMatchingOption = filteredOptions.some(opt => equalsIgnoreCase(opt.tagName, trimmedQuery));
        const hasMatchingTag = this.selectedTags.some(tag => equalsIgnoreCase(tag.name, trimmedQuery));
        if (!hasMatchingOption && !hasMatchingTag) {
          // we are creating a tag
          filteredOptions.unshift({ tagName: trimmedQuery, label: 'Create tag "' + trimmedQuery + '"', isNew: true, disabled: !trimmedQuery.length });
        }
      }
    }
    this.filteredTagOptions.next(filteredOptions);
  }

  // filter for only options that match what the user is searching for
  optionMatchesQueryFilter(option, query: string): boolean {
    const filterValue = typeof query === 'string' && query ? query.trim().toLowerCase() : '';
    return option.label.toLowerCase().includes(filterValue);
  }

  // filter for only tag options that are not already applied to this entity
  optionNotYetAppliedFilter(option): boolean {
    return !this.selectedTags.some(tag => {
      return tag.name === option.tagName;
    });
  }

  handleOptionSelected(tagOption, inputElement: HTMLInputElement) {
    const { tagName, tagGroupName, tagGroupId } = tagOption;
    if (!tagName && tagGroupName) {
      // only group selected, do nothing (they will need to enter more info to create a tag under this group)
    } else {
      const tag: Partial<Tag> = {
        customerId: this.customerId,
        lenId: this.lenId,
        meterId: this.meterId,
        propertyId: this.propertyId,
        name: tagName.trim(),
        objectType: this.type,
      }
      if (tagGroupName) {
        tag.tagGroup = {
          name: tagGroupName.trim(),
          objectType: this.type,
          organizationId: this.organizationId,
          customerId: this.customerId,
        }
      }
      this.onAddTag.emit(tag);
      inputElement.blur(); // To remove focus
    }
  }

  handleDelete(tag) {
    this.onRemoveTag.emit(tag);
  }
}
