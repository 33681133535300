/* tslint:disable:max-line-length */
import {
  DataProgress,
  FilterClass,
  FilterClassWithId,
  PriceEntity, SupplierProgress,
  SupplierRateClassCoverageMapResponse,
  UtilityRateScheduleResponse,
  Zone,
  ZoneDetailsMapList
} from '../../../_shared/_zen-legacy-common/zen-common-services/tili-services/models/matrix-pricing';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../../../environments';
import {take} from 'rxjs/operators';
import {LoadFactorLabelsMatrixRC} from '../../zen-reports/_modules/zen-rate-check-report/_models/matrix-rate-check.interface';

const HOURS_IN_A_YEAR = 8760;



@Injectable({
  providedIn: 'root'
})
export class MatrixPricingDataService {
  private baseUrl = environment.matrixApiBaseUrl;
  private coreApiBaseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient) { }

  getParams(paramsObject): HttpParams {
    let httpParams = new HttpParams();
    Object.keys(paramsObject).forEach((key) => {
      if (!(paramsObject[key] === undefined || paramsObject[key] === null)) {
        httpParams = httpParams.append(key, paramsObject[key]);
      }
    });
    return httpParams;
  }

  getUtilityList(effectivePriceString, selectedState): Observable<FilterClassWithId[]>  {
    // tslint:disable-next-line: max-line-length
    return this.http.get<FilterClassWithId[]>(this.baseUrl + '/v1/utilities?effectivePriceDate=' + effectivePriceString + '&state=' + selectedState).pipe(take(1));
  }

  getRateScheduleList(effectivePriceDate, state, utilityId, matrixOnly= false): Observable<FilterClass[]>  {
    let httpParams = this.getParams({
      effectivePriceDate,
      state,
      utilityId,
      matrixOnly
    });
    return this.http.get<FilterClass[]>(this.baseUrl + '/v1/rate_schedules', { params: httpParams }).pipe(take(1));
  }

  getRateScheduleListWithDescriptions(utilityId): Observable<UtilityRateScheduleResponse> {
    return this.http.get<UtilityRateScheduleResponse>(environment.apiBaseUrl + '/v1/utility_rate_schedules?utility_id=' + utilityId)
      .pipe(take(1));
  }

  getZipCodeZoneList(state?: string, zipCode?: string): Observable<Zone[]> {
    return this.http.get<Zone[]>(environment.apiBaseUrl + '/v1/zip-code-zones/zones', { params: this.getParams({ state, zipCode }) }).pipe(take(1));
  }

  getZipCodeZoneListByMeterId(meterId: number): Observable<Zone[]> {
    return this.http.get<Zone[]>(environment.apiBaseUrl + '/v1/zip-code-zones/meter-zones', { params: this.getParams({ meterId }) }).pipe(take(1));
  }

  getZoneList(effectivePriceString, selectedState, selectedUtility): Observable<FilterClass[]>  {
    // tslint:disable-next-line: max-line-length
    return this.http.get<FilterClass[]>(this.baseUrl + '/v1/zones?effectivePriceDate=' + effectivePriceString + '&state=' + selectedState + '&utilityId=' + selectedUtility).pipe(take(1));
  }

  getLoadFactorList(effectivePriceString, selectedState, selectedUtility, selectedZone): Observable<FilterClass[]> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<FilterClass[]>(this.baseUrl + '/v1/load_factors?effectivePriceDate=' + effectivePriceString + '&state=' + selectedState + '&utilityId=' + selectedUtility + '&zone=' + selectedZone).pipe(take(1));
  }

  getDataProgress(effectivePriceString, numOfProperties, state, annualUsage, utilityId, startDate, rateScheduleId, zone, loadFactor, hiddenSupplierIds): Observable<DataProgress[]> {
    let httpParams = this.getParams({
      effectivePriceDate: effectivePriceString, numOfProperties, state, annualKwhUsage: annualUsage, utilityId, startDate, rateScheduleId,
      zone, loadFactor, hiddenSupplierIds
    });
    return this.http.get<DataProgress[]>(this.baseUrl + '/v2/data_progress', { params: httpParams }).pipe(take(1));
  }

  getSupplierProgress(effectivePriceString, state?: string, utilityId?: string): Observable<SupplierProgress> {
    let httpParams = this.getParams({
      effectivePriceDate: effectivePriceString,
      state,
      utilityId
    });
    return this.http.get<SupplierProgress>(this.baseUrl + '/v2/supplier_progress', { params: httpParams }).pipe(take(1));
  }

  getSupplierIdsByUtilityId(utilityId: number) {
    return this.http.get<string[]>(this.baseUrl + '/v1/utility_data/' + utilityId).pipe(take(1));
  }

  getSupplierCoverage(rateScheduleIds: number[]) {
    const rateSchedulesParam = [...new Set(rateScheduleIds)].join(',');
    return this.http.get<SupplierRateClassCoverageMapResponse>(this.coreApiBaseUrl + `/v1/supplier-rate-class-schedule-maps/coverage?rateScheduleIds=${rateSchedulesParam}`).pipe(take(1));
  }

  getPricingV3(params: object): Observable<PriceEntity[]> {
    let httpParams = this.getParams(params);

    // At some point we started writing this bad value into the parameters, it should never go to the backend.
    if (httpParams.has('loadFactor') && httpParams.get('loadFactor') === 'NONE') {
      httpParams = httpParams.delete('loadFactor');
    }
    return this.http.get<PriceEntity[]>((this.baseUrl + '/v3/pricing'), { params: httpParams }).pipe(take(1));
  }

  calculatedLoadFactor(selectedState, selectedPeakDemandKw, totalUsage): LoadFactorLabelsMatrixRC {
    if (selectedState === 'TX') {
      const hoursInAYear = 8760;
      const defaultLoadFactorPercentile = 40; // Anything less than 40 is LOW, so we are using this as a cutoff Value
      totalUsage = totalUsage * 1000;  //  We are passing in MHW,  this needs to be KWH for the calculation to work.
      // If peak demand is null, 0, or 1, lets use some fallback calculation to default to LOW
      let _selectedPeakDemandKw = selectedPeakDemandKw == null || selectedPeakDemandKw > 1 ? Math.round(parseInt(selectedPeakDemandKw, 10)) :
        Math.round(((totalUsage * 100) / (hoursInAYear * defaultLoadFactorPercentile)) + 1);

      _selectedPeakDemandKw = _selectedPeakDemandKw > 0 ? _selectedPeakDemandKw : 1; // To avoid Infinity
      const loadFactor = (totalUsage) / (_selectedPeakDemandKw * HOURS_IN_A_YEAR);
      /** LOW -> 0 to less than 0.4, MEDIUM -> 0.4 to 0.6, HIGH -> greater than 0.6 **/
      const loadFactorLabel = (loadFactor > 0 && loadFactor < 0.4) ? LoadFactorLabelsMatrixRC.LOW :
        (loadFactor >= 0.4 && loadFactor <= 0.6) ? LoadFactorLabelsMatrixRC.MEDIUM :
          (loadFactor > 0.6) ? LoadFactorLabelsMatrixRC.HIGH : LoadFactorLabelsMatrixRC.NONE;
      console.log(`loadFactor = ${totalUsage} / (${_selectedPeakDemandKw} * 8760) = `, loadFactor, `(${loadFactorLabel})`);
      return loadFactorLabel;
    } else { return LoadFactorLabelsMatrixRC.NONE; }
  }
}
