import {NgModule} from '@angular/core';
import {ZenMagicScrollComponent} from './_components/zen-magic-scroll/zen-magic-scroll.component';
import {ZenMatTableComponent} from './_components/zen-mat-table/zen-mat-table.component';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {CommonModule, TitleCasePipe} from '@angular/common';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatMenuModule} from '@angular/material/menu';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {SafeHtmlPipe} from './_pipes/safe-html.pipe';
import {ZenSnapshotComponent} from './_components/zen-snapshot/zen-snapshot.component';
import {ZenChartComponent} from './_components/zen-chart/zen-chart.component';
import {NgChartsModule, ThemeService} from 'ng2-charts';
import {ZenDialogComponent} from './_dialogs/zen-dialog/zen-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {ZenToastComponent} from './_components/zen-toast/zen-toast.component';
import {ZenSkeletonLoaderComponent} from './_components/zen-skeleton-loader/zen-skeleton-loader.component';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {ZenErrorComponent} from './_components/zen-error/zen-error.component';
import {NgxPopperjsModule} from 'ngx-popperjs';
import {ZenPopperComponent} from './_components/zen-popper/zen-popper.component';
import {ZenSendEmailDialogComponent} from './_dialogs/zen-send-email-dialog/zen-send-email-dialog.component';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ZenDialogMsgService} from './_services/zen-dialog-msg.service';
import {ZenMatTableActionsComponent} from './_components/zen-mat-table/zen-mat-table-actions/zen-mat-table-actions.component';
import {ZenUserProfileDialogComponent} from './_dialogs/zen-user-profile-dialog/zen-user-profile-dialog.component';
import {ZenCustomerProfileDialogComponent} from './_dialogs/zen-customer-profile-dialog/zen-customer-profile-dialog.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {NgxMaskModule} from 'ngx-mask';
import {ZenTagsComponent} from './_components/zen-tags/zen-tags.component';
import {TagService} from './_services/tag.service';
import {ZenDialogWrapperComponent} from './_dialogs/zen-dialog-wrapper/zen-dialog-wrapper.component';
import {ZenEditLenDialogComponent} from './_dialogs/zen-edit-len-dialog/zen-edit-len-dialog.component';
import {ZenUploadFileComponent} from './_components/zen-upload-file/zen-upload-file.component';
import {ZenEditMeterDialogComponent} from './_dialogs/zen-edit-meter-dialog/zen-edit-meter-dialog.component';
import {ZenProcurementSnapshotComponent} from './_components/zen-procurement-snapshot/zen-procurement-snapshot.component';
import {IMaskModule, IMaskPipe} from 'angular-imask';
import {ZenCardNoDataComponent} from './_components/zen-card-no-data/zen-card-no-data.component';
import {ZenEditPropertyDialogComponent} from './_dialogs/zen-edit-property-dialog/zen-edit-property-dialog.component';
import {ZenUsageProfileDialogComponent} from './_dialogs/zen-usage-profile-dialog/zen-usage-profile-dialog.component';
import {MatRadioModule} from '@angular/material/radio';
import {ZenBaseComponent} from './_components/zen-base/zen-base.component';
import {ZenBreadcrumbComponent} from './_components/zen-breadcrumps/zen-breadcrumb.component';
import {ZenMessageComponent} from './_components/zen-message/zen-message.component';
import {ZenNoDataSectionComponent} from './_components/zen-no-data-section/zen-no-data-section.component';
import {ZenTabTopActionComponent} from './_components/zen-tab-top-action/zen-tab-top-action.component';
import {
  ZenMatrixPricingStatusReportDialogComponent
} from './_dialogs/zen-matrix-pricing-status-report-dialog/zen-matrix-pricing-status-report-dialog.component';
import {
  ZenMarketPulseGenerateLeadDialogComponent
} from './_dialogs/zen-market-pulse-generate-lead-dialog/zen-market-pulse-generate-lead-dialog.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {ZenMatrixSetPctDialogComponent} from './_dialogs/zen-matrix-set-pct-dialog/zen-matrix-set-pct-dialog.component';
import {ZenFlyoutComponent} from './_components/zen-flyout/zen-flyout.component';
import {MatTreeModule} from '@angular/material/tree';
import {MatIconModule} from '@angular/material/icon';
import {ZenEditContractDialogComponent} from './_dialogs/zen-edit-contract-dialog/zen-edit-contract-dialog.component';
import {MatNativeDateModule} from '@angular/material/core';
import {
  ZenAddProcurementSnapshotDialogComponent
} from './_dialogs/zen-add-procurement-snapshot-dialog/zen-add-procurement-snapshot-dialog.component';
import {MatTabsModule} from '@angular/material/tabs';
import {ZenContractFormComponent} from './_components/zen-contract-form/zen-contract-form.component';
import {ZenFlyoutChipsComponent} from './_components/zen-flyout-chips/zen-flyout-chips.component';
import {ZenContractInfoCardComponent} from './_components/zen-contract-info-card/zen-contract-info-card.component';
import {ZenPasswordFieldComponent} from './_components/zen-password-field/zen-password-field.component';
import {
  ZenPasswordStrengthCheckerComponent
} from './_components/zen-password-field/zen-password-strength-checker/zen-password-strength-checker.component';
import {ZenPaymentFeedbackDialogComponent} from './_dialogs/zen-payment-feedback-dialog/zen-payment-feedback-dialog.component';
import {ZenLoaderComponent} from './_components/zen-loader/zen-loader.component';
import {ZenMatTableDialogComponent} from './_dialogs/zen-mat-table-dialog/zen-mat-table-dialog.component';
import {ZenTimelineComponent} from './_components/zen-timeline/zen-timeline.component';
import {HeaderComponent} from './_components/header/header.component';
import {ZenTableInfoProgressbarComponent} from './_components/zen-table-info-progressbar/zen-table-info-progressbar.component';
import {CommodityUnitPipe} from './_pipes/commodity-unit.pipe';
import {ZenMatDatepickerComponent} from './_components/zen-mat-datepicker/zen-mat-datepicker.component';
import {AbsolutePipe} from './_pipes/absolute.pipe';
import {ZenMetersTreeTableDialogComponent} from './_dialogs/zen-meters-tree-table-dialog/zen-meters-tree-table-dialog.component';
import {ZenReportProductsDialogComponent} from './_dialogs/zen-report-products-dialog/zen-report-products-dialog.component';
import {ZenRequestRefreshDialogComponent} from './_dialogs/zen-request-refresh-dialog/zen-request-refresh-dialog.component';
import {ZenImageCropperDialogComponent} from './_dialogs/zen-image-cropper-dialog/zen-image-cropper-dialog.component';
import {ZenGenericSignDocumentComponent} from './_components/zen-generic-sign-document/zen-generic-sign-document.component';
import {FilterChipsComponent} from './_components/filter-chips/filter-chips.component';
import {PortfolioFilterFlyoutComponent} from './_components/portfolio-filter-flyout/portfolio-filter-flyout.component';
import {ZenSelectSearchDropdownComponent} from './_components/zen-select-search-dropdown/zen-select-search-dropdown.component';
import {ZenContactFormFieldsComponent} from './_components/zen-contact-form-fields/zen-contact-form-fields.component';
import {SearchTextHighlightPipe} from './_pipes/search-text-highlight.pipe';
import {ZenUnitConversionInputComponent} from './_components/zen-unit-conversion-input/zen-unit-conversion-input.component';
import {ZenEditCustomerMemberDialogComponent} from './_dialogs/zen-edit-customer-member-dialog/zen-edit-customer-member-dialog.component';
import {
  ZenCustomerMemberDeleteDialogComponent
} from './_dialogs/zen-customer-member-delete-dialog/zen-customer-member-delete-dialog.component';
import {ZenToastSnackbarComponent} from './_components/zen-toast-snackbar/zen-toast-snackbar.component';
import {CommodityFeeFormatPipe} from './_pipes/commodity-fee-format.pipe';
import {ZenDateTimePickerComponent} from './_components/zen-date-time-picker/zen-date-time-picker.component';
import {ZenSideFlyoutComponent} from './_components/zen-side-flyout/zen-side-flyout.component';
import {ZenInputChipListSelectionComponent} from './_components/zen-input-chip-list-selection/zen-input-chip-list-selection.component';
import {ZenAutocompleteComponent} from './_components/zen-autocomplete/zen-autocomplete.component';
import {PhoneFormatPipe} from './_pipes/phone-format.pipe';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {ZenIconButtonComponent} from './_components/zen-icon-button/zen-icon-button.component';
import {ZenIconButtonToggleGroupComponent} from './_components/zen-icon-button-toggle-group/zen-icon-button-toggle-group.component';
import {ZenMatTableWithExpansionComponent} from './_components/zen-mat-table-with-expansion/zen-mat-table-with-expansion.component';
import {ZenMessageWithImgComponent} from './_components/zen-message-with-img/zen-message-with-img.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {ZenToastWithPopperComponent} from './_components/zen-toast-with-popper/zen-toast-with-popper.component';
import {ZenTexasContractOptionsComponent} from './_dialogs/zen-texas-contract-options/zen-texas-contract-options.component';
import {JenZenImgComponent} from './_components/jen-zen-img/jen-zen-img.component';
import {JenZenImgLargeComponent} from './_components/jen-zen-img/jen-zen-img-large/jen-zen-img-large.component';
import {JenZenImgSmallComponent} from './_components/jen-zen-img/jen-zen-img-small/jen-zen-img-small.component';
import {JenZenImgHappyComponent} from './_components/jen-zen-img/jen-zen-img-happy/jen-zen-img-happy.component';
import {JenZenImgHappiestComponent} from './_components/jen-zen-img/jen-zen-img-happiest/jen-zen-img-happiest.component';
import {JenZenImgOkComponent} from './_components/jen-zen-img/jen-zen-img-ok/jen-zen-img-ok.component';
import {JenZenImgSadComponent} from './_components/jen-zen-img/jen-zen-img-sad/jen-zen-img-sad.component';
import {
  RcReqMeterAnnualUsageUpdateDialogComponent
} from './_dialogs/rc-req-meter-annual-usage-update-dialog/rc-req-meter-annual-usage-update-dialog.component';
import {
  RcReqMeterRateClassUpdateDialogComponent
} from './_dialogs/rc-req-meter-rate-class-update-dialog/rc-req-meter-rate-class-update-dialog.component';
import {RcReqMeterZoneUpdateDialogComponent} from './_dialogs/rc-req-meter-zone-update-dialog/rc-req-meter-zone-update-dialog.component';
import {
  RcReqOnUtilityContractEditDialogComponent
} from './_dialogs/rc-req-on-utility-contract-edit-dialog/rc-req-on-utility-contract-edit-dialog.component';
import {ZenPortfolioDetailExpansionComponent} from './_components/zen-detail-expansion/zen-portfolio-detail-expansion.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {ZenLoaderWithFactsComponent} from './_components/zen-loader-with-facts/zen-loader-with-facts.component';
import {AddressAutocompleteService} from './_services/address-autocomplete.services';
import {NguCarouselModule} from '@ngu/carousel';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ZenActivationFormComponent} from './_components/zen-activation-form/zen-activation-form.component';
import {ZenActivationFormDialogComponent} from './_dialogs/zen-activation-form-dialog/zen-activation-form-dialog.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {
  ZenBulkAssignMetersEnergyPlanDialogComponent
} from './_dialogs/zen-bulk-assign-meters-energy-plan-dialog/zen-bulk-assign-meters-energy-plan-dialog.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatListModule} from '@angular/material/list';
import {ZenMatMenuItemComponent} from './_components/zen-mat-menu-item/zen-mat-menu-item.component';
import {ZenTotalFeeBreakdownComponent} from './_components/zen-total-fee-breakdown/zen-total-fee-breakdown.component';
import {MatSliderModule} from '@angular/material/slider';
import {MonthYearCustomDateFormat} from './_directives/month-year-custom-date-format.directive';
import {ZenDatepickerComponent} from './_components/zen-datepicker/zen-datepicker.component';
import {ZenTotalFeeErrorComponent} from './_components/zen-total-fee-error/zen-total-fee-error.component';
import {
  ZenTotalFeeBreakdownPopperIconComponent
} from './_components/zen-total-fee-breakdown-popper-icon/zen-total-fee-breakdown-popper-icon.component';
import {RcReqPortfolioConfirmDialogComponent} from './_dialogs/rc-req-portfolio-confirm-dialog/rc-req-portfolio-confirm-dialog.component';
import {ZenFileUploadComponent} from './_components/zen-file-upload/zen-file-upload.component';
import {TranslateModule} from '@ngx-translate/core';
import {ZenBaseWithTranslateComponent} from './_components/zen-base-with-translate/zen-base-with-translate.component';
import {ZenSuccessMsgDialogComponent} from './_dialogs/zen-success-msg-dialog/zen-success-msg-dialog.component';
import {ZenMetricSparklineWidgetComponent} from './_components/zen-metric-sparkline-widget/zen-metric-sparkline-widget.component';


@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatRadioModule,
    MatDatepickerModule,
    MatTreeModule,
    MatIconModule,
    MatNativeDateModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule,
    NgxMaskModule.forRoot(),
    NgxSkeletonLoaderModule,
    NgxPopperjsModule,
    NgxPaginationModule,
    IMaskModule,
    NguCarouselModule,
    DragDropModule,
    ImageCropperModule,
    MatTooltipModule,
    ImageCropperModule,
    MatListModule,
    MatSliderModule,
    TranslateModule
  ],
    declarations: [ZenMagicScrollComponent, ZenMatTableComponent, ZenMatTableComponent, ZenCardNoDataComponent,
        SafeHtmlPipe, ZenChartComponent, ZenSnapshotComponent, ZenDialogComponent, ZenToastComponent, ZenSkeletonLoaderComponent, ZenEditLenDialogComponent,
        ZenErrorComponent, ZenPopperComponent, ZenSendEmailDialogComponent, ZenMatTableActionsComponent, ZenUserProfileDialogComponent, ZenCustomerProfileDialogComponent, ZenTagsComponent,
        ZenDialogWrapperComponent, ZenUploadFileComponent, ZenProcurementSnapshotComponent, ZenBreadcrumbComponent,
        ZenEditPropertyDialogComponent, ZenBaseComponent, ZenMessageComponent, ZenEditMeterDialogComponent, ZenUsageProfileDialogComponent, ZenNoDataSectionComponent,
        ZenTabTopActionComponent,
        ZenMatrixPricingStatusReportDialogComponent,
        ZenMarketPulseGenerateLeadDialogComponent,
        ZenMatrixSetPctDialogComponent,
        ZenFlyoutComponent,
        ZenEditContractDialogComponent,
        ZenAddProcurementSnapshotDialogComponent,
        ZenContractFormComponent,
        ZenFlyoutChipsComponent,
        ZenContractInfoCardComponent,
        ZenPasswordFieldComponent,
        ZenPasswordStrengthCheckerComponent,
        ZenPaymentFeedbackDialogComponent,
        ZenLoaderComponent,
        ZenMatTableDialogComponent,
        ZenTimelineComponent,
        HeaderComponent,
        ZenTableInfoProgressbarComponent, CommodityUnitPipe, AbsolutePipe, SearchTextHighlightPipe, CommodityFeeFormatPipe, PhoneFormatPipe,
        ZenMatDatepickerComponent, ZenMetersTreeTableDialogComponent, ZenReportProductsDialogComponent, ZenRequestRefreshDialogComponent,
        ZenImageCropperDialogComponent, ZenGenericSignDocumentComponent,
        FilterChipsComponent, PortfolioFilterFlyoutComponent, ZenSelectSearchDropdownComponent, ZenContactFormFieldsComponent, ZenUnitConversionInputComponent,
        ZenEditCustomerMemberDialogComponent,
        ZenCustomerMemberDeleteDialogComponent,
        ZenToastSnackbarComponent,
        ZenInputChipListSelectionComponent,
        ZenAutocompleteComponent,
        ZenIconButtonComponent,
        ZenIconButtonToggleGroupComponent,
        ZenMatTableWithExpansionComponent, ZenMessageWithImgComponent,
        ZenSideFlyoutComponent,
        ZenToastWithPopperComponent,
        ZenDateTimePickerComponent,
        ZenTexasContractOptionsComponent,
        JenZenImgComponent,
        JenZenImgLargeComponent,
        JenZenImgSmallComponent,
        JenZenImgHappyComponent,
        JenZenImgHappiestComponent,
        JenZenImgOkComponent,
        JenZenImgSadComponent,
        RcReqMeterAnnualUsageUpdateDialogComponent,
        RcReqMeterRateClassUpdateDialogComponent,
        RcReqMeterZoneUpdateDialogComponent,
        RcReqOnUtilityContractEditDialogComponent,
        ZenPortfolioDetailExpansionComponent,
        ZenLoaderWithFactsComponent,
        ZenActivationFormComponent,
        ZenActivationFormDialogComponent,
        ZenBulkAssignMetersEnergyPlanDialogComponent,
        ZenMatMenuItemComponent, MonthYearCustomDateFormat,
        ZenTotalFeeBreakdownComponent,
        ZenTotalFeeBreakdownPopperIconComponent,
        ZenDatepickerComponent, ZenTotalFeeErrorComponent,
        RcReqPortfolioConfirmDialogComponent,
        ZenFileUploadComponent,
        ZenBaseWithTranslateComponent,
        ZenSuccessMsgDialogComponent,
        ZenMetricSparklineWidgetComponent
    ],
  exports: [ZenMagicScrollComponent, ZenMatTableComponent, SafeHtmlPipe, ZenDialogComponent, ZenSendEmailDialogComponent,
    ZenChartComponent, ZenSnapshotComponent, ZenSkeletonLoaderComponent, ZenPopperComponent, NgxPopperjsModule,
    ZenUploadFileComponent, ZenTagsComponent, ZenProcurementSnapshotComponent, ZenCardNoDataComponent, ZenBreadcrumbComponent, ZenNoDataSectionComponent,
    ZenTabTopActionComponent, ZenMessageComponent, ZenDialogWrapperComponent, ZenFlyoutComponent, ZenFlyoutChipsComponent, ZenPasswordFieldComponent, ZenLoaderComponent, ZenTimelineComponent, ZenContractFormComponent,
    HeaderComponent, ZenTableInfoProgressbarComponent, ZenMatDatepickerComponent,
    AbsolutePipe, ZenImageCropperDialogComponent, ZenGenericSignDocumentComponent,
    FilterChipsComponent, PortfolioFilterFlyoutComponent, ZenSelectSearchDropdownComponent, ZenContactFormFieldsComponent, SearchTextHighlightPipe, ZenUnitConversionInputComponent,
    ZenEditCustomerMemberDialogComponent, CommodityFeeFormatPipe, ZenInputChipListSelectionComponent, ZenAutocompleteComponent,
    PhoneFormatPipe, ZenMatTableActionsComponent,
    ZenIconButtonComponent,
    ZenIconButtonToggleGroupComponent,
    ZenMatTableWithExpansionComponent, ZenMessageWithImgComponent, ZenSideFlyoutComponent, ZenDateTimePickerComponent, JenZenImgComponent,
    RcReqMeterAnnualUsageUpdateDialogComponent,
    RcReqMeterRateClassUpdateDialogComponent,
    RcReqMeterZoneUpdateDialogComponent,
    RcReqOnUtilityContractEditDialogComponent,
    ZenPortfolioDetailExpansionComponent,
    ZenActivationFormComponent,
    ZenActivationFormDialogComponent, ZenMatMenuItemComponent,
    ZenTotalFeeBreakdownComponent, ZenTotalFeeErrorComponent,
    ZenTotalFeeBreakdownPopperIconComponent, ZenFileUploadComponent,
    ZenSuccessMsgDialogComponent,
    ZenMetricSparklineWidgetComponent
  ],
  providers: [ThemeService, ZenDialogMsgService, TagService, CommodityFeeFormatPipe, PhoneFormatPipe, IMaskPipe,
    AddressAutocompleteService, TitleCasePipe]
})
export class SharedModule {
}
