export enum MatTableSize {
  LARGE = '130rem',
  MEDIUM = '100rem',
  SMALL = '75rem'
}

/**
 * The below enums are w.r.t /V4/portfolio/table-filters API
 * This enum is used for default search fields in the zen-mat-table where the searchType='select-search'
 * */
export enum MatTableDefaultSearchField {
  CONTRACT_DETAIL_METER = 'uan1',
  RATECHECK = 'legalEntityName',
  CONTRACT = 'legalEntityName',
  CUSTOMER = 'customerName',
  LEN = 'legalEntityName',
  PROPERTY = 'propertyName',
  METER = 'meterName',
  ENERGY_PLAN_ELIGIBLE_METER = 'uan',
}
