import {Component} from '@angular/core';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {ZenBreadcrumbService} from '../../_services/zen-breadcrumb.service';

export interface ZenBreadcrumbConfig {
  pageName: string;
  command?: () => void;
  breadcrumbs?: ZenBreadcrumb[];
  mobileBreadcrumbs?: ZenBreadcrumb[];
}

export interface ZenBreadcrumb {
  icon: ZenIconsEnum;
  title: string;
  currentPage?: boolean; // current active page
  customerId?: number;
  lenId?: string;
  disableClick?: boolean;
  propertyId?: number;
  meterId?: number;
  command?: () => void;
}

@Component({
  selector: 'app-zen-breadcrumb',
  templateUrl: './zen-breadcrumb.component.html',
  styleUrls: ['./zen-breadcrumb.component.scss']
})
export class ZenBreadcrumbComponent {
  hovered: boolean;

  constructor(public zenBcSvc: ZenBreadcrumbService) {}

}
