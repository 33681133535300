import {Injectable} from '@angular/core';
import {combineLatest, Subject} from 'rxjs';
import {
  MatrixSupplier,
  MatrixSuppliersModel,
  SupplierService
} from '../../../_shared/_zen-legacy-common/zen-common-services/tili-services/services/supplier.service';
import {DataProgress, ProgressStatus} from '../../../_shared/_zen-legacy-common/zen-common-services/tili-services/models/matrix-pricing';
import {BizCalendarService} from '../../../_shared/_zen-legacy-common/zen-common-services/_services/v2/biz-calendar.service';
import {MatrixPricingDataService} from './matrix-pricing-data.service';
import {StatusColorsEnum} from '../../../_shared/_zen-legacy-common/_enums/status-colors.enum';
import {
  RateCheckRequestService
} from '../../../_shared/_zen-legacy-common/zen-common-services/tili-services/services/rate-check-request.service';
import * as moment from 'moment-timezone';
import {stateAbbrToName} from '../../../_shared/_zen-legacy-common/_utils/state-utils';
import {TimeSettings} from '../../../_shared/_zen-legacy-common/_utils/etc-timezone-utils';
import {MessageComponentInfo} from '../../../_shared/_zen-legacy-common/_models/message-component';

@Injectable({
  providedIn: 'root'
})
export class MatrixMessageService {
  pricingMessage: MessageComponentInfo;
  isSupplierMessage: boolean;
  disabledMatrix = false;
  loading: boolean;
  dataProgressList: DataProgress[] = [];
  utilityName: string
  /** To hide/show office hours related messages.
   * NOTE: There is no office hours messages for MarketPulse **/
  showOfficeHrsMessage: boolean;
  public onSupplierData: Subject<any> = new Subject<any>();
  /** In the external MarketPulse, we auto load the API calls with delay, so because of that issue we are fetching utility name from here. */
  public utilityNameFetched: Subject<string> = new Subject<string>();

  constructor(private bizCalendarService: BizCalendarService,
              private matrixPricingService: MatrixPricingDataService,
              private rateCheckRequestService: RateCheckRequestService,
              private supplierService: SupplierService,
               ) {
  }

  getPropertyStartDates(customerId: number, propertyIds: number[]) {
    return this.rateCheckRequestService.getPropertyStartDates(customerId, propertyIds);
  }

  getMatrixMessages(effectivePriceDateString, selectedPropertiesLength, selectedState, totalUsage, utilityId, utilityName, startDate, rateScheduleId, zone, loadFactor, hiddenSupplierIds) {
    this.loading = true;
    this.utilityName = utilityName;
    if (utilityName) { this.utilityNameFetched.next(utilityName); }
    const s = combineLatest(
      // tslint:disable-next-line:max-line-length
      this.matrixPricingService.getDataProgress(effectivePriceDateString, selectedPropertiesLength, selectedState, Math.floor(totalUsage), utilityId, startDate, rateScheduleId, zone, loadFactor, hiddenSupplierIds),
      this.supplierService.getMatrixSuppliers(),
      this.matrixPricingService.getSupplierIdsByUtilityId(utilityId),
      (dataProgressRes: any, matrixSuppliers: MatrixSuppliersModel, supplierIds) => {
        return {
          dataProgressRes: dataProgressRes,
          matrixSuppliers: matrixSuppliers.suppliers,
          supplierIds: supplierIds
        };
      }).subscribe(result => {
      this.loading = false;
      let utilitySupplierList: MatrixSupplier[] = [];
      result.matrixSuppliers.map(supplier => {
        if (!utilitySupplierList.find(sl => sl.id === supplier.id) &&
          result.supplierIds.includes(supplier.id)) {
          utilitySupplierList.push(supplier);
        }
      });

      // Filter dataProgress that includes suppliers by selected utility
      this.dataProgressList = result.dataProgressRes.response.filter((d) => {
        return result.supplierIds.includes(d.supplierID);
      });

      let withApprovedStatus = 0;
      let withApprovalPendingStatus = 0;
      let withNotApprovedStatus = 0;
      let withIneligibleStatus = 0;
      // Set dataProgress pricing statuses
      this.dataProgressList.forEach(dataProgress => {
        const supplierObj = utilitySupplierList.find(utilSupp => utilSupp.id === dataProgress.supplierID);
        dataProgress.supplierName = utilitySupplierList.length > 0 && supplierObj ? supplierObj.name : dataProgress.supplierName;
        dataProgress.progressStatus = ProgressStatus[dataProgress.progressStatus];
        switch (dataProgress.progressStatus) {
          case ProgressStatus.APPROVED:
            dataProgress.pricingStatusColor = StatusColorsEnum.success1;
            withApprovedStatus++;
            break;
          case ProgressStatus.APPROVAL_PENDING:
            dataProgress.pricingStatusColor = StatusColorsEnum.pending1;
            withApprovalPendingStatus++;
            break;
          case ProgressStatus.NOT_RECEIVED:
            dataProgress.pricingStatusColor = StatusColorsEnum.danger1;
            withNotApprovedStatus++;
            break;
          case ProgressStatus.INELIGIBLE:
            dataProgress.pricingStatusColor = StatusColorsEnum.warning1;
            withApprovedStatus++;  // We don't want ineligible to detract from the percentage of approved suppliers.
            withIneligibleStatus++;
            break;
        }
      });
      this.checkOfficeHours(result, withApprovedStatus, withNotApprovedStatus, withApprovalPendingStatus, withIneligibleStatus, utilityName);
      this.onSupplierData.next(result);
    }, () => {
      this.loading = false;
    });
  }

  private checkOfficeHours(result, withApprovedStatus, withNotApprovedStatus, withApprovalPendingStatus, withIneligibleStatus, utilityName) {
    this.bizCalendarService.getNowWithinOfficeHours().subscribe(response => {
      if (!response.withinOfficeHours && this.showOfficeHrsMessage) {
        // Disable matrix rate check report builder
        this.disabledMatrix = true;
        // Set message
        if (response.reason === 'Holiday') {
          this.pricingMessage = {
            icon: 'info_outline',
            type: 'pending',
            message: 'Today is a Bank Holiday. Matrix pricing cannot be executed when commodity markets are closed.'
          }
        } else {
          this.pricingMessage = {
            icon: 'info_outline',
            type: 'pending',
            // tslint:disable-next-line:max-line-length
            message: 'Matrix pricing is only available Monday through Friday between 9:00-5:00 pm EDT. Pricing must be executed BEFORE 5:00 pm EDT.'
          }
        }
      } else {
        if (withApprovedStatus === 0) {
          this.disabledMatrix = true;
          this.pricingMessage = {
            icon: 'info_outline',
            type: 'pending',
            message: 'Matrix pricing is currently unavailable for ' + utilityName +
              // tslint:disable-next-line:max-line-length
              '. Of the ' + result.supplierIds.length + ' suppliers that have previously submitted pricing for this utility, ' + withApprovalPendingStatus +
              ' have pricing that is pending approval and ' + withNotApprovedStatus + ' have not yet submitted. Check back soon!'
          }
        } else {
          this.disabledMatrix = false;
          let fraction = `${withApprovedStatus - withIneligibleStatus} of ${this.dataProgressList.length}`;
          this.pricingMessage = {
            icon: 'info_outline',
            type: 'pending',
            message: fraction +
              ' of available suppliers can provide matrix pricing for your search criteria. ' +
              '<a href="#" class="pointer" style="color: unset; font-weight: 500; text-decoration: underline;">' +
              'Review the Matrix Pricing Status Report for ' + utilityName + '</a>'
          }
        }
      }
    });
    this.isSupplierMessage = false;
  }

   percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
  }
  getEffectivePriceDate(): string {
    let today = moment().tz(TimeSettings.Zone);
    return today.tz(TimeSettings.Zone).format(TimeSettings.Format);
  }

  getSupplierProgressMessage(state?: string, utilityId?: string, utilityName?: string, isExternal?: boolean) {
    if (!isExternal) {
      this.isSupplierMessage = true;
      this.matrixPricingService.getSupplierProgress(this.getEffectivePriceDate(), state, utilityId).subscribe(supplierProgress => {
        let fraction = `${supplierProgress.currentSupplierCount} of ${supplierProgress.allSupplierCount}`;
        fraction = fraction === '0/0' ? 'No' : fraction;
        let suffix = state ? ` for ${stateAbbrToName(state)}.` : '.';
        suffix = utilityId ? ` for ${utilityName}.` : suffix;
        const type = supplierProgress.currentSupplierCount === 0 ? 'error'
          : this.percentage(supplierProgress.currentSupplierCount, supplierProgress.allSupplierCount) < 67 ? 'warning'
            : 'pending';

        const supplierProgressText = ' Suppliers have currently reported pricing today';
        // Only overwrite supplier progress message, but not the others that are more informed.
        // Solves a race condition that can happen
        if (this.pricingMessage?.message == null || this.pricingMessage.message.includes(supplierProgressText)) {
          this.pricingMessage = {
            icon: 'info_outline',
            type,
            message: fraction + ` ${supplierProgressText}${suffix}`,
          }
        }
      });
    }
  }

  clearData(forceClear = false) {
    if (this.isSupplierMessage !== true || forceClear) {
      this.pricingMessage = {message: null, type: null};
      this.dataProgressList = [];
    }
  }


}
