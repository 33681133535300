import {CommodityType} from '../../../_shared/_zen-legacy-common/_models/commodity';
import {ZenTableProcurement} from '../../../_shared/_components/zen-mat-table/zen-mat-table.component';
import {Tag} from './tag.model';
import {PropertyStatus} from '../../../_shared/_zen-legacy-common/_models/property';
import {ZenIconsEnum} from '../../../_shared/_enums/zen-icons.enum';
import {CustomerAndPropertyType, MeterType, PropertySubType} from '../../../_shared/_zen-legacy-common/_enums/entity-types.enum';
import {ActivationAuditChangeModel, ActivationChangeBulkModel} from '../../../_shared/_model/activation-change-bulk.model';

export interface PortfolioMetersModel {
  content: PfMetersContentModel[];
  page: number;
  size: number;
  sort: string[];
  totalElements: number;
  totalPages: number;
}

export interface PfMetersContentModel {
  active: boolean;
  address2: string;
  commodityType: CommodityType;
  createDt: string;
  deletable: boolean;
  id: number;
  imagePath: string;
  imageSmallPath: string;
  label: string;
  lenId: string;
  meterMetrics: MeterMetrics;
  projectedAnnualUsage: number;
  customerId: number;
  propertyId: number;
  propertyState: string;
  type: string;
  utilityAccountNum1: string;
  utilityAccountNum2: string;
  utilityAccountNum3: string;
  utilityId: number;
  utilityName: string;
}

export interface MeterMetrics {
  activeRateCheckCount: number;
  billCount: number;
  projectedUsage: number;
  energyPlanTimelines: EnergyPlanTimeline[];
}

export interface EnergyPlanTimeline {
  startDate: string;
  endDate: string;
  termMonths: number;
  monthsRemaining: number;
  percent: number;
  energyPlanId: number;
  timelineStatus: TimelineStatus;
  provider: string;
  providerType: string; // Utility or Supplier
  supplierRate: number; // if providerType = Utility -> null or if providerType = Supplier -> Contract Rate
  // On Utility meter - Opportunities field
  excludedFromOpportunities: boolean;
  excludedUntil: Date; // excludedFromOpportunities until
  // These are used to handle actions from the zen-procurement-snapshot component
  customerId: number;
  commodityType: CommodityType;
  meterId: number;
  lenId: string;
  propertyId: number;
  state: string;
  showLinkLabel: boolean;
}

export interface EnergyPlanTimelineModified {
  statusCls: string;
  percent: string;
}

export enum TimelineStatus {
  EXPIRED = 'Expired',
  CURRENT_CONTRACT = 'Contracted',
  FUTURE_CONTRACT = 'Future Contract',
  PROCESSING = 'Processing',
  ON_UTILITY = 'On Utility',
  UNDEFINED = 'Undefined',
  DRAFT = 'Draft',
  EXPIRING_SOON = 'Expiring < 3 Months',
  EXPIRING = 'Expiring > 3 Months'
}

export interface PfMetersTableRowModel extends PfMetersContentModel {
  billCount: number;
  meterStatusCls: 'active' | 'inactive';
  activeRateCheckCountStr: string;
  usage: string;
  usageType: ZenIconsEnum.ELECTRIC | ZenIconsEnum.NAT_GAS;
  usageTypeCls: 'electric' | 'nat-gas';
  procurementSnapshot?: ZenTableProcurement;
}

// Get Meter by Id
export interface MeterV4Model {
  activationAuditId: number;
  active: boolean;
  commodityType: CommodityType;
  createDt: string;
  elecPeakDemandKw: number;
  id: number;
  imagePath: string;
  imageSmallPath: string;
  label: string;
  propertyId: number;
  secondaryStatus: string;
  status: string;
  street2: string;
  type: MeterType;
  updateDt: string;
  utilityAccountNum1: string;
  utilityAccountNum2: string;
  utilityAccountNum3: string;
  utilityId: number;
  utilityRateScheduleId: null;
  zoneId: number;
}

export interface MeterDetailsV4Model {
  activationAuditId: number;
  active: boolean;
  commodityType: CommodityType;
  createDt: string;
  elecPeakDemandKw: number;
  id: number;
  imagePath: string;
  imageSmallPath: string;
  label: string;
  propertyId: number;
  secondaryStatus: string;
  status: string;
  street2: string;
  type: MeterType;
  updateDt: string;
  utilityAccountNum1: string;
  utilityAccountNum2: string;
  utilityAccountNum3: string;
  utilityId: number;
  utilityRateScheduleId: number;
  utilityRateSchedule: string
  zoneId: number;
  customerCompanyName: string;
  customerId: number;
  customerType: CustomerAndPropertyType;
  deletable: boolean;
  lenId: string;
  lenName: string;
  propertyCity: string;
  propertyLabel: string;
  propertyState: string;
  propertyStreet1: string;
  propertyStreet2: string;
  propertyZip: string;
  propertyType: CustomerAndPropertyType;
  propertySubType: PropertySubType;
  tags: Tag[];
  utilityName: string;
  zoneName: string;
  projectedAnnualUsage: number;

  // Only for the UI purpose
  propertyAddress?: string;
  contractStartDate?: Date; // this object find using /scenario-meters API, and mapped w.r.t meterId.
  contractEndDate?: Date; // this object find using /scenario-meters API, and mapped w.r.t meterId.
}

export interface MeterAddUpdateModel {
  id: number;
  propertyId: number;
  street2: string;
  utilityAccountNum1: string;
  utilityAccountNum2: string;
  utilityAccountNum3: string;
  utilityId: number;
  status: PropertyStatus;
  label: string;
  utilityRateScheduleId: number;
  zoneId: number;
  commodityType: CommodityType;
  secondaryStatus: string;
  type: MeterType;
  elecPeakDemandKw: number;
  tags: Tag[];
  imageTempId: string;
  active: boolean;
  activationChange?: ActivationAuditChangeModel;
}

export interface  MeterActiveCheck {
  activeMeterExist: boolean;
}


export interface ScenarioMetersV4Model {
  contractEndDate: Date;
  contractStartDate: Date;
  meterId: number;
  pricingScenarioId: string;
  pricingScenarioOptionId: string;
}
