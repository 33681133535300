import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {SearchRequest, SearchResponse} from '../../_models/search';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../../../../../environments';

const SEARCH_URL: string = environment.apiBaseUrl + '/v2/search';

@Injectable()
export class SearchService {

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  public search(searchRequest: SearchRequest, organizationId?: string): Observable<SearchResponse> {
    let headers = this.authService.getAjaxHttpHeaders();
    let url = SEARCH_URL + (organizationId ? `?organizationId=${organizationId}` : '');
    return this.http
      .post(url, searchRequest, {headers: headers})
      .pipe(map(response => response as SearchResponse));
  }

}
