import {Component, OnInit} from '@angular/core';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ReplaySubject} from 'rxjs';
import {PhoneMask} from '../../../../modules/zentility/_shared/_enums/zen-masks.enum';
import {Tag, TagType} from '../../../../modules/zentility/_modules/portfolio/_model/tag.model';
import {ValidPatterns} from '../../../../modules/zentility/_shared/_zen-legacy-common/_models/common/email-pattern';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

@Component({
  selector: 'app-zen-mat-form-fields',
  templateUrl: './zen-mat-form-fields.component.html',
  styleUrls: ['./zen-mat-form-fields.component.scss']
})
export class ZenMatFormFieldsComponent implements OnInit {
  appearance: MatFormFieldAppearance = 'outline';
  errorEmail = new UntypedFormControl(null, [Validators.required, Validators.pattern(ValidPatterns.EMAIL_PATTERN)]);
  errorEmailEngaged = new UntypedFormControl('Text', [Validators.required, Validators.pattern(ValidPatterns.EMAIL_PATTERN)]);
  errorFileEngaged = new UntypedFormControl('File Name', [Validators.required, Validators.pattern(ValidPatterns.EMAIL_PATTERN)]);

  campaignOne = new UntypedFormGroup({
    start: new UntypedFormControl(new Date(year, month, 13)),
    end: new UntypedFormControl(new Date(year, month, 16)),
  });
  campaignTwo = new UntypedFormGroup({
    start: new UntypedFormControl(new Date(year, month, 15)),
    end: new UntypedFormControl(new Date(year, month, 19)),
  });

  unitBtnsEngDisabled = new UntypedFormGroup({
    usage: new UntypedFormControl('Text'),
    unit: new UntypedFormControl('kWh'),
  });

  unitBtnsError = new UntypedFormGroup({
    usage: new UntypedFormControl('Text', [Validators.required]),
    unit: new UntypedFormControl('kWh', [Validators.required])
  });

  unitBtnsErrorEngaged = new UntypedFormGroup({
    usage: new UntypedFormControl('Text', [Validators.required]),
    unit: new UntypedFormControl('kWh', [Validators.required])
  });

  phoneMask = PhoneMask;
  unmask = true;

  // Tags
  TagType = TagType;
  selectedTags: Partial<Tag>[] = [{
    'id': '9388549c-12c0-4750-af3f-110edb34dc21',
    'name': 'mytagname',
    'objectType': TagType.CUSTOMER,
    'tagGroup': {'id': 'f0979123-4147-4074-bcdd-ac8990b545c3', 'name': 'bad', 'objectType': TagType.CUSTOMER, 'customerId': 163537843},
    'customerId': 163537103,
    'lenId': null,
    'propertyId': null,
    'meterId': null
  }, {
    'id': 'fcb1bcc6-7cd8-4dae-822d-6a8e214752e9',
    'name': 'CraigTest',
    'objectType': TagType.CUSTOMER,
    'tagGroup': null,
    'customerId': 163537103,
    'lenId': null,
    'propertyId': null,
    'meterId': null
  }];

  public filteredContacts: ReplaySubject<any> = new ReplaySubject();

  constructor() {
  }

  ngOnInit(): void {
    this.unitBtnsEngDisabled.disable();
    this.unitBtnsEngDisabled.markAsDirty();

    this.unitBtnsError.controls.usage.markAsDirty();
    this.unitBtnsError.controls.unit.markAsDirty();

    this.errorEmail.markAsDirty();
    this.errorEmail.markAsTouched();

    this.errorEmailEngaged.markAsDirty();
    this.errorEmailEngaged.markAsTouched();

    this.errorFileEngaged.markAsDirty();
    this.errorFileEngaged.markAsTouched();

    this.filteredContacts.next(
      [{label: 'Label 1'}, {label: 'Label 2'}]
    );
  }


  displayFn(item): string {
    return item && item.label ? item.label : '';
  }

}
