export enum PortfolioHierarchyLevelEnum {
 PORTFOLIO = 0, CUSTOMERS = 1, LENS = 2, PROPERTIES = 3, METERS = 4
}

// Details page after delete goBack call handling.
export enum PfDetailsAfterDelete {
  CUSTOMERS = 0, LENS = 1, PROPERTIES = 2, METERS = 3
}
// This is used to determine the type of filters we get back from the initial filter API
export enum PortfolioFilterScope {
  PORTFOLIO = 'PORTFOLIO',
  CONTRACT = 'CONTRACT',
  RATECHECK = 'RATECHECK'
}
