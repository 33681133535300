export enum CustomerAndPropertyType {
  GOVERNMENT = 'Government',
  EDUCATION = 'Education',
  NON_PROFIT = 'Non-Profit',
  HEALTHCARE = 'Healthcare',
  REAL_ESTATE = 'Real Estate',
  HOSPITALITY = 'Hospitality',
  FINANCIAL = 'Financial',
  RETAIL = 'Retail',
  MANUFACTURING = 'Manufacturing',
  TRANSPORTATION = 'Transportation',
  MEDIA_ENTERTAINMENT = 'Media Entertainment',
  TECHNOLOGY = 'Technology',
  OTHER = 'Other'
}

export enum PropertySubType {
  OFFICE_BUILDING = 'Office Building',
  OTHER = 'Other',
  CITY_HALL_MUNICIPAL_BUILDING = 'City Hall / Municipal Building',
  COUNTY_COURTHOUSE_ADMINISTRATIVE_BUILDING = 'County Courthouse / Administrative Building',
  STATE_CAPITOL_BUILDING_OFFICE = 'State Capitol Building / Office',
  FEDERAL_BUILDING = 'Federal Building',
  MILITARY_BASE_INSTALLATION = 'Military Base / Installation',
  EMBASSY_CONSULATE = 'Embassy / Consulate',
  CORRECTIONAL_FACILITY = 'Correctional Facility',
  NATIONAL_PARK_MONUMENT = 'National Park / Monument',
  GOVERNMENT_OWNED_BUILDING = 'Government-Owned Building',
  FEDERAL_FINANCIAL_INSTITUTION = 'Federal Financial Institution',
  PRESCHOOL_DAYCARE = 'Preschool / Daycare',
  SCHOOL_K_12 = 'School (K-12)',
  UNIVERSITY_COLLEGE = 'University / College',
  ADMINISTRATIVE_BUILDING = 'Administrative Building',
  RELIGIOUS = 'Religious',
  HOSPITAL = 'Hospital',
  CLINICS_URGENT_CARE = 'Clinics / Urgent Care',
  NURSING_HOME = 'Nursing Home',
  REHABILITATION_CENTER = 'Rehabilitation Center',
  MENTAL_HEALTH_CENTER = 'Mental Health Center',
  LABORATORY_DIAGNOSTICS = 'Laboratory / Diagnostics',
  IMAGING_CENTERS = 'Imaging Centers',
  HOSPICE_CARE = 'Hospice Care',
  DENTAL = 'Dental',
  CHIROPRACTIC_OFFICE = 'Chiropractic Office',
  PHYSICAL_THERAPY_CENTER = 'Physical Therapy Center',
  OCCUPATIONAL_THERAPY_CENTER = 'Occupational Therapy Center',
  COMMERCIAL = 'Commercial',
  MULTI_FAMILY = 'Multi-Family',
  INDUSTRIAL = 'Industrial',
  WAREHOUSE_STORAGE = 'Warehouse / Storage',
  MIXED_USE = 'Mixed-Use',
  HOTEL_RESORT = 'Hotel / Resort',
  RESTAURANT = 'Restaurant',
  BAR_NIGHTCLUB = 'Bar / Nightclub',
  CASINO = 'Casino',
  AMUSEMENT_PARK = 'Amusement Park',
  MUSEUM_ART_GALLERY = 'Museum / Art Gallery',
  CULTURAL_HISTORIC_SITE = 'Cultural / Historic Site',
  BANK_CREDIT_UNION = 'Bank / Credit Union',
  DEPARTMENT_STORE = 'Department Store',
  SPECIALTY_STORE = 'Specialty Store',
  GROCERY_CONVENIENCE = 'Grocery / Convenience',
  BRICK_AND_MORTAR_STORE = 'Brick-and-Mortar Store',
  APPAREL = 'Apparel',
  BEAUTY_PERSONAL_CARE = 'Beauty and Personal Care',
  HOME_GOODS_FURNITURE = 'Home Goods / Furniture',
  ELECTRONICS_TECHNOLOGY = 'Electronics and Technology',
  PET_SUPPLY = 'Pet Supply',
  SPORTING_GOODS_OUTDOOR = 'Sporting Goods / Outdoor',
  BOOKSTORE = 'Bookstore',
  FACTORY_PLANT = 'Factory / Plant',
  WAREHOUSES_DISTRIBUTION_CENTER = 'Warehouses / Distribution Center',
  RESEARCH_DEVELOPMENT_CENTER = 'Research / Development Center',
  FOUNDRIES_METALWORKING_FACILITY = 'Foundries / Metalworking Facility',
  PROCESSING_ASSEMBLY_PLANT = 'Processing / Assembly Plant',
  REFINERIES_CHEMICAL_PLANT = 'Refineries / Chemical Plant',
  POWER_PLANT_UTILITY = 'Power Plant / Utility',
  FABRICATION_MACHINE_SHOP = 'Fabrication / Machine Shop',
  CONSTRUCTION_YARD_HEAVY_EQUIPMENT_FACILITY = 'Construction Yard / Heavy Equipment Facility',
  INDUSTRIAL_PARK_BUSINESS_INCUBATOR = 'Industrial Park / Business Incubator',
  WAREHOUSE_DISTRIBUTION_CENTERS = 'Warehouse / Distribution Centers',
  AIRPORT = 'Airport',
  PORT = 'Port',
  RAIL_YARD = 'Rail Yard',
  SHIPPING_FREIGHT_CENTER = 'Shipping / Freight Center',
  TRANSPORTATION_CENTER = 'Transportation Center',
  STATION = 'Station',
  PRODUCTION_FACILITY = 'Production Facility',
  SPORTS_TEAM_FACILITY = 'Sports Team Facility',
  ARENA = 'Arena',
  DATA_CENTER_SERVER_FARM = 'Data Center / Server Farm',
  NETWORK_OPERATIONS_CENTERS = 'Network Operations Centers (NOCs)',
  R_D_LAB = 'R&D Lab',
  CORPORATE_OFFICES_HEADQUARTERS = 'Corporate Offices or Headquarters',
  CALL_SUPPORT_CENTER = 'Call / Support Center',
  TELECOMMUNICATIONS_SWITCHING_CENTER = 'Telecommunications Switching Center',
  BROADCAST_MEDIA_STATION = 'Broadcast / Media Station',
  TRAINING_CONFERENCE_CENTER = 'Training / Conference Center'
}

export enum MeterType {
  COMMON_AREA = 'Common Area',
  UNIT = 'Unit'
}
