export enum ZenUnitsEnum {
  // Electricity Units
  kWh = 'kWh', // Base unit for electricity
  MWh = 'MWh',

  // Gas Units
  Th = 'Th',
  Dth = 'Dth', // Base unit for Gas
  Ccf = 'Ccf',
  Mcf = 'Mcf',
  MMBtu = 'MMBtu',

  // Canadian gas units
  GJ = 'GJ'

  /**
   * Electricity - > 1. MWh, 2. kWh (default) →
   *   -- if unit MWh → val * 1000
   *
   * Gas → 1.Dth (default)  2.Th, 3.Ccf ,  4.Mcf  5.MMBtu (same as Dth), 6.Btu
   *   -– $ per Ccf divided by 1.037 equals $ per therm
   *   -- $ per Mcf divided by 10.37 equals $ per therm
   *
   * Dth = Th / 10
   * Dth = MMBtu
   * Dth = Btu / 1 mn
   * Dth = (Ccf / 1.037) / 10
   * Dth = (Mcf / 10.37) / 10
   *
   * NOTE: These unit conversations are handled in -> ZenUnitService
   * */
}
