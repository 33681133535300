import {MeterDetailsV4Model} from '../../../_modules/portfolio/_model/portfolio-meters.model';

export function isEmptyOrNull(value: string): boolean {
  return typeof value === 'string' && !value.trim() || typeof value === 'undefined' || value === null;
}

export function formatAddress(property): string {
  return concatAndIgnoreEmpty([property.street1, property.street2, property.city, property.state]) + ' ' + property.zip;
}

export function formatAddressType2(property): string {
  return concatAndIgnoreEmpty([property.address1, property.address2, property.city, property.state]) + ' ' + property.zip;
}

// This method removes comma between state and zip concat.
export function zenFormatAddressType(property): string {
  return concatAndIgnoreEmpty([property.address1, property.address2, property.city, property.state + ' ' + property.zip]);
}

export function formatMeterAddress(meter: MeterDetailsV4Model): string {
  return concatAndIgnoreEmpty([meter.propertyStreet1, (meter.street2 ? meter.street2 : meter.propertyStreet2), meter.propertyCity, meter.propertyState + ' ' + meter.propertyZip]);
}

export function formatPropertyAddress(property): string {
  return concatAndIgnoreEmpty([property.propertyAddress1, property.propertyAddress2, property.propertyCity, property.propertyState + ' ' + property.propertyZip]);
}

export function formatPropertyAddressV2(property): string {
  return concatAndIgnoreEmpty([property.propertyStreet1, property.propertyStreet2, property.propertyCity, property.propertyState + ' ' + property.propertyZip]);
}

export function formatLenAddress(len): string {
  return concatAndIgnoreEmpty([len.lenAddress1, len.lenAddress2, len.lenCity, len.lenState, len.lenZip]);
}

export function formatServiceAddress(property): string {
  return concatAndIgnoreEmpty([property.svcStreet1, property.svcStreet2, property.svcCity, property.svcState]) + ' ' + property.svcZip;
}


export function concatAndIgnoreEmpty(arr: string[]) {
  return arr.filter(Boolean).map(e => e.trim()).filter(Boolean).join(', ');
}

export function formatShortDate(date: Date): string {
  return date ? (date.toLocaleString('en-US').split(','))[0] : '';
}

export function getColoredRank(cell) {
  let rankClass = 'rank-low';
  if (cell > 10) { rankClass = 'rank-medium'; }
  if (cell > 25) { rankClass = 'rank-high'; }
  return 'rank-colored ' + rankClass;
}

export function getColoredRankTooltip(cell) {
  let rankClass = 'rank-low';
  if (cell > 10) { rankClass = 'rank-medium'; }
  if (cell > 25) { rankClass = 'rank-high'; }
  return rankClass.split('-')[1] + ' importance rank';
}

export function formatPercent(cell) {
  return round(cell, 2) + '%';
}

export function formatNumber(cell) {
  return Intl.NumberFormat('en-US').format(cell);
}

export function formatRate(cell) {
  return round(cell, 5);
}

export function formatPropertyLink(cell: any) {
  // work-around because ViewCells require the value to either be a string or number
  return JSON.stringify(cell);
}

export function round(value, exp) {
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math.round(value);
  }

  value = +value;
  exp = +exp;

  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }

  // Shift
  value = value.toString().split('e');
  value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));

  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp));
}


export function formatUsage(usage: number) {
  return formatNumber(usage);
}


export function formatEndDate(endDate: string) {
  return new Date(endDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short'});
}

export function convertToLocalDate(date: string): Date {
  let convertedDate = new Date(date);
  convertedDate.setTime(convertedDate.getTime() - (convertedDate.getTimezoneOffset() * 60000));
  return convertedDate;
}

export const PHONE_FORMAT = {
  mask: '(999) 999-9999',
  placeholder: '(000) 000-0000'
};

export function capitalizeFirstLetter(string: string) {
  return string ? string.charAt(0).toUpperCase() + string?.toLowerCase()?.slice(1) : '';
}

export function capitalizeEachFirstLetter(sentence: string) {
  return sentence ? sentence.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) : '';
}
