import {EventEmitter, Injectable} from '@angular/core';
import {AbstractControl, UntypedFormControl, Validators} from '@angular/forms';
import {PfCustomerDetailsModel} from '../../portfolio/_model/portfolio-customers.model';
import {RateCheckRequestStepsEnum} from '../_enums/rate-check-request-steps.enum';
import {ZenTimeline, ZenTimelineSteps} from '../../../_shared/_components/zen-timeline/zen-timeline.component';
import {NavigationService} from '../../../_shared/_zen-legacy-common/zen-common-services/_services/navigation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ZenDialogMsgService} from '../../../_shared/_services/zen-dialog-msg.service';
import {CustomerServiceV4} from '../../../_shared/_services/v4/customer-v4.service';
import {ItemListV4Model, ItemListWithCount} from '../../../_shared/_model/item-list-v4.model';
import {ZenErrorMsgEnum} from '../../../_shared/_enums/zen-error-msg.enum';
import {
  DataFlowChildrenDataTableModified,
  DataFlowChildrenDataV4Model,
  DataFlowHierarchyCategoryDetailsTableModifiedData,
  DataFlowHierarchyGroupedV4Model
} from '../../../_shared/_model/data-flow-hierarchy-v4.model';
import {ZenExpansionTableData} from '../../../_shared/_components/zen-mat-table-with-expansion/zen-mat-table-with-expansion.component';
import {DecimalPipe} from '@angular/common';
import {ZenHierarchicalGrouping} from '../../../_shared/_enums/zen-hierarchical-grouping.enum';
import {ZenBulkImportTableHelperService} from '../../../_shared/_services/helpers/zen-bulk-import-table-helper.service';
import {RateCheckV4Service} from '../../../_shared/_services/v4/rate-check-v4.service';
import {RateCheckRequestV4FlowModel, StaggeredStartProjection, ValidationType} from '../../../_shared/_model/rate-check-request-v4.model';
import {ItemListV4Service} from '../../../_shared/_services/v4/item-list-v4.service';
import {PfLenDialogRes, PfLensTableRowModel} from '../../portfolio/_model/portfolio-lens.model';
import {zenFormatAddressType} from '../../../_shared/_zen-legacy-common/_utils/format-utils';
import {CommodityType} from '../../../_shared/_zen-legacy-common/_models/commodity';
import {PortfolioLensHelperService} from '../../portfolio/_services/_helpers/portfolio-lens-helper.service';
import {MatDialog} from '@angular/material/dialog';
import {LOAObligationV4, LOAType} from '../../../_shared/_model/rate-check-v4-authorizations.model';
import {RateCheckStatusEnum} from '../../../_shared/_zen-legacy-common/_models/rate-checks/rate-check-status';
import {PriceEntity} from '../../../_shared/_zen-legacy-common/zen-common-services/tili-services/models/matrix-pricing';
import {isValidItemListV2} from '../../../_shared/_zen-legacy-common/_utils/validator-utils';
import {forkJoin} from 'rxjs';
import {ZenColors} from '../../../_shared/_enums/zen-colors.enum';
import {ContractFriendlyStatus, ContractFriendlyStatusDescription} from '../../../_shared/_zen-legacy-common/_utils/contract-utils';
import moment from 'moment';
import {LoadFactorLabelsMatrixRC} from '../../zen-reports/_modules/zen-rate-check-report/_models/matrix-rate-check.interface';
import {MatrixPricingHelperService} from '../../zen-market-pulse/_services/matrix-pricing-helper.service';
import {StateV4Service} from '../../../_shared/_services/v4/state-v4.service';
import {PropertyRow} from '../../zen-rate-checks/_model/zen-electric-rc-request.model';
import {BulkUpdateType} from '../../../_shared/_enums/zen-bulk-update-type.enum';
import {ZenLocaleModel} from '../../../_shared/_model/zen-locale.model';
import {TranslateService} from '@ngx-translate/core';
import {updateModifiedDataWithExistingData} from '../../../_shared/_utils/zen-grouped-data-flow.util';
import {ButtonActionTypes, ButtonTypes, ZenDialogDataModel} from '../../../_shared/_dialogs/zen-dialog/zen-dialog.component';
import {ZenSuccessMsgDialogComponent} from '../../../_shared/_dialogs/zen-success-msg-dialog/zen-success-msg-dialog.component';
import {ZenDialogSizeEnum} from '../../../_shared/_enums/zen-dialogs.enum';
import {ZenIconsEnum} from '../../../_shared/_enums/zen-icons.enum';


@Injectable({
  providedIn: 'root'
})
export class ZenRcReqFlowHelperService {
  currentStep = RateCheckRequestStepsEnum.S1_CUSTOMER_DETAILS;
  timelineSteps: ZenTimeline[] = [];
  customerIdParam: number;
  customerCtrl = new UntypedFormControl(null, [Validators.required]);

  refreshDataEmitter: EventEmitter<void> = new EventEmitter<void>();

  rateCheckId: string;
  rateCheck: RateCheckRequestV4FlowModel;
  /**
   * For Texas Electricity only:
   * 1) Hide transmission and capacity
   * 2) Expose Basis as Fixed or Passthrough Same as the Bulk Upload Sheet
   * 3) Consolidated Billing Only.
   */
  isTxElectric: boolean;

  rcStartDateInfo: StaggeredStartProjection[];
  // Customer item list
  searchableCustomers: ItemListV4Model[] = [];
  selectedCustomer: PfCustomerDetailsModel;
  stateOptions: ItemListWithCount[] = [];

  // Step 2: Meter selection
  selectedCommodity = new UntypedFormControl(); // CommodityType
  selectedState = new UntypedFormControl(null, [Validators.required]);

  dataFlowGroupedLoaded = new EventEmitter<void>();
  groupedRawData: DataFlowHierarchyGroupedV4Model[] = [];
  rawDataStaticDetails = {totUsageKwh: 0, totMeterCount: 0};
  groupedTableData: ZenExpansionTableData<DataFlowChildrenDataTableModified, DataFlowHierarchyCategoryDetailsTableModifiedData>[] = [];
  actionReqTableData: DataFlowChildrenDataTableModified[] = [];

  // Opportunities flyout
  showEligibleMetersSelectionFlyout: boolean;
  showBulkImportMetersFlyout: boolean;
  eligibleMetersRawData: DataFlowHierarchyGroupedV4Model[] = [];
  eligibleMetersGroupedTableData: ZenExpansionTableData<DataFlowChildrenDataTableModified, DataFlowHierarchyCategoryDetailsTableModifiedData>[] = [];

  // Step 2: Meter Selection & Opportunities
  selectedOpportunityType = new UntypedFormControl(null, [Validators.required]); // RcReqOpportunityTypesEnum

  matrixRcDetails = {
    zoneIds: [],
    rateClassIds: [],
    utilityIds: [],
    utilityNames: [],
    contractEndDates: new Set(),
    zipCodes: new Set(),
    contractStatuses: new Set(),
    tempMessages: []
  };

  statusLegends: { label: string, color: ZenColors, description: string; }[] = [
    {label: ContractFriendlyStatus.Current, description: ContractFriendlyStatusDescription.Current, color: ZenColors.SUCCESS_2},
    {label: ContractFriendlyStatus.Future, description: ContractFriendlyStatusDescription.Future, color: ZenColors.SUCCESS_3},
    {label: ContractFriendlyStatus.Processing, description: ContractFriendlyStatusDescription.Processing, color: ZenColors.INFO_1},
    {label: ContractFriendlyStatus.On_Utility, description: ContractFriendlyStatusDescription.On_Utility, color: ZenColors.WARN_1},
    {label: ContractFriendlyStatus.Expired, description: ContractFriendlyStatusDescription.Expired, color: ZenColors.DANGER_1}
  ];


  // Step 3: Pricing Details
  totalAnnualUsage = new UntypedFormControl(1000, [Validators.required]);
  showMatrixPricingBuilder: boolean;
  matrixPricingDetails: { selectedPricingIds: string[]; selectedPriceEntities: PriceEntity[], totalFeeMils: number };

  // Step 4: Authorization
  selectedAuthType = new UntypedFormControl(); // CommodityType
  contactUserCtrl = new UntypedFormControl();
  loaObligationValid: boolean;

  loading: boolean;

  showCompleteMsg: boolean;

  constructor(private navSvc: NavigationService, private router: Router, private route: ActivatedRoute,
              private zenDialogSvc: ZenDialogMsgService, private customerV4Svc: CustomerServiceV4,
              private decimalPipe: DecimalPipe,
              private rcV4Svc: RateCheckV4Service,
              private pfLenHelpSvc: PortfolioLensHelperService,
              private dialog: MatDialog,
              private itemListV4Svc: ItemListV4Service,
              private translateSvc: TranslateService,
              private stateV4Svc: StateV4Service,
              private zenBulkImportTableHelperSvc: ZenBulkImportTableHelperService,
              public mpHelpSvc: MatrixPricingHelperService) {
  }

  goToMeterSelectionsIfErrorsExist() {
    if (this.rateCheck.validations && this.rateCheck.validations.validations
      .filter(x => x.validationType === ValidationType.ERROR).length > 0 && !this.rateCheck.validations.valid) {
      this.gotoNextStep(RateCheckRequestStepsEnum.S2_METER_SELECTION);
    }
  }

  loadCustomers(search?: string, callback?: Function): void {
    this.loading = true;
    this.itemListV4Svc.getOrgCustomerItemList(search).subscribe({
      next: result => {
        // Keeping initially loaded customers. Item-list API default count 100.
        this.searchableCustomers = result;
        this.loading = false;

        // When there is only one customer - preselect the customer in the rate check builder.
        if (result.length === 1 && !this.customerIdParam) {
          this.handleCustomerSelection(result[0]?.key, () => this.loading = false);
        }

        if (callback) {
          callback();
        }
      }, error: err => {
        this.loading = false;
        this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      }
    });
  }

  setMatrixRcDetails() {
    this.matrixRcDetails = {
      zoneIds: [],
      rateClassIds: [],
      utilityIds: [],
      utilityNames: [],
      contractEndDates: new Set(),
      zipCodes: new Set(),
      contractStatuses: new Set(),
      tempMessages: []
    };
  }


  loadStates(formCtrl: UntypedFormControl | AbstractControl, commodity: CommodityType, callback?: Function): void {
    const customerId = this?.rateCheck?.customerId || this.customerCtrl.value;

    if (customerId && commodity) {
      this.stateV4Svc.getStateByCustomerIdAndCommodity(customerId, commodity).subscribe({
        next: commodityStates => {
          const _modifiedStates = commodityStates.map((s, index) => {
            return {
              key: s.key,
              value: s.value,
              bolder: s.meterCount > 0
            } as ItemListWithCount
          });

          this.stateOptions = [..._modifiedStates.filter(s => s.bolder), ..._modifiedStates.filter(s => !s.bolder)];

          // If we choose an ineligible state,  lets clear it out.  If its valid, lets keep it.
          if (commodityStates.findIndex(s => s.value === this.selectedState.value) === -1) {
            this.selectedState.reset();
          }

          // Update state validation
          setTimeout(() => {
            formCtrl.setValidators([Validators.required, isValidItemListV2(_modifiedStates)]);
          }, 10);


          this.loading = false;

          if (callback) {
            callback();
          }
        },
        error: () => {
          this.loading = false;
          this.zenDialogSvc.openToast(false);
        }
      });
    }
  }

  handleCustomerSelection(customerId: number, callBack?: Function) {
    if (customerId) {
      this.loading = true;
      this.customerV4Svc.getCustomerDetails(customerId).subscribe(customer => {
        this.selectedCustomer = customer;

        // Edit RC
        if (this.customerIdParam && !this.searchableCustomers.some(c => c.key === this.customerIdParam)) {
          this.searchableCustomers.push({key: this.customerIdParam, value: customer.companyName});
        }

        this.customerCtrl.setValue(customerId, {emitEvent: false});

        this.loading = false;

        if (callBack) {
          callBack();
        }
      }, err => {
        this.loading = false;
        this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      });
    }
  }

  getRateCheckMetersGroupedDataById(groupBy: ZenHierarchicalGrouping, callback?: Function, skipLoader = false, init = false) {
    this.loading = !skipLoader

    this.rcV4Svc.getRcRequestDataFlowGroupedByRcId(this.customerIdParam, this.rateCheckId, groupBy)
      .subscribe({
        next: (data) => {
          this.loading = false;
          this.groupedRawData = data;
          this.setDataStaticDetails();

          // Cleaning array data to improve performance.
          this.groupedTableData = [];

          this.handleAfterDataLoad();
          if (callback) {
            callback();
          }

          // On init if there is no data show welcome msg dialog
          if (init && this.rateCheck.statusCode < RateCheckStatusEnum.STEP_2_PRE && (!data || data?.length === 0)) {
            this.openStepTwoWelcomeMsgDialog();
          }
        },
        error: err => {
          this.loading = false;
          this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        }
      });
  }

  /**
   * Method used to map updated table row data at categoryDetails and meter data level.
   * @param groupBy
   * @param rowData
   * @param callback
   */
  loadRateCheckMetersAndMapRowData(groupBy: ZenHierarchicalGrouping, rd: DataFlowChildrenDataTableModified, callback?: Function) {
    this.loading = true;

    this.rcV4Svc.getRcRequestDataFlowGroupedByRcId(this.customerIdParam, this.rateCheckId, groupBy)
      .subscribe({
        next: updatedData => {
          this.loading = false;
          this.setDataStaticDetails();

          this.groupedRawData = updatedData;
          const _updatedRawData = updatedData.find(d => d?.data?.some(cd => cd?.meter?.meterId === rd?.meterId));
          const updatedModifiedData = this.zenBulkImportTableHelperSvc.getModifiedTableData([_updatedRawData], this.selectedCommodity.value);
          updateModifiedDataWithExistingData(rd, groupBy, this.groupedTableData, updatedModifiedData);

          if (callback) {
            callback();
          }
        },
        error: err => {
          this.loading = false;
          this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        }
      });
  }

  openStepTwoWelcomeMsgDialog() {
    let dialogData: ZenDialogDataModel = {
      data: {
        title: `Let’s add some meters`,
        bodyText: `Click <span class="mat-mdc-icon-button with-text default-state-btn">
    <i class="material-icons text-color-dark vertical-align-sub me-1">${ZenIconsEnum.ADD}</i>
    <span class="text-color-dark font-weight-400 small">Meters</span>
</span> to add existing meters to your Rate Check or click
<span class="mat-mdc-icon-button with-text default-state-btn">
    <i class="material-symbols-rounded medium-2 text-color-dark vertical-align-sub me-1">${ZenIconsEnum.UPLOAD}</i>
    <span class="text-color-dark font-weight-400 small">Import</span>
</span>
to import new meters.`
      },
      hideCloseX: true,
      actionButtons: [
        {
          label: 'Meters', prefixIcon: ZenIconsEnum.ADD,
          btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'accent', actionType: ButtonActionTypes.SUBMIT,
          command: () => {
            dialogRef.close();
            this.openEligibleMeterSelectionFlyout();
          }
        },
        {
          label: 'Import', prefixIcon: ZenIconsEnum.UPLOAD,
          btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'accent', actionType: ButtonActionTypes.SUBMIT,
          command: () => {
            dialogRef.close();
            this.openBulkImportMetersFlyout();
          }
        }
      ]
    };

    const dialogRef = this.dialog.open(ZenSuccessMsgDialogComponent, {
      width: ZenDialogSizeEnum.EXTRA_SMALL,
      data: dialogData, autoFocus: false
    });
  }

  setDataStaticDetails() {
    this.rawDataStaticDetails = {totUsageKwh: 0, totMeterCount: 0};

    this.groupedRawData.forEach(gd => {
      if (gd.hasSubCategories) {
        gd.subCategories.forEach(subCat => {
          this.rawDataStaticDetails.totMeterCount += subCat?.counts?.meterCount;
          this.rawDataStaticDetails.totUsageKwh += subCat?.counts?.totalUsage;
        });
      } else {
        this.rawDataStaticDetails.totMeterCount += gd?.counts?.meterCount;
        this.rawDataStaticDetails.totUsageKwh += gd?.counts?.totalUsage;
      }
    });
  }

  handleAfterDataLoad() {
    this.groupedTableData = this.zenBulkImportTableHelperSvc.getModifiedTableData(this.groupedRawData, this.selectedCommodity.value);
    this.dataFlowGroupedLoaded.emit();
  }

  buildTimelineSteps() {
    this.timelineSteps = [
      {title: RateCheckRequestStepsEnum.S1_CUSTOMER_DETAILS, className: ZenTimelineSteps.PENDING},
      {title: RateCheckRequestStepsEnum.S2_METER_SELECTION, className: null},
      {title: RateCheckRequestStepsEnum.S3_PRICING_DETAILS, className: null},
      {title: RateCheckRequestStepsEnum.S4_AUTHORIZATION, className: null},
      {title: RateCheckRequestStepsEnum.S5_COMPLETE, className: null}
    ];
  }

  gotoNextStep(stepTitle: RateCheckRequestStepsEnum) {
    // When customer got selected from the base route -> /advisor/request-v2 from the step 1.
    // Updating the URL state
    if (location.pathname === this.navSvc.toRouterLink(this.navSvc.getRateCheckRequestV2BaseRoute())) {
      this.router.navigate(this.navSvc.getRateCheckRequestV2PageRoute(this.selectedCustomer.customerId, this.rateCheck?.id))
        .then(() => this.updateTimelineStatus(stepTitle));
    } else {
      this.updateTimelineStatus(stepTitle);
    }
  }

  goToMeterSelectionFromCustomerImport(stepTitle: RateCheckRequestStepsEnum, customerId: number, rateCheckId: string) {
    this.router.navigate(this.navSvc.getRateCheckRequestV2PageRoute(customerId, rateCheckId))
      .then(() => this.updateTimelineStatus(stepTitle));
  }

  goToMatrixBuilderRcFlow(incumbentSupplierScenarioIds: string[]) {
    // If its our first time to the RC flow, save the status update
    if (this.rateCheck.statusCode < RateCheckStatusEnum.STEP_2_PRE) {
      const request: Partial<RateCheckRequestV4FlowModel> = {
        statusCode: RateCheckStatusEnum.STEP_2_PRE,
        incumbentSupplierScenarioIds: incumbentSupplierScenarioIds
      };
      this.updateRcRequestFlowDetails(request, this.startMatrixPricingBuilderRcFlow.bind(this), true);
    } else {
      // otherwise, start the matrix builder flow
      this.startMatrixPricingBuilderRcFlow();
    }
  }


  startMatrixPricingBuilderRcFlow() {
    // Steps to support Matrix Pricing builder
    this.mpHelpSvc.init(false);
    this.mpHelpSvc.searchCriteria.state = this.rateCheck.state;
    this.mpHelpSvc.getAndLoadUtilities();

    this.mpHelpSvc.selectedProperties = [];
    this.mpHelpSvc.utilityList = [];

    // fetching fresh data from the API each time in case changes were made
    this.getRateCheckMetersGroupedDataById(ZenHierarchicalGrouping.LEN, this.finishMatrixPricingBuilderRcFlow.bind(this));
  }

  finishMatrixPricingBuilderRcFlow() {
    let today = this.mpHelpSvc.normalizeToTimeZone(moment());
    this.mpHelpSvc.effectivePriceDateDisplay = today.format('MM/DD/YYYY');
    let totUsageMwh: (number | string) = this.groupedRawData
      .reduce((prev, gd) => (gd?.counts?.totalUsage + prev), 0) / 1000;

    /* Variables needs to set */
    this.mpHelpSvc.searchCriteria = {
      state: this.rateCheck.state, utilityId: this.matrixRcDetails.utilityIds[1],
      rateClass: this.matrixRcDetails.rateClassIds[1], zone: this.matrixRcDetails.zoneIds[1],
      loadFactor: LoadFactorLabelsMatrixRC.NONE, utilityName: this.matrixRcDetails.utilityNames[1], peakDemandKw: undefined,
      terms: [6, 12, 18, 24, 30, 36, 48, 60], annualMwhUsage: totUsageMwh,
      passThroughCapAndTrans: false, renewable: undefined,
      startDate: this.rateCheck.startDate,
      effectivePriceDate: today.toDate()
    };

    this.mpHelpSvc.selectedProperties = this.groupedTableData.map(gtd => {
      if (gtd.hasSubCategories) {
        return gtd.subCategories.map(sc => sc?.data?.map(d => this.getPropertyDetailsFromBulkImportData(d))
          .flatMap(d => d)).flatMap(d => d);
      } else {
        return gtd?.data?.map(d => this.getPropertyDetailsFromBulkImportData(d));
      }
    }).flatMap(d => d) as PropertyRow[];

    /* Variables used in Matrix */
    // this.mpHelpSvc.priceEntities
    // this.mpHelpSvc.savingAnalysis
    setTimeout(() => {
      this.mpHelpSvc.setTotalAnnualMwhUsage();
      this.showMatrixPricingBuilder = true;
      this.gotoNextStep(RateCheckRequestStepsEnum.S3_PRICING_DETAILS);
    }, 25);
  }

  getPropertyDetailsFromBulkImportData(childData: DataFlowChildrenDataTableModified): Partial<PropertyRow> {
    const _rc = this.rateCheck;
    const _selectedCustomer = this.selectedCustomer;
    const _rawData = this.getRawDataOfEditingRow(childData);
    const _rawContract = _rawData?.contract;
    return {
      // billingCity: 'Austin'
      // billingState: 'TX'
      // billingStreet1: '1 Main Street'
      // billingStreet2: null
      // billingZip: '02115'
      // borderStyle: {}
      // companyName: 'aa456'
      // contractOnboardingStatus: 'Pre-Existing Contract'
      // contractStatus: childData?.contractStatus,
      contractStatusV2: {
        energyPlanId: childData?.energyPlanId,
        rateCheckId: _rc.id,
        // @ts-ignore
        contractStatus: childData?.contractStatus
      },
      customerId: _selectedCustomer.customerId,
      meterId: childData.meterId,
      // customerStatus: 'Active'
      // disabled: false
      effectiveRate: _rawContract?.contractRate,
      effectiveUtilityRate: 0,
      endDate: new Date(_rawContract?.contractEndDate),
      energyPlanId: childData?.energyPlanId,
      // firstName: 'Ussain_',
      // greenName: '25.0% Green',
      greenPercent: _rawContract?.contractRenewablePct,
      // greenStatus: 'Green',
      // greenType: 'hybrid'
      // id: 4856299171210076000
      label: childData?.propertyName,
      // lastName: 'Raswi'
      // latestEnergyPlanId: 15360
      // latestUtilityBillId: null
      // lenId: :'27baea0d-d6ee-4a89-b11d-92325f890edd'
      // lenName: 'Tagret Houston LLC'
      maxRateCheckStatus: _rc.statusCode,
      peakDemandKw: _rawData.meter.peakDemand != null ? _rawData.meter.peakDemand : 0,
      // pepId:145497
      planEndDate: childData?.contractEndDate,
      planUtilityId: childData?.meterUtilityId,
      // planUtilityIds: '134'
      // pricingSolutionId: null
      // pricingSolutionRate: null
      // propertyCity: childData?.propertyCity,
      propertyId: childData?.propertyId,
      propertyLabel: childData?.propertyName,
      propertySecondaryUtilAccountNo: childData?.utilityAccountNum2,
      propertyState: childData?.propertyState,
      // propertyStatus: 'Active',
      // propertyStreet1: '101',
      // propertyStreet2: 'Suite 208'
      propertyUtilAccountNo: childData?.utilityAccountNum1,
      propertyUtilityRateSchedule: {
        id: childData?.rateScheduleId,
        utilityId: childData?.meterUtilityId,
        code: childData?.rateScheduleCode,
        description: '',
        type: ''
      },
      propertyZip: childData?.propertyZip,
      propertyZone: {
        id: childData?.zoneId,
        name: childData?.zoneName,
        state: _rc.state
      },
      // quoteId: :null
      // rateCheckId: null
      // rateCheckStatus: null
      rateType: _rawContract?.contractRateType,
      // refreshable: false
      serviceAddress: childData?.propertyServiceAddress,
      startDate: null, // TODO: We might need API integration here.
      state: _rc.state,
      supplierId: _rawContract?.contractSupplierId,
      supplierName: _rawContract?.contractSupplier,
      supplierRate: childData?.contractRate,
      // switchDate: '2021-06-01'
      termMonths: childData?.contractTerm,
      // tooltipMsg: undefined
      totalAnnualMwhUsage: childData?.annualUsage / 1000,
      uan: childData?.utilityAccountNum1,
      utilityId: childData?.meterUtilityId,
      utilityName: childData?.meterUtilityName,
      // utilityProcurementStatus: 'AVAILABLE'
      yearlyUsage: childData?.estimatedAnnualUsage
    }
  }


  /** Based on the RC statusCode we will update the step status - If a step is COMPLETED
   * 1. Next step will be in IN_PROGRESS state.
   * 2. All the other further steps will be set to PENDING **/
  updateTimelineStatus(stepTitle: RateCheckRequestStepsEnum, updateUrl = true) {
    setTimeout(() => {
      const _isMatrixAuth = this.showMatrixPricingBuilder && stepTitle === RateCheckRequestStepsEnum.S4_AUTHORIZATION;
      if (_isMatrixAuth) {
        return false; // No step 4 route for matrix
      } else {
        const currentStepIndex = this.timelineSteps.findIndex(ts => ts.title === stepTitle);
        this.timelineSteps = this.timelineSteps.map((ts, i) => {
          if (i - 1 >= -1 && i < currentStepIndex) { // On load update previous steps as completed
            ts.className = ZenTimelineSteps.COMPLETED;
          } else if (i === currentStepIndex && updateUrl) { // Current step should be pending
            ts.className = ZenTimelineSteps.PENDING;
          } else { // Other steps
            ts.className = null;
          }

          // No auth required for Matrix RC flow
          if (_isMatrixAuth) {
            ts.className = 'completed';
          }

          return ts;
        });

        if (updateUrl) {
          this.currentStep = stepTitle;
          this.updateUrlState();
        }
      }
    });
  }

  gotoStepWithoutChangingTimeline(stepTitle: RateCheckRequestStepsEnum) {
    this.currentStep = stepTitle;
  }

  updateUrlState() {
    let queryParams = {currentStep: this.currentStep}
    this.router.navigate([], {
      relativeTo: this.route, queryParams,
      queryParamsHandling: 'merge'
    });
  }

  handleGoBack(currentStepTitle: RateCheckRequestStepsEnum) {
    const _isMatrixAuth = this.showMatrixPricingBuilder && currentStepTitle === RateCheckRequestStepsEnum.S4_AUTHORIZATION;
    if (_isMatrixAuth) {
      return false; // No step 4 route for matrix
    } else {
      this.currentStep = currentStepTitle as RateCheckRequestStepsEnum;

      this.timelineSteps = this.timelineSteps.map(ts => {
        // If current step is not completed then it should be at pending
        if (ts.title === currentStepTitle && ts.className !== ZenTimelineSteps.COMPLETED) {
          ts.className = ZenTimelineSteps.PENDING;
        } else if (ts.className !== ZenTimelineSteps.COMPLETED) {
          // When user clicks previous step the blue from in progress should not be on bar.
          ts.className = null;
        }
        return ts;
      });

      this.updateUrlState();
    }
  }

  finalComplete() {
    const i = this.timelineSteps.findIndex(ts => ts.title === RateCheckRequestStepsEnum.S5_COMPLETE);
    this.timelineSteps[i].className = ZenTimelineSteps.COMPLETED;
    this.showCompleteMsg = true;
  }

  reset() {
    this.currentStep = RateCheckRequestStepsEnum.S1_CUSTOMER_DETAILS;
    this.customerIdParam = null;
    this.customerCtrl.reset();

    // Customer item list
    this.searchableCustomers = [];
    this.selectedCustomer = null;

    this.setRateCheck(null);

    // Step 2: Meter selection
    this.selectedCommodity.reset();
    this.selectedState.reset();

    this.groupedRawData = [];
    this.groupedTableData = [];
    this.actionReqTableData = [];
    this.showEligibleMetersSelectionFlyout = false;
    this.showBulkImportMetersFlyout = false;

    // Step 2: Meter Selection & Opportunities
    this.selectedOpportunityType.reset();

    // Step 3: Pricing Details
    this.totalAnnualUsage.reset();
    this.showMatrixPricingBuilder = false;
    this.matrixPricingDetails = null;

    // Step 4: Authorization
    this.selectedAuthType.reset();
    this.contactUserCtrl.reset();
    this.loaObligationValid = false;

    this.showCompleteMsg = false;
  }

  get isRcExists() {
    return Boolean(this.selectedCustomer?.customerId && this.rateCheckId);
  }

  createRcRequest() {
    if (this.selectedCustomer?.customerId) {
      const request = {
        customerId: this.selectedCustomer?.customerId,
        commodityType: this.selectedCommodity.value,
        state: this.selectedState.value
      } as RateCheckRequestV4FlowModel;


      this.loading = true;
      this.rcV4Svc.createRcRequest(this.selectedCustomer?.customerId, request).subscribe(_rc => {
        this.loading = false;
        this.rateCheckId = _rc.id;
        this.setRateCheck(_rc);
        this.gotoNextStep(RateCheckRequestStepsEnum.S2_METER_SELECTION);
      }, err => {
        this.loading = false;
        this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      });
    }
  }

  setRateCheck(_rc: RateCheckRequestV4FlowModel) {
    this.rateCheck = _rc;
    this.isTxElectric = _rc?.commodityType === CommodityType.Electricity && _rc?.state === 'TX';
  }

  // This uses a complete request object to make a RC Creation request
  // This is used by customer import flow.
  createRcByRequest(request: RateCheckRequestV4FlowModel, isBulkImportFlow = false, successCallBack?: Function, errorCallBack?: Function) {
    this.loading = true;
    this.rcV4Svc.createRcRequest(request.customerId, request).subscribe(_rc => {
      this.loading = false;
      this.rateCheckId = _rc.id;
      this.setRateCheck(_rc);
      if (isBulkImportFlow) {
        this.goToMeterSelectionFromCustomerImport(RateCheckRequestStepsEnum.S2_METER_SELECTION, request.customerId, this.rateCheckId);
      }
      if (successCallBack) {
        successCallBack();
      }
    }, err => {
      this.loading = false;
      this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      if (errorCallBack) {
        errorCallBack();
      }
    });
  }

  cloneRcByRequest(customerId: number, rcId: string, gotoMeterSelection = false, callback?: Function) {
    this.loading = true;
    this.rcV4Svc.cloneRateCheck(customerId, rcId).subscribe({
      next: _rc => {
        this.loading = false;
        this.rateCheckId = _rc.id;
        this.setRateCheck(_rc);
        if (callback) {
          callback();
        }
        if (gotoMeterSelection) {
          this.goToMeterSelectionFromCustomerImport(RateCheckRequestStepsEnum.S2_METER_SELECTION, customerId, this.rateCheckId);
        }
      }, error: err => {
        this.loading = false;
        this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      }
    });
  }

  isUpdateRcStatusIsGreaterThanCurrent(updateStatusCode: RateCheckStatusEnum): boolean {
    return updateStatusCode >= this.rateCheck.statusCode;
  }

  getStepTitleWrtRcStatusCode(): RateCheckRequestStepsEnum {
    switch (this.rateCheck?.statusCode) {
      case RateCheckStatusEnum.STEP_1:
        return RateCheckRequestStepsEnum.S2_METER_SELECTION;
      case RateCheckStatusEnum.STEP_2_PRE:
        return RateCheckRequestStepsEnum.S3_PRICING_DETAILS;
      case RateCheckStatusEnum.STEP_3:
        return RateCheckRequestStepsEnum.S4_AUTHORIZATION;
      case RateCheckStatusEnum.STEP_4:
        return RateCheckRequestStepsEnum.S5_COMPLETE;
      default:
        return RateCheckRequestStepsEnum.S1_CUSTOMER_DETAILS;
    }
  }

  updateRcRequestFlowDetails(request?: Partial<RateCheckRequestV4FlowModel>, callback?: Function, skipLoader = false, recalculate = false) {
    if (this.isRcExists) {
      // handle null request object
      request = (request == null) ? {} : request;
      // update request
      const updateRequest = {
        customerId: this.selectedCustomer?.customerId,
        commodityType: this.selectedCommodity.value,
        state: this.selectedState.value,
        ...request
      } as RateCheckRequestV4FlowModel;
      const _updateStatusCode = parseFloat(JSON.stringify(updateRequest.statusCode));
      // Do not update the RC status, if updating RC status code is less then the current status code.
      if (!this.isUpdateRcStatusIsGreaterThanCurrent(updateRequest.statusCode)) {
        updateRequest.statusCode = this.rateCheck.statusCode;
      }

      this.loading = !skipLoader
      this.rcV4Svc.updateRcRequest(this.selectedCustomer?.customerId, this.rateCheckId, updateRequest, recalculate).subscribe(_rc => {
        this.loading = false;
        this.setRateCheck(_rc);
        // Pull in start date info
        this.rcV4Svc.getRcRequestStartDateByRcId(this.selectedCustomer?.customerId, this.rateCheckId).subscribe(v => {
          this.rcStartDateInfo = v;
        });

        if (callback) {
          callback(_rc);
        }

        // To keep the timeline same, if updating RC status code is less then the current status code.
        if (!this.isUpdateRcStatusIsGreaterThanCurrent(_updateStatusCode)) {
          setTimeout(() => {
            // updatedUrl = _rc.statusCode === RateCheckStatusEnum.STEP_1 // Doing this change to fix,
            // When the RC status is 50, & user try to update step one & come back to step 2,
            // the step 2 should be at pending status in the timeline.
            this.updateTimelineStatus(this.getStepTitleWrtRcStatusCode(), _rc.statusCode === RateCheckStatusEnum.STEP_1);
          }, 10);
        }

      }, err => {
        this.loading = false;
        this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        // Resetting RC flow is RC id is not found.
        if (err?.error?.status === 404) {
          setTimeout(() => this.reset(), 25);
        }
      });
    }
  }

  getRateCheckRequestFlowById(callback?: Function, skipLoader = false) {
    if (this.selectedCustomer?.customerId && this.rateCheckId) {
      this.loading = !skipLoader
      forkJoin([this.rcV4Svc.getRcRequestFlowByRcId(this.selectedCustomer?.customerId, this.rateCheckId),
        this.rcV4Svc.getRcRequestStartDateByRcId(this.selectedCustomer?.customerId, this.rateCheckId)])
        .subscribe({
          next: ([_rc, _rcStartDate]) => {
            this.loading = false;
            this.setRateCheck(_rc);
            this.rcStartDateInfo = _rcStartDate;
            this.selectedCommodity.setValue(_rc.commodityType);
            this.customerCtrl.setValue(_rc.customerId);
            this.selectedState.setValue(_rc.state);
            if (callback) {
              callback();
            }
          },
          error: err => {
            this.loading = false;
            this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
            // Resetting RC flow is RC id is not found.
            if (err?.error?.status === 404) {
              setTimeout(() => this.reset(), 25);
            }
          }
        });
    }
  }

  // In the step 2: Meter selection - below flag is used to open/close Opportunities meter flyout.
  openEligibleMeterSelectionFlyout() {
    this.showEligibleMetersSelectionFlyout = true;
  }

  closeEligibleMeterSelectionFlyout() {
    this.showEligibleMetersSelectionFlyout = false;
  }

  // In the step 2: Meter selection - below flag is used to open/close Bulk Import meter flyout.
  openBulkImportMetersFlyout() {
    this.showBulkImportMetersFlyout = true;
  }

  closeBulkImportMetersFlyout() {
    this.showBulkImportMetersFlyout = false;
  }

  /** To edit LEN details using portfolio edit len dialog.
   * This method is used in both RcReqGroupedEligibleMeterSelectionFlyoutComponent & RcReqGroupedMetersTableComponent */
  handleEditLen(rowData: DataFlowHierarchyCategoryDetailsTableModifiedData, callback: Function) {
    this.pfLenHelpSvc.handleAddEditLen({
        id: rowData.lenId,
        customerId: this.customerIdParam
      } as PfLensTableRowModel, this.customerIdParam,
      (result: PfLenDialogRes) => {
        if (result?.lenModel) {
          rowData.lenName = result?.lenModel?.name;
          rowData.lenAddress = result?.lenModel?.billingAddress ? zenFormatAddressType(result?.lenModel?.billingAddress) : null;

          if (callback) {
            callback(result);
          }
        }
      }, () => {
      }, false, true);
  }

  /** Gets the proper data grouping to be used in the Rate Class or Zone popup according to the BulkUpdateType
   *  and passed along into the component
   * @param bulkUpdateType
   */
  getDataGrouping(bulkUpdateType: BulkUpdateType) {
    if (bulkUpdateType === BulkUpdateType.RATE_CHECK_ELIGIBLE_METERS_FLYOUT) { // from Eligible Meter Selection Flyout
      return this.eligibleMetersRawData[0].grouping;
    } else {  // from Rate Check Meters Table or from Bulk Import Recently Added Meters List
      return this.groupedRawData[0].grouping;
    }
  }

  /** Gets the proper data grouping text to be shown in the Rate Class or Zone popup according to the BulkUpdateType
   * @param bulkUpdateType
   */
  getDataGroupingText(bulkUpdateType: BulkUpdateType) {
    if (bulkUpdateType === BulkUpdateType.RATE_CHECK_ELIGIBLE_METERS_FLYOUT) { // from Eligible Meter Selection Flyout
      return this.eligibleMetersRawData[0].grouping === ZenHierarchicalGrouping.LEN ? 'LEN' : 'Energy Plan';
    } else {  // from Rate Check Meters Table or from Bulk Import Recently Added Meters List
      return this.groupedRawData[0].grouping === ZenHierarchicalGrouping.LEN ? 'LEN' : 'Energy Plan';
    }
  }

  /** To get Raw data of editing table row. Used to get primary ids and infos. */
  getRawDataOfEditingRow(rowData: DataFlowChildrenDataTableModified): DataFlowChildrenDataV4Model {
    let rawChildData: DataFlowChildrenDataV4Model;
    // This method is used in both RcReqGroupedEligibleMeterSelectionFlyoutComponent & RcReqGroupedMetersTableComponent
    // So, w.r.t the showEligibleMetersSelectionFlyout we are deciding the raw data.
    let _data = this.showEligibleMetersSelectionFlyout ? [...this.eligibleMetersRawData] : [...this.groupedRawData];
    if (_data?.length) {
      _data.find(rd => {
        const dataToCheck = rd.hasSubCategories ? rd.subCategories.flatMap(sc => sc.data) : rd.data;

        const matchingData = dataToCheck.find(d => d?.meter?.meterId === rowData?.meterId);
        if (matchingData) {
          rawChildData = matchingData;
          return true; // Break the loop since we found the data
        }
      });
    }
    return rawChildData;
  }

  // examples. Joes_Pizza_LLC__GenericGasLOA__MultipleUtilities or Joes_Pizza_LLC__UtilityGasLOA__National_Grid
  generateLoaFileName(loaObligation: LOAObligationV4) {
    const companyNamePrefix = this.selectedCustomer.companyName.replace(/\s/g, '_');
    const _translations = Object.values(this.translateSvc.translations)?.[0] as ZenLocaleModel;
    let loaType = loaObligation.loaType === LOAType.UTILITY_SPECIFIC ? _translations?.nomenclature?.utilityShort : 'Generic';
    loaType += (this.rateCheck.commodityType === CommodityType.Electricity ? 'ElectricityLOA' : 'GasLOA');
    const utilitySuffix = loaObligation.utilityNames.length === 1 ? loaObligation.utilityNames[0].replace(/\s/g, '_') : 'MultipleUtilities';
    return `${companyNamePrefix}__${loaType}__${utilitySuffix}.pdf`;
  }


  /** To get meter ids when the view by is contract status. */
  getMeterIdsWrtContractStatus(catStatusData: DataFlowHierarchyCategoryDetailsTableModifiedData): number[] {
    let meterIds: number[] = [];
    let _data = [...this.groupedRawData]; // Unlink option only used in the RC selected meters table.
    if (_data?.length) {
      _data.find(rd => {
        if (rd.categoryDetails?.contractStatus === catStatusData.contractStatus) {
          meterIds = rd.subCategories.map(sc => sc?.data?.map(d => d.meter?.meterId)).flatMap(id => id);
          return true;
        }
      });
    }
    return meterIds;
  }

  /** Below method used to set */
  get dontShowUnlinkAgainStorageName(): string {
    return `DONT_SHOW_UNLINK_CONFIRM_AGAIN-${this.rateCheck?.id}`;
  }

  setDontShowAgainUnlinkConfirmLocStorage(val: boolean) {
    localStorage.setItem(this.dontShowUnlinkAgainStorageName, JSON.stringify(val));
  }

  getDontShowAgainUnlinkConfirmLocStorage() {
    return JSON.parse(localStorage.getItem(this.dontShowUnlinkAgainStorageName));
  }

  removeDontShowAgainUnlinkConfirmLocStorage() {
    localStorage.removeItem(this.dontShowUnlinkAgainStorageName);
  }

}
