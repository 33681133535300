  <div class="d-flex justify-content-start align-items-center mb-3">
    <p class="form-title mb-0">{{friendlyType}} Tags</p>
    <i class="material-icons-outlined help-outline ms-2"
      [popper]="tagsPopper"
      [popperHideOnScroll]="true"
      [popperHideOnClickOutside]="true"
      popperTrigger="click"
      popperApplyClass="sm"
      popperPlacement="bottom">
      info
    </i>
  </div>

  <div class="d-flex justify-content-start text-start">
    <mat-form-field appearance="outline">
      <mat-label>Find or create a tag</mat-label>
     <input placeholder="Find or create a tag" matInput #inputElement
      [formControl]="tagFilterCtrl" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="handleOptionSelected($event.option.value, inputElement)" [displayWith]="getOptionText" [panelWidth]="350" [autoActiveFirstOption]="true">
        <mat-option *ngFor="let option of filteredTagOptions | async" [value]="option" [disabled]="option.disabled">
          <div class="option-row">
            <div *ngIf="option.isNew" class="plus">+</div>
            <span>{{option.label}}</span>
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="d-flex justify-content-start align-items-center mt-3 mb-2">
    <mat-chip-listbox #chipList>
      <mat-chip-option *ngFor="let tag of selectedTags" class="mx-2"  (click)="handleDelete(tag)">
        <div class="d-flex align-items-center">
          <span>#{{tag.tagGroup ? (tag.tagGroup.name + ":") : ""}}{{tag.name}}</span>
          <i class="material-icons pointer large ms-1">close</i>
        </div>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>


<popper-content #tagsPopper>
  <app-zen-popper [title]="popperTitle" [closeable]="true" [popperContent]="tagsPopper" align="left">
    <ng-container *ngIf="type === TagType.CUSTOMER">
      <p class="medium">
        Tags can be leveraged to group and filter similar objects together. Outlined below are a few use cases for how customers can be tagged:
      </p>
      <div class="list-data xs-bullet">
        <div class="list">
          Tier - group customers by tier to recognize the importance of one customer over another - tier: gold | tier:silver | tier:bronze
        </div>
      </div>
      <div class="list-data xs-bullet">
        <div class="list">
          Owner - assign an owner and filter pages to understand what each person on the team is responsible for - owner:john | owner:sara
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="type === TagType.LEN">
      <p class="medium">
        Tags can be leveraged to group and filter similar objects together. Outlined below is one use case for how legal entity names can be tagged:
      </p>
      <div class="list-data xs-bullet">
        <div class="list">
          Investor - group legal entity names by #investor to organize your portfolio -  investor:REIT1001 | investor:thewardfamilytrust
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="type === TagType.PROPERTY">
      <p class="medium">
        Tags can be leveraged to group and filter similar objects together. Outlined below is one use case for how properties can be tagged:
      </p>
      <div class="list-data xs-bullet">
        <div class="list">
          Complex - Group properties by complex name to recognize multiple properties located at the same site, each having a unique service addresses - complex:101west | complex:dominionbuspark
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="type === TagType.METER">
      <p class="medium">
        Tags can be leveraged to group and filter similar objects together. Outlined below is one use case for how  meters can be tagged:
      </p>
      <div class="list-data xs-bullet">
        <div class="list">
          Site Location - Group meters by site location to recognize those tied to a specific floor or portion of the outdoor common area - sitelocation:laundry | sitelocation:library
        </div>
      </div>
    </ng-container>
  </app-zen-popper>
</popper-content>
