import {ZenTableMenuOption} from '../_components/zen-mat-table/zen-mat-table.component';
import {orderBy} from 'lodash';
import {ZenLocaleModel} from '../_model/zen-locale.model';

/**
 * This method used to support <app-zen-menu-item> component. In the hierarchy level list tables and details pages,
 * Used to group the flat menu options w.r.t the parent menu item.
 * Here, the group by is taken place w.r.t the menu option label.
 * @param menuItems
 */
export function groupByPortfolioMenuItems(menuItems: ZenTableMenuOption[]): ZenTableMenuOption[] {
  if (menuItems?.length) {
    // Labels which are used in the portfolio menu options.
    const _viewItemLabels = ['View']; // View Menu Items Labels
    const _manageAddressItemLabels = ['Manage Addresses']; // View Menu Items Labels
    const _modifyItemLabels = ['Edit', 'Delete', 'Deactivate', 'Activate']; // Modify Menu Items Labels
    let _addEntityItemLabels = ['Legal Entity Name', 'Property', 'Meter', 'Bill'];
    let _sendEmailItemLabels = ['LOE/MLOA Email', 'Resend LOE/MLOA Email', 'Welcome Email'];
    const _meterBillsItemLabels = ['Replace Bill', 'Upload Bill'];

    // Parent group -> Modify, Add and Send Email
    let _modifyMenuChildren: ZenTableMenuOption[] = []; // Modify
    let _addEntityChildren: ZenTableMenuOption[] = []; // Add
    let _sendEmailChildren: ZenTableMenuOption[] = []; // Send Email

    menuItems.forEach(m => {
      if (_modifyItemLabels.includes(m.label)) {
        _modifyMenuChildren.push(m);
      }
      if (_addEntityItemLabels.includes(m.label)) {
        _addEntityChildren.push(m);
      }
      if (_sendEmailItemLabels.includes(m.label)) {
        _sendEmailChildren.push(m);
      }
    });

    _modifyMenuChildren = orderBy(_modifyMenuChildren, 'label');
    _addEntityItemLabels = orderBy(_addEntityItemLabels, 'label');
    _sendEmailItemLabels = orderBy(_sendEmailItemLabels, 'label');

    let _viewMenuItems: ZenTableMenuOption = menuItems.find(m => _viewItemLabels.includes(m.label)); // View
    let _manageAddressMenuItem: ZenTableMenuOption = menuItems.find(m => _manageAddressItemLabels.includes(m.label));
    let _meterBillsMenuItem: ZenTableMenuOption = menuItems.find(m => _meterBillsItemLabels.includes(m.label));

    // Add divider line with the view option if there is no Edit options.
    if (_viewMenuItems && (!_modifyMenuChildren || _modifyMenuChildren?.length === 0)) {
      _viewMenuItems.divider = true;
    }

    return [
      _viewMenuItems ? _viewMenuItems : null,
      _modifyMenuChildren?.length ? {label: 'Edit', type: 'button',
        divider: Boolean(_addEntityChildren?.length > 0), children: _modifyMenuChildren} as ZenTableMenuOption : null,
      _addEntityChildren?.length ? {label: 'Add', type: 'button', children: _addEntityChildren} as ZenTableMenuOption : null,
      _manageAddressMenuItem ? _manageAddressMenuItem : null,
      _sendEmailChildren?.length ? {label: 'Send Email', type: 'button', children: _sendEmailChildren} as ZenTableMenuOption : null,
      _meterBillsMenuItem ? _meterBillsMenuItem : null
    ].filter((menu: ZenTableMenuOption) => menu !== null);

  } else {
    return [];
  }
};

/**
 * This method used to support <app-zen-menu-item> component. Only in the RC list and RCR pages.
 * Used to group the flat menu options w.r.t the parent menu item.
 * Here, the group by is taken place w.r.t the menu option label.
 * @param menuItems
 */
export function groupByRateCheckMenuItems(menuItems: ZenTableMenuOption[], translations: ZenLocaleModel): ZenTableMenuOption[] {
  if (menuItems?.length) {
    const _suppTxt = translations?.nomenclature?.supplier;
    // Menu item labels used to group by
    const _viewItemLabels = ['View Request', 'View Report', 'View Contract Details', 'View Rates'];

    const _reportOptionsItemLabels = [ `Expose ${_suppTxt} Names`, `Anonymize ${_suppTxt}s`, 'Include Standard Report',
      'Include Renewable Report', 'Include MarketCheck', 'Show Median Rate',
      'Hide Median Rate', 'Hide Rate Check History', 'Include Rate Check History', 'Texas New Service Options'];

    const _editItemLabels = ['Modify (Legacy)', 'Edit Draft', 'Delete', 'Abort', 'Request Refresh'];

    const _shareItemLabels = ['Email', 'Copy Link to Report', 'Download Report'];

    // Menu items used as a children in the group by
    let _viewChildren: ZenTableMenuOption[] = [];
    let _reportOptionsMenuChildren: ZenTableMenuOption[] = [];
    let _editMenuChildren: ZenTableMenuOption[] = [];
    let _shareMenuChildren: ZenTableMenuOption[] = [];

    menuItems.forEach(m => {
      if (_viewItemLabels.includes(m.label)) {
        _viewChildren.push(m);
      }
      if (_reportOptionsItemLabels.includes(m.label)) {
        _reportOptionsMenuChildren.push(m);
      }
      if (_editItemLabels.includes(m.label)) {
        _editMenuChildren.push(m);
      }
      if (_shareItemLabels.includes(m.label)) {
        _shareMenuChildren.push(m);
      }
    });

    _viewChildren = orderBy(_viewChildren, 'label');
    _reportOptionsMenuChildren = orderBy(_reportOptionsMenuChildren, 'label');
    _editMenuChildren = orderBy(_editMenuChildren, 'label');
    _shareMenuChildren = orderBy(_shareMenuChildren, 'label');

    // @ts-ignore
    let _groupedMenuItems: ZenTableMenuOption[] = [
      _viewChildren?.length ? {label: 'View', type: 'button', children: _viewChildren} as ZenTableMenuOption : null,
      _editMenuChildren?.length ? {label: 'Edit', type: 'button', divider: _shareMenuChildren?.length > 0,
        children: _editMenuChildren} as ZenTableMenuOption : null,
      _reportOptionsMenuChildren?.length ? {label: 'Report Options', children: _reportOptionsMenuChildren} : null,
      _shareMenuChildren?.length ? {label: 'Share', type: 'button', children: _shareMenuChildren} : null
    ].filter((menu: ZenTableMenuOption) => menu !== null);

    return _groupedMenuItems;
  } else {
    return [];
  }

}

export function getActivationStatusFilterValue(tableMenuOptions: ZenTableMenuOption[]) {
  return !tableMenuOptions?.find(x => x?.label === 'Active')?.toggleDefaultVal;
}
