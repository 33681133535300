import { UtilityStatusTypes } from '../../../_models/properties-settings';
import {OnOffButtonModel} from './table-v2';

export enum CUSTOMER_MENU_ITEMS_TYPE {
  SEND_WELCOME_EMAIL = 'SEND_WELCOME_EMAIL', SEND_LOE_MLOA = 'SEND_LOE_MLOA', GOTO_CUSTOMER = 'GOTO_CUSTOMER'
}


export interface CustomerSignUp {
  firstName: string,
  lastName: string,
  title: string,
  email: string,
  companyName: string,
  domain: string,
  website: string,
  phone: string,
  password: string,
  city: string,
  state: string,
  zip: string,
  address1?: string,
  address2?: string,
  status?: string
}


export interface CustomerSummaryListItem {
  companyName: string;
  customerId: number;
  onboarded: any;
  rateChecks: number;
  accounts: number;
  annualUsageKwh: number;
  earnings: number;
  tacApproved: boolean;
  mloaSigned: boolean;
  showMenu?: boolean;
  markStatusCls?: string;
  createdAt?: string;
  onOffButtons?: OnOffButtonModel[];
  menuType?: CUSTOMER_MENU_ITEMS_TYPE;
  status: CustomerStatus;
}

export enum CustomerStatus {
  Pending = 'Pending',
  Active = 'Active',
  Cancelled = 'Cancelled',
  Suspended = 'Suspended'
}
