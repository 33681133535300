import { Injectable } from '@angular/core';
import {environment} from '../../../../../../environments';
import {HttpClient, HttpParams} from '@angular/common/http';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ItemListV4Model} from '../../_model/item-list-v4.model';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {LenAddressFilter} from '../../_enums/zen-len-address-filter.enum';
import {getParams} from '../../_zen-legacy-common/_utils/set-param-util';

@Injectable({
  providedIn: 'root'
})
export class ItemListV4Service {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient, private orgMgtSvc: OrganizationManagementService) { }

  /**
   * Customer item-list (key, value) api used in - PM Add/Edit dialog - LEN, Property and Meter.
   * This API only works for Advisor/Admin users.
   * @param search - required = false
   * @param active - required = false, defaultValue = "true"
   * @param limit - required = false, defaultValue = "200"
   * @param ignoreCache - required = false, defaultValue = "false"
   */
  getOrgCustomerItemList(search?: string, active = true, limit = 200, ignoreCache = false): Observable<ItemListV4Model[]> {
    let url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/customers/item-list`;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('search', search || '');
    httpParams = httpParams.append('active', active);
    httpParams = httpParams.append('limit', limit);
    httpParams = httpParams.append('ignoreCache', ignoreCache);
    return this.http.get<ItemListV4Model[]>(url, {params: httpParams}).pipe(take(1));
  }

  /**
   * LEN item-list (key, value) api used in - PM Add/Edit dialog - Property and Meter.
   * @param search - required = false
   * @param active - required = false, defaultValue = "true"
   * @param limit - required = false, defaultValue = "200"
   * @param ignoreCache - required = false, defaultValue = "false"
   * @param showAddress - defaultValue = "false", if its true it will append billingAddress with len,
   *  which is separated by || symbol. Value format -> lenName || address1, address2, city, start zip
   */
  getCustomerLenItemList(customerId: number, lenIds?: string[], search?: string, active = true, limit = 500, ignoreCache = false, lenAddressFilter = LenAddressFilter.ALL, showAddress = false): Observable<ItemListV4Model[]> {
    let url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/customers/${customerId}/lens/item-list`;
    let httpParams = getParams({search, active, limit, ignoreCache, lenAddressFilter, showAddress, lenIds});

    return this.http.get<ItemListV4Model[]>(url, {params: httpParams}).pipe(take(1));
  }

  /**
   * Property item-list (key, value) api used in - PM Add/Edit dialog - Meter.
   * @param search - required = false
   * @param active - required = false, defaultValue = "true"
   * @param limit - required = false, defaultValue = "200"
   * @param ignoreCache - required = false, defaultValue = "false"
   */
  getCustomerPropertyItemList(customerId: number, lenId?: string, search?: string,
                              utilityId?: number, commodityType?: CommodityType, propertyState?: string,
                              active = true, limit = 500, ignoreCache = false): Observable<ItemListV4Model[]> {
    let url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/portfolio/customers/${customerId}/properties/item-list`;
    let httpParams = new HttpParams();
    httpParams = httpParams.append('search', search || '');
    httpParams = httpParams.append('active', active);
    httpParams = httpParams.append('limit', limit);
    httpParams = httpParams.append('lenId', lenId);
    httpParams = httpParams.append('ignoreCache', ignoreCache);
    if (utilityId) {
      httpParams = httpParams.append('utilityId', utilityId);
    }
    if (commodityType) {
      httpParams = httpParams.append('commodityType', commodityType);
    }
    if (propertyState) {
      httpParams = httpParams.append('propertyState', propertyState);
    }
    return this.http.get<ItemListV4Model[]>(url, {params: httpParams}).pipe(take(1));
  }
}
