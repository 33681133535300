import {TX_SWITCH_OPTIONS} from '../../../../zen-market-pulse/_services/matrix-pricing-helper.service';

export enum LoadFactorLabelsMatrixRC {
  LOW = 'LOW', MEDIUM = 'MEDIUM', HIGH = 'HIGH', NONE = 'NONE'
}

export interface MatrixRateCheck {
    terms: number[];
    rateClassIds: number[];
    zone: string;
    loadFactor: string;
    totalKwhFee: number;
    properties: number[];
    startDate: string;
    effectivePriceDate: string;
    selected: string[];
    peakDemandKw: number;
    supplierRateCents: number;
    utilityRateCents: number;
    txSwitchData?: TxSwitchData;
    incumbentSupplierScenarioIds: string[];
}

export interface TxSwitchData {
  txSwitchOption: TX_SWITCH_OPTIONS;
  txSwitchDate: Date;
  txSwitchIsOnCycle?: boolean;
  txPriorityMoveIn?: boolean;
}
