import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {ZenRoutesEnum} from '../../../_enums/zen-routes.enum';
import {AuthenticationService} from './authentication.service';
import {CommodityType} from '../../_models/commodity';

@Injectable()
export class NavigationService {

  constructor(
    private router: Router,
    private authSvc: AuthenticationService
  ) {
  }

  executeNavigateTo(navigateTo: string): void {
    this.router.navigateByUrl(navigateTo);
  }

  isCustomerLevelForAdmin(): boolean {
    return window.location.href.includes('/customer/') && window.location.href.includes('/advisor/');
  }

  navigate(parts: any[]) {
    this.router.navigate(parts);
  }

  // Helper functions to get the right routes for Router to use

  toRouterLink(route: (string | number)[]): string {
    let link = '';
    route.forEach((part) => {
      link += `/${part}`;
    });
    return link;
  }

  // PORTFOLIO
  // Use case: this.router.navigate(this.navSvc.getPortfolioRoute());
  getBasePageRoute(): (string | number)[] {
    return [this.authSvc.getUserBaseRoute()]
  }

  getPortfolioPageRoute(): (string | number)[] {
    return [...this.getBasePageRoute(), 'portfolio'];
  }

  getCustomerDashboardPageRoute(): (string | number)[] {
    return [...this.getBasePageRoute(), 'dashboard'];
  }

  getPortfolioCustomerDetailsPageRoute(customerId: number): (string | number)[] {
    if (this.authSvc.isCustomer()) {
      return [...this.getPortfolioPageRoute()];
    } else {
      return [...this.getPortfolioPageRoute(), 'details', 'customer', customerId];
    }
  }

  getPortfolioLenDetailsPageRoute(customerId: number, lenId: string): (string | number)[] {
    return [...this.getPortfolioCustomerDetailsPageRoute(customerId), 'len', lenId];
  }

  getPortfolioPropertyDetailsPageRoute(customerId: number, propertyId: number): (string | number)[] {
    return [...this.getPortfolioCustomerDetailsPageRoute(customerId), 'property', propertyId];
  }

  getPortfolioMeterDetailsPageRoute(customerId: number, propertyId: number, meterId: number): (string | number)[] {
    return [...this.getPortfolioPropertyDetailsPageRoute(customerId, propertyId), 'meter', meterId];
  }

  // RATE CHECKS
  getRateCheckListPageRoute(): (string | number)[] {
    return [...this.getBasePageRoute(), 'rate-checks'];
  }

  getRateCheckRequestV2BaseRoute() {
    return [...this.getBasePageRoute(), 'request-v2'];
  }

  getRateCheckRequestV2PageRoute(customerId: number, rcId: string): (string | number)[] {
    return [...this.getBasePageRoute(), 'request-v2', 'customer', customerId, 'rate-checks', rcId];
  }

  // v2 RC request details page
  getRateCheckRequestV2StaticPageRoute(customerId: number, rcId: string, ) {
    return [...this.getBasePageRoute(), 'request-v2', 'customer', customerId, 'rate-checks', rcId, 'view-request'];
  }

  getRateCheckReportPageRoute(customerId: number, rcId: string) {
    if (this.authSvc.isCustomer()) {
      return [...this.getBasePageRoute(), 'rate-checks', rcId, 'report'];
    } else {
      return [...this.getBasePageRoute(), 'customer', customerId, 'rate-checks', rcId, 'report'];
    }
  }

  getRateCheckViewRequestPageRoute(commodityType: CommodityType, customerId: number, rcId: string): (string | number)[] {
    return [...this.getRateCheckListPageRoute(), 'request', commodityType === CommodityType.Electricity ? 'electricity' : 'natural-gas', 'view-request', customerId, rcId];
  }

  // CONTRACTS
  getContractListPageRoute(): (string | number)[] {
    return [...this.getBasePageRoute(), 'contracts'];
  }

  /**
   * Remember to add query params to router navigate!
   */
  getContractDetailsPageRoute(customerId: number, contractId: number): (string | number)[] {
    return [...this.getPortfolioCustomerDetailsPageRoute(customerId), 'contracts', contractId];
  }

  /** Used for customer contract details page link copying purpose used in ZenContractSharedHelperService.copyContractDetailsPageLink().
   * Reason: Regardless of the user role login we always need to copy customer route link. */
  getContractCustomerDetailsPageRoute(contractId: number): (string | number)[] {
    return [ZenRoutesEnum.CUSTOMER, 'portfolio', 'contracts', contractId];
  }

  // STACK RANKING
  getStackRankingPageRoute(): (string | number)[] {
    return [...this.getBasePageRoute(), 'stack-ranking'];
  }

  // MARKET PULSE
  getMarketPulsePageRoute(): (string | number)[] {
    return [...this.getBasePageRoute(), 'market-pulse'];
  }

  // PAYMENTS
  getPaymentsPageRoute(): (string | number)[] {
    return [...this.getBasePageRoute(), 'payments'];
  }

  // SETTINGS
  getSettingsPageRoute(): (string | number)[] {
    return [...this.getBasePageRoute(), 'settings'];
  }


  getOpportunitiesPageRoute(): (string | number)[] {
    return [...this.getBasePageRoute(), 'opportunities'];
  }

  getOpportunityDetailsPageRoute(customerId: number, opportunityId: number): (string | number)[] {
    return [...this.getOpportunitiesPageRoute(), 'customer', customerId, 'details', opportunityId];
  }
}
