import {PfChartInputModel, PfChartLegendModel} from '../../../../_shared/_components/zen-chart/zen-chart.component';
import {ZenColors} from '../../../../_shared/_enums/zen-colors.enum';
import {PortfolioContractCountdownModel, PortfolioContractMeterCount} from '../../_model/portfolio-summary.model';
import {Injectable} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {ContractMetrics, PfCustomersContentModel, PfCustomersTableRowModel} from '../../_model/portfolio-customers.model';
import {PfLensContentModel, PfLensTableRowModel} from '../../_model/portfolio-lens.model';
import {PfPropertiesContentModel, PfPropertiesTableRowModel} from '../../_model/portfolio-properties.model';
import {PfMetersContentModel, PfMetersTableRowModel} from '../../_model/portfolio-meters.model';
import {CommodityType} from '../../../../_shared/_zen-legacy-common/_models/commodity';
import {ZenIconsEnum} from '../../../../_shared/_enums/zen-icons.enum';
import {ZenUnitsEnum} from '../../../../_shared/_enums/zen-units.enum';
import {EntityTypeService} from '../../../../_shared/_zen-legacy-common/zen-common-services/_services/entity-type.service';
import {ZenNumberFormat} from '../../../../_shared/_enums/zen-number-format.enum';

@Injectable({
  providedIn: 'root'
})
export class PortfolioMapperService {

  constructor(private decimalPipe: DecimalPipe, private entityTypeSvc: EntityTypeService) {
  }

  /* Portfolio -> Meter Status -> Today, Future statuses app-zen-chart component data mapper. */
  mapChartInputModel(meterCount: PortfolioContractMeterCount): PfChartInputModel {
    // When you have data (at least 1 meter) show 0% for all widgets instead of N/A.
    const ifAtLeastOneMeterPresent = Boolean(Object.values(meterCount)?.some(mc => mc?.meterCount > 0));
    return {
      pluginData: {title: 'Contracted',
        value: ifAtLeastOneMeterPresent ? this.formatVal(meterCount.contracted?.percentage, '1.0-0') + '%' : null,
        valueFormat: (tooltipItem: PfChartLegendModel) => tooltipItem.subLabel ? this.formatVal(tooltipItem.value, '1.0-0') + '%' : 'N/A',
        titleFormat: (tooltipItem, subLabels) => this.pluginTitleFormatter(tooltipItem, subLabels)
      },
      legends: [
        {
          label: 'Contracted', subLabel: (meterCount.contracted?.meterCount),
          color: ZenColors.SUCCESS_2, value: meterCount.contracted?.percentage, disabled: false
        },
        {
          label: 'Expired', subLabel: (meterCount.expired?.meterCount),
          color: ZenColors.DANGER_1, value: meterCount.expired?.percentage, disabled: false
        },
        {
          label: 'On Utility', subLabel: (meterCount.onUtility?.meterCount),
          color: ZenColors.WARN_1, value: meterCount.onUtility?.percentage, disabled: false
        },
        {
          label: 'Undefined', subLabel: (meterCount.undefined?.meterCount),
          color: ZenColors.textColorLight_2, value: meterCount.undefined?.percentage, disabled: false
        }
      ]
    };
  }

  /* Portfolio -> Contract countdown -> app-zen-chart component data mapper. */
  contractCountdownChartMapper(res: PortfolioContractCountdownModel) {
    // When you have data (at least 1 meter) show 0% for all widgets instead of N/A.
    const ifAtLeastOneMeterPresent = Boolean(res.riskWindows && Object.values(res.riskWindows)?.some(mc => mc?.meterCount > 0));
    let {
      highRiskWindow: expired, betweenHighRiskAndMediumRiskWindow: expVerySoon,
      betweenMediumAndOutsideRiskWindow: expSoon, outsideRiskWindow: notExpiring,
      totalAtRisk
    } = res.riskWindows || this.emptyObjContractCount();
    return {
      pluginData: {title: 'At Risk', value: ifAtLeastOneMeterPresent ? this.formatVal(totalAtRisk?.percentage || 0, '1.0-0') + '%' : null,
        valueFormat: (tooltipItem: PfChartLegendModel) => tooltipItem.subLabel ? this.formatVal(tooltipItem.value, '1.0-0') + '%' : 'N/A',
        titleFormat: (tooltipItem, subLabels) => this.pluginTitleFormatter(tooltipItem, subLabels)},
      legends: [
        {label: 'Expired', subLabel: (expired?.meterCount), color: ZenColors.DANGER_1, value: expired?.percentage, disabled: false},
        {
          label: 'Expiring Soon', subLabel: (expVerySoon?.meterCount),
          color: ZenColors.DANGER_2, value: expVerySoon?.percentage, disabled: false
        },
        {label: 'Expiring', subLabel: (expSoon?.meterCount), color: ZenColors.DANGER_3, value: expSoon?.percentage, disabled: false},
        {
          label: 'Not Expiring', subLabel: (notExpiring?.meterCount),
          color: ZenColors.SUCCESS_2, value: notExpiring?.percentage, disabled: false
        }
      ]
    };
  }

  emptyObjContractCount() {
    return {
      highRiskWindow: null, betweenHighRiskAndMediumRiskWindow: null, outsideRiskWindow: null,
      betweenMediumAndOutsideRiskWindow: null, totalAtRisk: null
    };
  }

  /* Portfolio Tabs -> Customers table -> row data model mapper. */
  customerRowMappers(customers: PfCustomersContentModel[]): PfCustomersTableRowModel[] {
    return customers.map((c) => {
      return {
        ...c,
        imageSmallPath: c.imageSmallPath ?? this.entityTypeSvc.getDefaultTypeIconPath(c.type),
        activeRateCheckCountStr:
          c.customerMetrics.activeRateCheckCount ? `<span class="count">${c.customerMetrics.activeRateCheckCount}</span>` : null,
        electricityUsage: c.customerMetrics.meterCount === 0 ? '-' :
          this.decimalPipe.transform(c.customerMetrics?.electricityUsage, ZenNumberFormat.USAGE),
        natGasUsage: c.customerMetrics.meterCount === 0 ? '-' :
          this.decimalPipe.transform(c.customerMetrics?.natGasUsage, ZenNumberFormat.USAGE),
        lenCount: c.customerMetrics.lenCount,
        meterCount: c.customerMetrics.meterCount,
        propertyCount: c.customerMetrics.propertyCount,
        customerStatusCls: c.active ? 'active' : 'inactive',
        customerNameSubText: c.mloaSigned ? 'LOE/MLOA Signed' : 'Send LOE/MLOA',
        customerNameSubTextHyperlinked: !c.mloaSigned && c.active,
        ...this.contractMetricsModifier(c.customerMetrics.contractMetrics, c.customerMetrics.meterCount)
      } as PfCustomersTableRowModel;
    });
  }

  /* Portfolio Tabs -> LENs table -> row data model mapper. */
  lenRowMappers(lens: PfLensContentModel[]): PfLensTableRowModel[] {
    return lens.map((len: PfLensContentModel) => {
      return {
        ...len,
        imageSmallPath: len.imageSmallPath ?? this.entityTypeSvc.getDefaultLENIconPath(),
        activeRateCheckCountStr:
          len.lenMetrics.activeRateCheckCount ? `<span class="count">${len.lenMetrics.activeRateCheckCount}</span>` : null,
        electricityUsage: len.lenMetrics.meterCount === 0 ? '-' :
          this.decimalPipe.transform(len.lenMetrics.electricityUsage, ZenNumberFormat.USAGE),
        natGasUsage: len.lenMetrics.meterCount === 0 ? '-' :
          this.decimalPipe.transform(len.lenMetrics.natGasUsage, ZenNumberFormat.USAGE),
        propertyCount: len.lenMetrics.propertyCount,
        meterCount: len.lenMetrics.meterCount,
        lenStatusCls: len.active ? 'active' : 'inactive',
        ...this.contractMetricsModifier(len.lenMetrics.contractMetrics, len.lenMetrics.meterCount)
      } as PfLensTableRowModel;
    });
  }

  /* Portfolio Tabs -> Properties table -> row data model mapper. */
  propertyRowMappers(lens: PfPropertiesContentModel[]): PfPropertiesTableRowModel[] {
    return lens.map((property: PfPropertiesContentModel) => {
      return {
        ...property,
        imageSmallPath: property.imageSmallPath ?? this.entityTypeSvc.getDefaultTypeIconPath(property.type),
        activeRateCheckCountStr:
          property.propertyMetrics.activeRateCheckCount ? `<span class="count">${property.propertyMetrics.activeRateCheckCount}</span>` : null,
        meterCount: property.propertyMetrics.meterCount,
        electricityUsage: property.propertyMetrics.meterCount === 0 ? '-' :
          this.decimalPipe.transform(property.propertyMetrics.electricityUsage, ZenNumberFormat.USAGE),
        natGasUsage: property.propertyMetrics.meterCount === 0 ? '-' :
          this.decimalPipe.transform(property.propertyMetrics.natGasUsage, ZenNumberFormat.USAGE),
        propertyStatusCls: property.active ? 'active' : 'inactive',
        ...this.contractMetricsModifier(property.propertyMetrics.contractMetrics, property.propertyMetrics.meterCount)
      } as PfPropertiesTableRowModel;
    });
  }

  /* Portfolio Tabs -> Meters table -> row data model mapper. */
  meterRowMappers(lens: PfMetersContentModel[]): PfMetersTableRowModel[] {
    return lens.map((meter: PfMetersContentModel, index) => {
      let usage = `${this.formatVal(meter.projectedAnnualUsage)} ${meter.commodityType === CommodityType.Electricity ? ZenUnitsEnum.kWh : ZenUnitsEnum.Dth}`;
      meter.label = meter.label && meter.label.trim() !== '' ? meter.label : meter.utilityAccountNum1;
      return {
        ...meter,
        imageSmallPath: meter.imageSmallPath ?? this.entityTypeSvc.getDefaultMeterIconPath(),
        label: meter.label || meter.utilityAccountNum1,
        billCount: meter.meterMetrics.billCount,
        meterStatusCls: meter.active ? 'active' : 'inactive',
        activeRateCheckCountStr:
          meter.meterMetrics.activeRateCheckCount ? `<span class="count">${meter.meterMetrics.activeRateCheckCount}</span>` : null,
        usage: meter.projectedAnnualUsage !== null ? usage : '-',
        usageType: meter.commodityType === CommodityType.Electricity ? ZenIconsEnum.ELECTRIC : ZenIconsEnum.NAT_GAS,
        usageTypeCls: meter.commodityType === CommodityType.Electricity ? 'electric' : 'nat-gas',
        procurementSnapshot: {
          timelines: meter.meterMetrics.energyPlanTimelines.map(x => {
            x.customerId = meter.customerId;
            x.commodityType = meter.commodityType;
            x.state = meter.propertyState;
            x.meterId = meter.id;
            x.propertyId = meter.propertyId;
            x.lenId = meter.lenId;
            return x;
          }),
        }
      } as PfMetersTableRowModel;
    });
  }

  /* This method is used to modify contractMetrics for LEN, property and customer */
  contractMetricsModifier(contractMetrics: ContractMetrics, meterCount: number) {
    // Show dashes if there are no meters, because we are using 0 as a denominator
    // Updated Today and Future % to match API response ( - to 0)
    const {atRisk, today, future} = contractMetrics || {};
    return {
      atRiskPercentage: (atRisk == null || meterCount === 0) ? '-' : (atRisk?.percentage > 0 || meterCount > 0) ? this.formatVal(atRisk?.percentage) + '%' : '-',
      atRiskTextCls: (atRisk?.percentage > 0 && meterCount > 0) ? 'font-weight-bold danger_1' : '',
      todayPercentage: today?.percentage >= 0 ? this.formatVal(today?.percentage) + '%' : '-',
      todaySubText: (today?.percentage > 0 && meterCount > 0) ? 'Contracted' : '',
      futurePercentage: future?.percentage >= 0 ? this.formatVal(future?.percentage) + '%' : '-',
      futureSubText: (future?.percentage > 0 && meterCount > 0) ? 'Contracted' : '',
    }
  }

  formatVal = (val, format = '1.0-0') => this.decimalPipe.transform(val, format);

  /* Zen-chart -> (PfChartInputModel.titleFormat) tooltip formatter - the chart.js tooltip format method return this method. */
  pluginTitleFormatter(tooltipItem, subLabels: (string | number)[]): string {
    return `${tooltipItem.subLabel || 0} Meter${tooltipItem.subLabel !== 1 ? 's' : ''}`;
  }
}
