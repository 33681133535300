<ng-container *ngIf="feeDetails && rateCheckData?.rateCheckId && rateCheckData?.commodity">

  <ng-container *ngIf="orgMgtSvc.organization?.settings?.featureEnhancedFeeSelector else defaultFeeBreakdownTpl">
    <i class="material-icons mat-form-field-info-icon large"
       [popper]="feeBreakdownCalcPopper" popperTrigger="click">{{ZenIcons.FEE_BREAKDOWN_CALC}}</i>
    <popper-content #feeBreakdownCalcPopper>
      <!-- *ngIf="feeBreakdownCalcPopper.state" - flag is used to init/destroy the app-zen-total-fee-breakdown component
       from the template. Which used to recalculate the fees. -->
      <app-zen-total-fee-breakdown *ngIf="feeBreakdownCalcPopper.state"
                                   [rateCheckData]="rateCheckData"
                                   [popperElement]="feeBreakdownCalcPopper"
                                   (onSubmit)="onSubmit.emit($event)"></app-zen-total-fee-breakdown>
    </popper-content>
  </ng-container>

  <ng-template #defaultFeeBreakdownTpl>
    <i class="material-icons mat-form-field-info-icon ms-3"
       [popper]="feeBreakdownPopper" popperTrigger="click" popperApplyClass="min-xs">{{ZenIcons.FEE_BREAKDOWN}}</i>

    <popper-content #feeBreakdownPopper>
      <div class="p-3">
        <h4>Total Fee Breakdown</h4>
        <div class="math-fees">
          <div class="fee-row">
            <div class="sign-space"></div>
            <div *ngIf="!isElectric" class="fee-value">
              {{ feeDetails.brokerFee | currency: _translations?.currency?.abbreviation : 'symbol-narrow' : ZenNumberFormat.COST }}</div>
            <div *ngIf="isElectric"
                 class="fee-value">{{ feeDetails.brokerFee | number: ZenNumberFormat.COST }}
              mils
            </div>
            <div class="fee-label">Your Fee</div>
          </div>
          <div class="fee-row">
            <div class="sign-space">+</div>
            <div *ngIf="!isElectric" class="fee-value">
              {{ feeDetails.platformFee | currency: _translations?.currency?.abbreviation : 'symbol-narrow' : ZenNumberFormat.COST }}</div>
            <div *ngIf="isElectric"
                 class="fee-value">{{ feeDetails.platformFee | number: ZenNumberFormat.COST }}
              mils
            </div>
            <div class="fee-label">Platform Fee</div>
          </div>
          <div class="math-separator"></div>
          <div class="fee-row">
            <div class="sign-space"></div>
            <div *ngIf="!isElectric" class="fee-value">{{ feeDetails.totalFee | currency: _translations?.currency?.abbreviation : 'symbol-narrow' : ZenNumberFormat.COST }}</div>
            <div *ngIf="isElectric" class="fee-value">{{ feeDetails.totalFee | number: ZenNumberFormat.COST }} mils</div>
            <div class="fee-label">Total Fee</div>
          </div>
        </div>
      </div>
    </popper-content>

  </ng-template>
</ng-container>
