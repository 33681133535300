import {RateCheckStatusEnum, RateCheckStatusLabels} from '../../../_shared/_zen-legacy-common/_models/rate-checks/rate-check-status';
import {RcStatusCodeClass} from '../../../_shared/_zen-legacy-common/_models/rate-checks/rate-check-status-code-class.enum';
import {CommodityType} from '../../../_shared/_zen-legacy-common/_models/commodity';
import { MenuOptions } from '../../../_shared/_zen-legacy-common/_models/rc-v3-menu.model';
import {TxSwitchData} from '../../zen-reports/_modules/zen-rate-check-report/_models/matrix-rate-check.interface';
import {ReportTabs} from '../../../_shared/_model/custom/rcr-toggle.model';
import {ZenRcReqRenewableOptionsEnum} from '../../zen-rate-check-request-v2/_enums/rc-req-electricity.enum';
import {LenRcName, RateCheckVersion} from '../../../_shared/_model/rate-check-v4.model';
import {ZenBillingMethodEnum, ZenNatGasPaymentTermEnum} from '../../zen-rate-check-request-v2/_enums/rc-req-natural-gas.enum';

export interface RateCheckV3Model {
  advisorMessageToCustomer: string;
  id: string;
  shortId: string;
  organizationId: string;
  customerId: number;
  statusCode: RateCheckStatusEnum;
  rateCheckCoreStatusCode: RateCheckStatusEnum;
  energyPlanId: number;
  timeQuoteDue?: Date | string; // After removing old UI make this as Date
  usingMLOA?: boolean;
  hasReport?: boolean;
  bulkUploadFilePath?: string;
  billAttestDate: string;
  state: string;
  showMedianRate: boolean;
  showRateHistory: boolean;
  propertyAccountAttestDate: string;
  customerContactId?: string;
  internalStatus: string; // Admin status
  createdAt: string;
  customerName: string;
  friendlyStatus: RateCheckStatusLabels;
  limitBeforePushingOneMonth: number;
  projectedArr: number;
  propertyCount: number;
  tiliBrokerFee?: number
  updateDT: string;
  utilities: string[];
  utilityIds: string[];
  lens: LenRcName[];
  lensNames: string;
  refreshable: boolean;
  // coreId: string;
  brokerFee?: number;
  platformFee?: number;
  mloaFilePath?: string;
  loeFilePath?: string;
  startDate?: string;
  earliestStartDate?: string;
  orgInfo?: string;
  totalAnnualUsage?: number;
  contractStart: string;
  hasAbortedContract: boolean;
  maxAuditStatus: RateCheckStatusEnum
  rateCheckCoreStatus: RateCheckStatusEnum;
  reviewerContactIds: string[];
  staggeredStart: boolean;
  productModel: GasProductModel;
  /** Only for UI dev **/
  basisFixedText?: string;
  commodityFixedText?: string;
  carbonOffsetPricingText?: string;
  swingText?: string;
  statusCodeClass?: RcStatusCodeClass;
  description?: string;
  contractTerms?: string;
  totalFee?: string;
  abortedBySystem: boolean;
  /** For the tree-table purpose **/
  showMenu?: boolean;
  rateCheckMenuItems?: MenuOptions[];
  rcVersion: RateCheckVersion; // Default V2, for the new RCF is V4
  excludedObjects?: string[]; // To hide column -> td values.
  hideChildRow?: boolean; // To hide child toggle btn
  tooltip?: string; // If type === money and need to show tooltip. Eg. Advisor RC list and NatGas RC list, Proj ARR column.
  nymexDate: Date;
  commodityType: CommodityType
  isMatrixPricing: boolean;
  marketCheckReportEnabled: boolean;
  startingTerms?: number[];
  anonymizeSuppliers: boolean;
  txMatrixSwitchData: TxSwitchData;
  specialRequirements?: string;
  hiddenReportTabs: ReportTabs[]; // number[];
}

export interface GasProductModel {
  swing?: number;
  contractTerms?: number[];
  paymentTerms?: ZenNatGasPaymentTermEnum;
  billingMethod?: ZenBillingMethodEnum;
  totalFee?: number;
  basisFixed?: boolean;
  commodityFixed?: boolean;
  basisPricingLocation?: BasisPricingLocation;
  carbonOffsetPricing?: boolean;
  renewable?: ZenRcReqRenewableOptionsEnum
  requirements?: string;
}

export enum BasisPricingLocation {
  BURNER_TIP = 'burner tip',
  CITY_GATE = 'city gate'
}

export enum BasisPricingLocationLabels {
  BURNER_TIP = 'Burner Tip',
  CITY_GATE = 'City Gate'
}

export interface DateValidations {
  minDate: Date;
  maxDate: Date;
  invalidDates: Date[];
}

export interface RefreshPricingModel {
  status: RateCheckStatusEnum;
  friendlyStatusName: RateCheckStatusLabels;
  requestDueDate: Date;
}
