import {Injectable} from '@angular/core';
import {environment} from '../../../../../../../environments';
import {HttpClient} from '@angular/common/http';
import {OrganizationManagementService} from './organization-management.service';
import {combineLatest, Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {CustomerAndPropertyType, PropertySubType} from '../../_enums/entity-types.enum';

@Injectable({
  providedIn: 'root'
})
export class EntityTypeService {
  public static CUSTOMER_AND_PROPERTY_TYPES: CustomerAndPropertyType[];
  public static PROPERTY_TYPE_TO_SUBTYPE_MAP: Map<CustomerAndPropertyType, PropertySubType[]>;
  private baseUrl = environment.apiBaseUrl;

  private THUMBNAIL_PATH = '/assets/img/img-thumbnail.svg';

  constructor(private http: HttpClient,
              private orgMgtSvc: OrganizationManagementService) {
  }

  populateEntityTypes() {
    combineLatest([this.getCustomerAndPropertyTypes(), this.getPropertyTypeToSubtypeMap()])
      .subscribe(([customerAndPropertyTypes, propertyTypeToSubtypeMap]) => {
        // Populate customer/property types sorted
        EntityTypeService.CUSTOMER_AND_PROPERTY_TYPES = customerAndPropertyTypes.sort((a, b) => a.localeCompare(b));
        // Populate property type to subtype map, sort by key and sort subtype lists by value
        EntityTypeService.PROPERTY_TYPE_TO_SUBTYPE_MAP = new Map<CustomerAndPropertyType, PropertySubType[]>();
        Object.keys(propertyTypeToSubtypeMap).sort().forEach(key => {
          EntityTypeService.PROPERTY_TYPE_TO_SUBTYPE_MAP.set(key as CustomerAndPropertyType, propertyTypeToSubtypeMap[key].sort((a, b) => a.localeCompare(b)));
        });
      });
  }

  private getCustomerAndPropertyTypes(): Observable<CustomerAndPropertyType[]> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/customer-types`;
    return this.http.get<CustomerAndPropertyType[]>(url).pipe(take(1));
  }

  private getPropertyTypeToSubtypeMap(): Observable<Map<CustomerAndPropertyType, PropertySubType[]>> {
    const url = `${this.baseUrl}/v4/organizations/${this.orgMgtSvc.getOrganizationId()}/property-subtypes`;
    return this.http.get<Map<CustomerAndPropertyType, PropertySubType[]>>(url).pipe(take(1));
  }

  public getDefaultTypeIconPath(type: CustomerAndPropertyType): string {
    if (type == null) {
      return this.THUMBNAIL_PATH;
    }
    let iconPath = 'assets/img/types/';
    // Based on enum name, get the icon path
    switch (type) {
      case CustomerAndPropertyType.GOVERNMENT:
        iconPath += 'government';
        break;
      case CustomerAndPropertyType.EDUCATION:
        iconPath += 'education';
        break;
      case CustomerAndPropertyType.NON_PROFIT:
        iconPath += 'non-profit';
        break;
      case CustomerAndPropertyType.HEALTHCARE:
        iconPath += 'healthcare';
        break;
      case CustomerAndPropertyType.REAL_ESTATE:
        iconPath += 'real-estate';
        break;
      case CustomerAndPropertyType.HOSPITALITY:
        iconPath += 'hospitality';
        break;
      case CustomerAndPropertyType.FINANCIAL:
        iconPath += 'financial';
        break;
      case CustomerAndPropertyType.RETAIL:
        iconPath += 'retail';
        break;
      case CustomerAndPropertyType.MANUFACTURING:
        iconPath += 'manufacturing';
        break;
      case CustomerAndPropertyType.TRANSPORTATION:
        iconPath += 'transportation';
        break;
      case CustomerAndPropertyType.MEDIA_ENTERTAINMENT:
        iconPath += 'media-entertainment';
        break;
      case CustomerAndPropertyType.TECHNOLOGY:
        iconPath += 'technology';
        break;
      case CustomerAndPropertyType.OTHER:
        iconPath += 'other';
        break;
    }
    iconPath += '.svg';
    return iconPath;
  }
  public getDefaultLENIconPath(): string {
    return 'assets/img/types/len.svg';
  }
  public getDefaultMeterIconPath(): string {
    return 'assets/img/types/meter.svg';
  }
}
