<app-zen-dialog-wrapper [data]="data" (onSubmit)="submit($event)" [errorMsg]="errorMsg" [disableButton]="disableButton">
  <div *ngIf="form" class="d-flex flex-wrap" [formGroup]="form">
    <div class="col-12 col-md-6 px-4">
      <!-- Associated with section - is hidden if customerId, lenId and propertyId known. -->
      <div class="dialog-form-sec with-border" [hidden]="lockedCustomerId && lockedLenId && lockedPropertyId">
        <div class="d-flex justify-content-center">
          <div class="w-100">

            <div class="row mb-3">
              <div class="col-12 d-flex align-items-center justify-content-between">
                <p class="form-title mb-0">Associated with...</p>
                <button *ngIf="propertyCtrl.value" class="filter-button d-flex align-items-center p-0"
                        (click)="toggleShowMore()">
                  <span class="small">{{showMore ? 'Show less' : 'Show more'}}</span>
                  <i class="material-icons small ms-1">{{showMore ? 'chevron_right' : 'expand_more'}}</i>
                </button>
              </div>
            </div>

            <!-- Customer form -->
            <div class="row text-start">
              <div class="col-12 form-group" *ngIf="showMore else lockedCustTpl">
                <mat-form-field [appearance]="appearance" [class]="lockedCustomerId ? 'locked' : ''">
                  <mat-label>{{authSvc.customerNameLabel}}*</mat-label>
                  <input *ngIf="lockedCustomerId" matInput [value]="getLockedCustomer?.value"
                         [readonly]="lockedCustomerId">
                  <ng-container *ngIf="!lockedCustomerId">
                    <input type="text"
                           matInput
                           [formControl]="customerCtrl"
                           [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                      <mat-option *ngFor="let option of filteredCustomers | async" [value]="option">
                        {{option.value}}
                      </mat-option>
                    </mat-autocomplete>
                  </ng-container>
                  <i class="material-symbols-rounded" matSuffix>expand_more</i>
                  <mat-error *ngIf="customerCtrl.touched && customerCtrl.hasError('validoption')">Invalid Customer*</mat-error>
                  <mat-error *ngIf="customerCtrl.touched && customerCtrl.hasError('required')">Required Field*</mat-error>
                </mat-form-field>
              </div>

              <ng-template #lockedCustTpl>
                <div class="locked-input-value">
                  <div class="d-flex align-items-center">
                    <p class="m-0 d-flex align-items-center text-md">
                      <i class="material-symbols-rounded ms-1">{{ZenIcons.CUSTOMER}}</i>
                      <span class="ms-2">{{getLockedCustomer?.value}}</span>
                    </p>
                  </div>
                </div>
              </ng-template>
            </div>

            <ng-container *ngIf="customerCtrl.valid && showMore">
              <!-- LEN Search -->
              <div class="row text-start">
                <div class="col-12 form-group">
                  <mat-form-field [appearance]="appearance" [class]="lockedLenId?'locked':''">
                    <mat-label>Legal Entity Name*</mat-label>
                    <input *ngIf="lockedLenId" matInput [value]="getLockedLen?.value" [readonly]="lockedLenId">
                    <ng-container *ngIf="!lockedLenId">
                      <input type="text" matInput [formControl]="lenCtrl" [matAutocomplete]="auto">
                      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let option of filteredLens | async" [value]="option">
                          {{option.value}}
                        </mat-option>
                      </mat-autocomplete>
                    </ng-container>
                    <i class="material-symbols-rounded" matSuffix>expand_more</i>
                    <mat-error *ngIf="lenCtrl.touched && lenCtrl.hasError('validoption')">Invalid LEN*</mat-error>
                    <mat-error *ngIf="lenCtrl.touched && lenCtrl.hasError('required')">Required Field*</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <!-- Property Search -->
              <ng-container *ngIf="lenCtrl.valid">
                <div class="row text-start">
                  <div class="col-12 form-group">
                    <mat-form-field [appearance]="appearance" [class]="lockedPropertyId?'locked':''">
                      <mat-label>Property Address*</mat-label>
                      <input *ngIf="lockedPropertyId" matInput [value]="getLockedProperty?.value"
                             [readonly]="lockedPropertyId">
                      <ng-container *ngIf="!lockedPropertyId">
                        <input type="text" matInput
                               [formControl]="propertyCtrl" [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                          <mat-option *ngFor="let option of filteredProperties | async" [value]="option"
                                      [class]="(propertyCtrl.value?.key === option.key) ? 'mdc-list-item--selected' : ''">
                            {{option.value}}
                          </mat-option>
                        </mat-autocomplete>
                      </ng-container>
                      <i class="material-symbols-rounded" matSuffix>expand_more</i>
                      <mat-error *ngIf="propertyCtrl.touched && propertyCtrl.hasError('validoption')">Invalid Property*</mat-error>
                      <mat-error *ngIf="propertyCtrl.touched && propertyCtrl.hasError('required')">Required Field*</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </ng-container>
            </ng-container>

          </div>
        </div>
      </div>

      <div class="dialog-form-sec with-border">
        <div class="zen-mat-form zen-customer-contact-form">
          <div class="d-flex justify-content-center">
            <div class="w-100">

              <div class="row mb-3">
                <div class="col-12 d-flex align-items-center justify-content-between">
                  <p class="form-title mb-0">Meter Details
                    <i class="material-icons-outlined help-outline ms-2"
                       [popper]="meterDetailsInfoPopper"
                       [popperHideOnScroll]="true"
                       [popperHideOnClickOutside]="true"
                       popperTrigger="click"
                       popperApplyClass="sm"
                       popperPlacement="bottom">info</i>
                  </p>
                </div>
              </div>

              <!-- LEN details form -->
              <div class="row mt-1 text-start">
                <div class="col-12 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Meter Name</mat-label>
                    <input matInput formControlName="label">
                  </mat-form-field>
                </div>
                <div class="col-12 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Service Address 1*</mat-label>
                    <input matInput formControlName="propertyStreet1">
                    <mat-error *ngIf="hasError('propertyStreet1', 'required')">Required Field*
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-6 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Service Address 2</mat-label>
                    <input matInput formControlName="street2">
                  </mat-form-field>
                </div>
                <div class="col-6 col-md-6 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>City*</mat-label>
                    <input matInput formControlName="propertyCity">
                    <mat-error *ngIf="hasError('propertyCity', 'required')">Required Field*
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-6 col-md-6 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>{{'nomenclature.state' | translate}}*</mat-label>
                    <input matInput [formControl]="controls.propertyState" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" [panelWidth]="100" [autoActiveFirstOption]="true">
                      <mat-option *ngFor="let option of filteredStateOptions | async" [value]="option">
                        {{option}}
                      </mat-option>
                    </mat-autocomplete>
                    <i class="material-symbols-rounded" matSuffix>expand_more</i>
                    <mat-error *ngIf="hasError('propertyState', 'required')">Required Field*
                    </mat-error>
                    <mat-error *ngIf="hasError('propertyState', 'validoption')">Invalid {{'nomenclature.state' | translate}}*
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-6 col-md-6 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Zip*</mat-label>
                    <input matInput formControlName="propertyZip" [mask]="i18nMask.zip">
                    <mat-error *ngIf="hasError('propertyZip', 'required')">Required Field*
                    </mat-error>
                    <mat-error *ngIf="hasError('propertyZip', 'pattern')">Invalid Format*</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 form-group" *ngIf="showMeterType">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Meter Type*</mat-label>
                    <mat-select formControlName="type">
                      <mat-option *ngFor="let opt of meterTypeOptions" [value]="opt" class="py-2">{{opt}}</mat-option>
                    </mat-select>
                    <i class="material-symbols-rounded" matSuffix>expand_more</i>
                    <mat-error *ngIf="hasError('type', 'required')">Required Field*</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Activation Status*</mat-label>
                    <mat-select (selectionChange)="handleSelectionChange($event)" formControlName="active">
                      <mat-option [value]="true" class="py-2">Active</mat-option>
                      <mat-option [value]="false" class="py-2">Inactive</mat-option>
                    </mat-select>
                    <mat-hint *ngIf="disableActivationStatus" class="text-color mt-2">
                      Parent must be active in order to activate.</mat-hint>
                    <mat-hint *ngIf="activatedMeter" class="text-color mt-2">
                      You are about to <b>reactivate</b> this meter.</mat-hint>
                    <mat-hint *ngIf="deactivatedMeter" class="text-color mt-0">
                      You are about to <b>deactivate</b> this meter which will make it ineligible for procurement.</mat-hint>
                    <i class="material-symbols-rounded" matSuffix>expand_more</i>
                    <mat-error *ngIf="hasError('active', 'required')">Required Field*</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 px-4">
      <div class="dialog-form-sec with-border">
        <div class="zen-mat-form zen-customer-contact-form">
          <div class="d-flex justify-content-center">
            <div class="w-100">

              <div class="row mb-3">
                <div class="col-12 d-flex align-items-center">
                  <p class="form-title mb-0">Service Details</p>
                  <i class="material-icons-outlined help-outline ms-2"
                     [popper]="serviceDetailsInfoPopper"
                     [popperHideOnScroll]="true"
                     [popperHideOnClickOutside]="true"
                     popperTrigger="click"
                     popperApplyClass="sm"
                     [popperPlacement]="popperPlacements.AUTOSTART">info</i>
                </div>
              </div>

              <!-- Tax form -->
              <div class="row mt-1 text-start">
                <div class="col-12 form-group">
                  <mat-form-field [appearance]="appearance">
                    <mat-label>Service*</mat-label>
                    <mat-select formControlName="commodityType">
                      <mat-option *ngFor="let opt of serviceOptions" [value]="opt.value"
                                  class="py-2" (click)="clearUtilityInformation()">{{opt.label}}</mat-option>
                    </mat-select>
                    <i class="material-symbols-rounded" matSuffix>expand_more</i>
                    <mat-error *ngIf="hasError('commodityType', 'required')">Required Field*
                    </mat-error>
                  </mat-form-field>
                </div>

                <ng-container *ngIf="controls.commodityType.value">
                  <div class="col-12 form-group">
                    <mat-form-field [appearance]="appearance">
                      <mat-label>
                        {{controls.commodityType.value === CommodityType.Gas ? 'Gas' : 'Electric'}}
                        {{'nomenclature.utilityShort' | translate}}
                        {{utilities.length ? '*' : ''}}
                      </mat-label>
                      <mat-select formControlName="utilityId">
                        <mat-option *ngFor="let u of utilities" [value]="u.utilityId"
                                    (click)="handleUtilityChange()">{{u.utilityName}}</mat-option>
                      </mat-select>
                      <i class="material-symbols-rounded" matSuffix>expand_more</i>
                      <mat-error *ngIf="hasError('utilityId', 'required')">Required Field*
                      </mat-error>
                    </mat-form-field>
                  </div>
                </ng-container>

                <ng-container *ngIf="controls.utilityId.value">

                  <!-- Zone field is Hidden for Natural Gas Meters. -->
                  <div *ngIf="form.controls.zoneId && STATES_WITH_ZONE.includes(propertyDet?.state) && controls.commodityType.value === CommodityType.Electricity" class="col-12 form-group">
                    <mat-form-field appearance="outline">
                      <mat-label>Zone</mat-label>
                      <mat-select formControlName="zoneId" [disabled]="isZoneSelectorDisabled">
                        <mat-option *ngFor="let z of zoneOptions" [value]="z.value">
                          {{z.label}}
                        </mat-option>
                      </mat-select>
                      <i class="material-symbols-rounded" matSuffix>expand_more</i>
                    </mat-form-field>
                  </div>

                  <div class="col-12 uan-1" [ngClass]="getUanStyleClasses(uan2, 'utilityAccountNum1',  'mb-0')">
                    <mat-form-field [appearance]="appearance">
                      <mat-label>{{uan1?.labelShort || (_translations?.nomenclature?.utilityShort + ' Account Number')}}*</mat-label>
                      <input matInput formControlName="utilityAccountNum1" (paste)="handlePaste($event, 'utilityAccountNum1')"
                             [placeholder]="uan1?.placeholder" [imask]="uan1Mask">
                    </mat-form-field>
                    <mat-error *ngIf="hasError('utilityAccountNum1', 'required')">Required Field*
                    </mat-error>
                    <mat-error
                      *ngIf="hasError('utilityAccountNum1', 'pattern')">{{uan1ErrorMessage}}</mat-error>
                  </div>
                  <div *ngIf="uan2" class="col-12 uan-2" [ngClass]="getUanStyleClasses(uan3, 'utilityAccountNum2', 'mb-2')" >
                    <mat-form-field [appearance]="appearance">
                      <mat-label>{{uan2.labelShort || 'UAN 2'}}{{uan2.required ? '*' : ''}}</mat-label>
                      <input matInput formControlName="utilityAccountNum2" (paste)="handlePaste($event, 'utilityAccountNum2')"
                             [placeholder]="uan2?.placeholder" [imask]="uan2Mask">
                    </mat-form-field>
                    <mat-error *ngIf="hasError('utilityAccountNum2', 'required')">Required Field*
                    </mat-error>
                    <mat-error
                      *ngIf="hasError('utilityAccountNum2', 'pattern')">{{uan2ErrorMessage}}</mat-error>
                  </div>
                  <div *ngIf="uan3" class="col-12 uan-3" [ngClass]="hasError('utilityAccountNum1', 'pattern') ? 'remove-trailing-uan-space' : 'form-group'">
                    <mat-form-field [appearance]="appearance">
                      <mat-label>{{uan3.labelShort || 'UAN 3'}}{{uan3.required ? '*' : ''}}</mat-label>
                      <input matInput formControlName="utilityAccountNum3" (paste)="handlePaste($event, 'utilityAccountNum3')"
                             [placeholder]="uan3?.placeholder" [imask]="uan3Mask">
                    </mat-form-field>
                    <mat-error *ngIf="hasError('utilityAccountNum3', 'required')">Required Field*
                    </mat-error>
                    <mat-error
                      *ngIf="hasError('utilityAccountNum3', 'pattern')">{{uan3ErrorMessage}}</mat-error>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dialog-form-sec with-border">
        <div class="zen-mat-form">
          <app-zen-tags
            [type]="TagType.METER"
            [tagFilterCtrl]="tagFilterCtrl"
            [tagOptions]="tagOptions"
            [selectedTags]="selectedTags"
            (onAddTag)="handleAddTag($event)"
            (onRemoveTag)="handleRemoveTag($event)"></app-zen-tags>
        </div>
      </div>
    </div>
  </div>
</app-zen-dialog-wrapper>

<popper-content #saveLenPopper>
  <app-zen-popper title="Saving as Legal Entity Name" [closeable]="true" [popperContent]="saveLenPopper">
    <p class="medium">
      Larger customers require a more generic {{authSvc.customerNameLabel}} to act as an umbrella for each underlying Legal Entity Name
      and/or Property while smaller customers, with a single property, often save the {{authSvc.customerNameLabel}} as the Legal Entity
      Name.
    </p>
  </app-zen-popper>
</popper-content>

<popper-content #meterDetailsInfoPopper>
  <app-zen-popper [closeable]="true" [popperContent]="meterDetailsInfoPopper">
    <p class="font-weight-600 mb-1">Meter Name</p>
    <p class="medium mb-4">
      Define a name to easily identify meters at a property. When added, this name will override the primary utility
      account number which serves as a default meter name.
    </p>
    <p class="font-weight-600 mb-1">Service Address</p>
    <p class="medium mb-4">
      The meter’s service address is defined at the property level with the exception of address 2.
    </p>
    <p class="font-weight-600 mb-1">Meter Type</p>
    <p class="medium mb-4">
      Further classify meters at multi-family properties as either “units” or “common areas”. The field will be
      present if the property type is multi-family.
    </p>
    <p class="font-weight-600 mb-1">Activation Status</p>
    <p class="medium mb-4">
      Organize your portfolio with an Activation Status. Records set a status of inactive will be ineligible for
      procurement and hidden from view.
    </p>
  </app-zen-popper>
</popper-content>

<popper-content #serviceDetailsInfoPopper>
  <app-zen-popper [closeable]="true" [popperContent]="serviceDetailsInfoPopper">
    <div [class.zen-popper-scroll-wrapper]="form.controls.zoneId && STATES_WITH_ZONE.includes(propertyDet?.state)">
      <p class="font-weight-600 mb-1">Service</p>
      <p class="medium mb-4">
        The service pertains to the specific type of utility service (commodity) that is supplied to the meter.
      </p>
      <p class="font-weight-600 mb-1">{{'nomenclature.utilityShort' | translate}}</p>
      <p class="medium mb-4">
        The {{'nomenclature.utilityShort' | translate}} is responsible for overseeing the distribution (or delivery) of the service to the end consumer.
        The {{'nomenclature.utilityShort' | translate}} will be referenced on the {{'nomenclature.utilityShort' | translate}} bill but please note that you may be looking at a supplier bill in
        the case of dual billing.
        In both instances, the utility should be listed on the bill.
      </p>
      <p class="font-weight-600 mb-1">{{'nomenclature.utilityShort' | translate}} Account Number</p>
      <p class="medium mb-0">
        A {{'nomenclature.utilityShort' | translate}} Account Number (UAN) is a unique identifier that utilities assign to each metered account.
        Suppliers use the UAN to request historical usage which is run through their pricing models.
        Different utilities may call this number by different names, such as Choice ID, POD ID, ESIID, or Customer
        Number.
        Your platform will prompt you with the correct name for your utility, as well as any other information that is
        required to procure within that utility's footprint.
      </p>
      <ng-container *ngIf="form.controls.zoneId && STATES_WITH_ZONE.includes(propertyDet?.state)">
        <p class="font-weight-600 mt-4 mb-1"><b>Zone</b></p>
        <p class="medium mb-0">
          Zones are established by some Independent System Operators (ISOs) to define specific load territories, each
          with its own demand profile and location-specific pricing. Matrix {{'nomenclature.suppliers' | translate}} price based on zone in New York,
          Texas, and
          Massachusetts, making this a requirement for matrix contracting.
        </p>
      </ng-container>
    </div>
  </app-zen-popper>
</popper-content>
