<!-- Adding this app-layout component to support base font-size(62.5% -> 1px = 0.1rem) -->
<app-layout></app-layout>

<div class="zen-layout-v2">
  <ng-container *ngIf="!(data && tableConfig)">
    <h3 class="sub-header-bg">SNAPSHOT - WIDGET </h3>
    <div class="story-zen-widgets card-container-wrapper">
      <div id="card-snapshot" class="card-container card-snapshot w-100">
        <div class="card-title-main">
          <div class="card-title-wrapper">
            <h2>Snapshot</h2>
            <i class="material-icons-outlined help-outline ms-2"
               [popper]="snapchatPopper" [popperHideOnScroll]="true" [popperHideOnClickOutside]="true"
               popperTrigger="click" popperApplyClass="sm" popperPlacement="right">info</i>
          </div>
        </div>
        <app-zen-snapshot [data]="snapshotData"></app-zen-snapshot>
      </div>

      <div class="card-container w-100">
        <div class="card-title-main">
          <div class="card-title-wrapper">
            <h2>Snapshot Error</h2>
          </div>
        </div>
        <app-zen-snapshot [error]="true"></app-zen-snapshot>
      </div>

      <div class="card-container card-snapshot w-100">
        <div class="card-title-main mb-2"></div>
        <app-zen-snapshot [loading]="true"></app-zen-snapshot>
      </div>
    </div>

    <h3 class="sub-header-bg mt-5">ZEN CHART</h3>
    <div class="story-zen-widgets card-container-wrapper">
      <div class="card-container card-snapshot w-100 pb-5">
        <div class="card-title-main">
          <div class="card-title-wrapper">
            <h2>Chart</h2>
          </div>
        </div>
        <app-zen-chart [chartData]="chartData"></app-zen-chart>
      </div>
      <div class="card-container card-snapshot w-100">
        <div class="card-title-main">
          <div class="card-title-wrapper">
            <h2>Chart Empty</h2>
          </div>
        </div>
        <app-zen-chart [chartData]="chartDataEmpty"></app-zen-chart>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="data && tableConfig">
    <h3 class="sub-header-bg mt-5">ZEN CHART</h3>
    <div class="story-zen-widgets card-container-wrapper">
      <div class="card-container card-snapshot w-100">
        <app-zen-mat-table [data]="data" [tableConfig]="tableConfig"></app-zen-mat-table>
      </div>
    </div>
  </ng-container>
</div>


<popper-content #snapchatPopper>
  <app-zen-popper title="Snapshot" [closeable]="true" [popperContent]="snapchatPopper">
    <p class="medium">
      This widget presents a summary count of all active Customers, Legal Entity Names, Properties, and Meters.
      By default, this count includes customers with properties in both regulated and deregulated commodity markets.
      To view and manage inactive records, click + Filter at the top of this page and flip the “Activation Status” to “inactive”.
    </p>
  </app-zen-popper>
</popper-content>
