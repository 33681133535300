<app-zen-skeleton-loader *ngIf="loading" [loaderType]="ZenLoaderTypes.SNAP_SHOT"></app-zen-skeleton-loader>
<app-zen-error *ngIf="!loading && error"></app-zen-error>
<div class="snapshot-main" *ngIf="!loading && !error">
  <div class="top-sec">
    <div class="col-6 col-md-8 p-0 pe-3">
      <div class="snap-card">
        <i class="material-symbols-rounded icon-main">{{ZenIcons.CUSTOMER}}</i>
        <div class="d-flex flex-column">
          <h2 class="large">{{data?.customerCount ? (data?.customerCount | number) : 'N/A'}}</h2>
          <p class="sub-heading-1">Customers</p>
        </div>
      </div>
    </div>
    <div class="col-6 col-md-4 p-0 ps-3">
      <div class="snap-card">
        <i class="material-symbols-rounded icon-main">{{ZenIcons.LEN}}</i>
        <div class="d-flex flex-column">
          <h2 class="large">{{data?.lenCount ? (data?.lenCount | number) : 'N/A'}}</h2>
          <p class="sub-heading-1">LENs</p>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-sec">
    <div class="col-6 col-md-8 p-0 pe-3">
      <div class="snap-card">
        <i class="material-symbols-rounded icon-main">{{ZenIcons.PROPERTY}}</i>
        <div class="d-flex flex-column">
          <h2 class="large">{{data?.propertyCount ? (data?.propertyCount | number) : 'N/A'}}</h2>
          <p class="sub-heading-1">Properties</p>
        </div>
      </div>
    </div>
    <div class="col-6 col-md-4 p-0 ps-3">
      <div class="snap-card">
        <i class="material-symbols-rounded icon-main">{{ZenIcons.METER}}</i>
        <div class="d-flex flex-column">
          <h2 class="large">{{data?.meterCount ? (data?.meterCount | number) : 'N/A'}}</h2>
          <p class="sub-heading-1">Meters</p>
        </div>
      </div>
    </div>
  </div>
</div>
