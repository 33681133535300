import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {LabelledTagOption, Tag, TagType} from '../../../_modules/portfolio/_model/tag.model';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ZenDialogActionButton, ZenDialogDataModel, ZenDialogDataType} from '../zen-dialog/zen-dialog.component';
import {ZenDialogMsgService} from '../../_services/zen-dialog-msg.service';
import {TagService} from '../../_services/tag.service';
import {CustomerService} from '../../_zen-legacy-common/zen-common-services/_services/customer.service';
import {isValidItemList, isValidOption} from '../../_zen-legacy-common/_utils/validator-utils';
import {debounceTime, distinctUntilChanged, map, startWith, take} from 'rxjs/operators';
import {CommodityType} from '../../_zen-legacy-common/_models/commodity';
import {OrganizationManagementService} from '../../_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {UtilityService} from '../../_zen-legacy-common/zen-common-services/_services/utility.service';
import StateUtility, {Utility} from '../../_zen-legacy-common/_models/properties-settings';
import {UtilityAccountNumber} from '../../_zen-legacy-common/_models/utility-account-number';
import {orderBy} from '../../_zen-legacy-common/_utils/orderby.utils';
import {MetersV4Service} from '../../_services/v4/meters-v4.service';
import {MeterAddUpdateModel, MeterDetailsV4Model, MeterV4Model} from '../../../_modules/portfolio/_model/portfolio-meters.model';
import {ZenIconsEnum} from '../../_enums/zen-icons.enum';
import {InitialFiltersService} from '../../../_modules/portfolio/_services/_helpers/initial-filters.service';
import {ItemListV4Service} from '../../_services/v4/item-list-v4.service';
import {ItemListV4Model} from '../../_model/item-list-v4.model';
import {PropertyV4Service} from '../../_services/v4/property-v4.service';
import {CustomerServiceV4} from '../../_services/v4/customer-v4.service';
import {PfCustomerDetailsModel} from '../../../_modules/portfolio/_model/portfolio-customers.model';
import {zenHasError} from '../../_utils/zen-has-error.util';
import {AuthenticationService} from '../../_zen-legacy-common/zen-common-services/_services/authentication.service';
import {CustomerAndPropertyType, MeterType, PropertySubType} from '../../_zen-legacy-common/_enums/entity-types.enum';
import {MatSelectChange} from '@angular/material/select';
import {ChangeStatusObjectType, PortfolioTabsEnum} from '../../../_modules/portfolio/_enums/portfolio-tabs.enum';
import {PortfolioHelperService} from '../../../_modules/portfolio/_services/_helpers/portfolio-helper.service';
import {FilterClassNumber, Zone} from '../../_zen-legacy-common/zen-common-services/tili-services/models/matrix-pricing';
import {MatrixPricingDataService} from '../../../_modules/zen-market-pulse/_services/matrix-pricing-data.service';
import {stateSupportsZone} from '../../_zen-legacy-common/_utils/state-utils';
import {NgxPopperjsPlacements} from 'ngx-popperjs';
import {PropertyDetailsV4Model} from '../../_model/property-v4.model';
import {ZenTableMenuOption} from '../../_components/zen-mat-table/zen-mat-table.component';
import {ZenBaseWithTranslateComponent} from '../../_components/zen-base-with-translate/zen-base-with-translate.component';
import {TranslateService} from '@ngx-translate/core';
import {StateV4Service} from '../../_services/v4/state-v4.service';
import {ZenErrorMsgEnum} from '../../_enums/zen-error-msg.enum';

const PROCUREMENT_UNAVAILABLE_ZONE_ID = 4;

@Component({
  selector: 'app-zen-edit-meter-dialog',
  templateUrl: './zen-edit-meter-dialog.component.html',
  styleUrls: ['./zen-edit-meter-dialog.component.scss']
})
export class ZenEditMeterDialogComponent extends ZenBaseWithTranslateComponent implements OnInit {
  form: UntypedFormGroup;
  ZenIcons = ZenIconsEnum;
  popperPlacements = NgxPopperjsPlacements;

  // state
  stateOptions: string[] = [];
  filteredStateOptions: Observable<string[]>;

  serviceOptions = [{label: 'Electric', value: CommodityType.Electricity}, {label: 'Gas', value: CommodityType.Gas}];
  meterTypeOptions = Object.values(MeterType).sort((a, b) => a.localeCompare(b));
  CommodityType = CommodityType;

  // tags
  tagFilterCtrl = new UntypedFormControl('');
  tagOptions: LabelledTagOption[] = [];
  selectedTags: Partial<Tag>[] = [];

  skipActiveStatusPopUps = false;
  isEditMode: boolean;
  disableButton: boolean;
  ZenDialogDataType = ZenDialogDataType;
  TagType = TagType;
  appearance: MatFormFieldAppearance = 'outline';

  // Customer search
  customerCtrl = new UntypedFormControl(null, [Validators.required, isValidItemList()]);
  public filteredCustomers: ReplaySubject<ItemListV4Model[]> = new ReplaySubject();
  searchableCustomers: ItemListV4Model[] = [];

  // Utility
  stateUtilities: StateUtility[];
  utilities: Utility[] = [];

  zoneOptions: FilterClassNumber[] = [];
  STATES_WITH_ZONE = ['NY', 'TX', 'MA'];

  /**
   * Is true when the user selects a different utility than the other meter utilities on the same property
   */
  selectedDifferentUtility: boolean;
  currentMetersInProperty: MeterV4Model[] = [];

  // UAN
  uan1: UtilityAccountNumber;
  uan2: UtilityAccountNumber;
  uan3: UtilityAccountNumber;

  // UAN Masks
  uan1Mask;
  uan2Mask;
  uan3Mask;

  // UAN Initial Values
  uan1InitialValue;
  uan2InitialValue;
  uan3InitialValue;

  // UAN Error Message
  uan1ErrorMessage;
  uan2ErrorMessage;
  uan3ErrorMessage;

  // Meter api
  meterDet: MeterDetailsV4Model;

  // LEN search
  lenCtrl = new UntypedFormControl(null, [Validators.required, isValidItemList()]);
  searchableLens: ItemListV4Model[] = [];
  public filteredLens: ReplaySubject<ItemListV4Model[]> = new ReplaySubject();

  // Property search
  propertyCtrl = new UntypedFormControl(null, [Validators.required, isValidItemList()]);
  searchableProperties: ItemListV4Model[] = [];
  public filteredProperties: ReplaySubject<ItemListV4Model[]> = new ReplaySubject();

  // Error Msg
  errorMsg: string;

  // Show more
  showMore: boolean;
  lockedCustomerId: number;
  lockedLenId: string;
  lockedPropertyId: string;

  // Details
  customerDet: PfCustomerDetailsModel;
  propertyDet: PropertyDetailsV4Model;

  // Associated with section - is hidden if customerId, lenId and propertyId known.
  hideAssociatedWithSection: boolean;
  disableActivationStatus = false;
  activatedMeter = false;
  deactivatedMeter = false;

  // Used to disable the commodity type field.
  lockedCommodity: CommodityType;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ZenDialogDataModel,
              public dialogRef: MatDialogRef<ZenEditMeterDialogComponent>,
              private dialog: MatDialog,
              private zenDialogSvc: ZenDialogMsgService,
              private tagService: TagService,
              private formBuilder: UntypedFormBuilder,
              private utilityService: UtilityService,
              private meterV4Svc: MetersV4Service,
              private customerService: CustomerService,
              private custV4Svc: CustomerServiceV4,
              private propV4Svc: PropertyV4Service,
              private pfFiltersSvc: InitialFiltersService,
              private orgMgtSvc: OrganizationManagementService,
              private itemListV4Svc: ItemListV4Service,
              public authSvc: AuthenticationService,
              public translateSvc: TranslateService,
              private pfHelpSvc: PortfolioHelperService,
              private stateV4Svc: StateV4Service,
              private matrixPricingDataSvc: MatrixPricingDataService) {
    super(translateSvc);
  }

  ngOnInit(): void {
    if (this.data.data) {
      this.isEditMode = Boolean(this.data.data && this.data.data.id);
      this.lockedCustomerId = Number(this.data.data.lockedCustomerId);
      this.lockedLenId = this.data.data.lockedLenId;
      this.lockedPropertyId = this.data.data.lockedPropertyId;
      this.lockedCommodity = this.data.data.lockedCommodity;
      this.hideAssociatedWithSection = Boolean(this.lockedCustomerId && this.lockedLenId && this.lockedPropertyId);
      this.skipActiveStatusPopUps = this.data.data.skipActiveStatusPopUps;
    }

    this.loadForm();
    this.init();
  }

  displayFn(item: ItemListV4Model): string {
    return item && item.value ? item.value : '';
  }

  get controls() {
    return this.form.controls;
  }

  get customerId() {
    return this.customerCtrl.value ? this.customerCtrl.value?.key : null;
  }

  get propertyId() {
    return this.propertyCtrl.value ? this.propertyCtrl.value?.key : null;
  }

  get lenId() {
    return this.lenCtrl.value ? this.lenCtrl.value?.key : null;
  }

  get getLockedCustomer(): ItemListV4Model {
    return this.customerCtrl.value;
  }

  get getLockedLen(): ItemListV4Model {
    return this.lenCtrl.value;
  }

  get getLockedProperty(): ItemListV4Model {
    return this.propertyCtrl.value;
  }

  handlePropertyChange(propertyId: number) {
    this.showMore = false;
    this.enableFormCheck();
    this.propV4Svc.getPropertyById(this.customerId, propertyId).subscribe(selectedProp => {
      this.propertyDet = selectedProp;
      if (selectedProp) {
        this.controls.propertyStreet1.setValue(selectedProp.street1);
        this.controls.propertyCity.setValue(selectedProp.city);
        this.controls.propertyState.setValue(selectedProp.state);
        this.controls.propertyZip.setValue(selectedProp.zip);
        // When adding a new Meter
        if (!this.isEditMode) {
          // If new meter, set street2 to null and enable it
          this.controls.street2.setValue(null);
          this.controls.street2.enable();
        } else { // When editing an existing Meter
          if (this.meterDet?.street2 && this.meterDet?.street2 !== '') {
            this.controls.street2.setValue(this.meterDet?.street2);
          } else {
            this.controls.street2.setValue(null);
          }
        }
      }

      this.controls.propertyStreet1.disable();
      this.controls.propertyCity.disable();
      this.controls.propertyState.disable();
      this.controls.propertyZip.disable();

      if (this.showMeterType) {
        this.controls.type.setValidators([Validators.required]);
        this.controls.type.updateValueAndValidity();
      }

      this.handleZipChange(selectedProp.zip, selectedProp.state);
    }, error => {
      console.log('Error: Get property by id ', error);
      this.zenDialogSvc.openToast(false);
    });
  }

  loadForm(): void {
    this.stateV4Svc.getAllCountryStates().subscribe({
      next: states => {
        this.stateOptions = states.map(s => s.key);
      },
      error: err => {
        this.zenDialogSvc.openToast(false, err?.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      }
    });

    this.form = this.formBuilder.group({
      active: new UntypedFormControl(true, []),
      label: new UntypedFormControl(null, []),
      propertyStreet1: new UntypedFormControl(null, [Validators.required]),
      street2: new UntypedFormControl(null, []),
      propertyState: new UntypedFormControl(null, [Validators.required, isValidOption(this.stateOptions)]),
      propertyCity: new UntypedFormControl(null, [Validators.required]),
      propertyZip: new UntypedFormControl(null, [Validators.required]),
      type: new UntypedFormControl(null),
      // service details
      commodityType: new UntypedFormControl(null, [Validators.required]),
      zoneId: new UntypedFormControl(),
      utilityId: new UntypedFormControl(null, [Validators.required]),
      utilityAccountNum1: new UntypedFormControl(null, [Validators.required]),
      utilityAccountNum2: new UntypedFormControl(null, []),
      utilityAccountNum3: new UntypedFormControl(null, [])
    });

    this.setValidationSubscriptions('utilityAccountNum1', 1);
    this.setValidationSubscriptions('utilityAccountNum2', 2);
    this.setValidationSubscriptions('utilityAccountNum3', 3);

    this.form.disable();

    this.customerCtrl.valueChanges
      .subscribe((customer: ItemListV4Model) => {
        if (customer && customer.key) {
          if (!this.isEditMode) { // In the add mode only user able to change the customer
            this.lenCtrl.setValue(null);
          }

          // Fetch customer details
          this.custV4Svc.getCustomerDetails(customer.key).subscribe({
            next: custDet => {
              this.customerDet = custDet;
            }, error: error => {
              console.log('Error: customer det ', error);
              this.zenDialogSvc.openToast(false);
            }
          });

          this.onCustomerChange(customer.key);
          this.enableFormCheck();
        }
      });

    this.propertyCtrl.valueChanges
      .pipe(debounceTime(500))
      .subscribe((property: ItemListV4Model) => {
        this.filterProperties();
        if (property && property.key) {
          this.handlePropertyChange(property.key);
        }
      });

    this.lenCtrl.valueChanges
      .subscribe((len: ItemListV4Model) => {
        this.filterLens();
        if (len) {
          if (len.key) {
            this.enableFormCheck();
            this.loadCustomerProperties(len.key, null);
          }
        }
      });

    this.controls.propertyState.valueChanges
      .subscribe(() => {
        this.handleStateSelection();
      });

    this.controls.commodityType.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(100))
      .subscribe(() => {
        this.handleStateSelection();
        this.setDefaultUtility();
      });

    // listen to changes in State, and filter options accordingly
    this.filteredStateOptions = this.controls.propertyState.valueChanges.pipe(
      startWith(''),
      map(query => this.filterStates(query)),
    );
  }

  setValidationSubscriptions(fieldName: string, accountOrder: number) {
    this.form.get(fieldName).valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
      if (this.controls.utilityId.value && val) {
        this.updateValidators(val, accountOrder, false)
      } else if (accountOrder === 1 && this.controls.utilityId.value && this.controls.commodityType.value === 'GAS') {
        this.updateValidators(val, accountOrder, true)
      }
    });
  }

  protected filterLens() {
    let search = this.lenCtrl.value;
    if (!search || typeof search === 'string') {
      this.loadCustomerLens(this.customerId, search);
    }
  }

  protected filterProperties() {
    let search = this.propertyCtrl.value;
    if (!search || typeof search === 'string') {
      this.loadCustomerProperties(this.lenId, search);
    }
  }

  loadCustomerLens(customerId, search?: string): void {
    // Hiding Associated with section if all values are known and skipping this API call.
    if (customerId && !this.hideAssociatedWithSection && this.meterDet) {
      this.itemListV4Svc.getCustomerLenItemList(customerId, [this.lockedLenId], search, this.meterDet.active)
        .pipe(distinctUntilChanged(), debounceTime(250))
        .subscribe({
          next: result => {
            this.searchableLens = result;
            this.setLenListItems();
          }, error: () => {
            this.zenDialogSvc.openToast(false);
          }
        });
    } else {
      // To support the flow
      this.searchableLens = [{key: this.lockedLenId, value: null}];
      this.setLenListItems();
    }
  }

  setLenListItems() {
    this.filteredLens.next(this.searchableLens); // Update autocomplete dropdown options

    if (this.lockedLenId && !this.lenCtrl.value?.key) { // Initial lockedLenValue check and value update
      const len = this.searchableLens.find(c => c.key === this.lockedLenId);
      if (len) {
        this.lenCtrl.setValue(len);
      }
    }
  }

  /** For known customer & for customer users (item-list API wont work for customer users).
   *  So, adding locked customer into list to support UI. */
  addLockedCustomerInSearchList() {
    this.custV4Svc.getCustomerDetails(this.lockedCustomerId).subscribe({
      next: custDet => {
        const _customer = {value: custDet.companyName, key: custDet.customerId};
        this.searchableCustomers.push(_customer);
        this.filteredCustomers.next(this.searchableCustomers);
        this.customerCtrl.setValue(_customer);
      }, error: error => {
        console.log('Error: customer det ', error);
        this.zenDialogSvc.openToast(false);
      }
    });
  }

  loadCustomerProperties(lenId: string, search?: string): void {
    // Hiding Associated with section if all values are known and skipping this API call.
    if (this.customerId && lenId && !this.hideAssociatedWithSection) {
      this.itemListV4Svc.getCustomerPropertyItemList(this.customerId, lenId, search, this.meterDet?.utilityId, this.meterDet?.commodityType, this.meterDet?.propertyState, this.meterDet?.active)
        .pipe(distinctUntilChanged(), debounceTime(250))
        .subscribe({
          next: result => {
            this.searchableProperties = result.sort((a, b) => a.value.localeCompare(b.value));
            this.setPropertyListItems();
            // When there is no lockedPropertyId setting property ctrl value - only on init load, not while doing search
            if (!this.lockedPropertyId && search == null) {
              const property = this.searchableProperties.find(p => p.key === this.meterDet.propertyId);
              if (property) {
                // setting emitEvent false to avoid valueChanges subscription
                this.propertyCtrl.setValue(property, {emitEvent: false});
                this.handlePropertyChange(this.meterDet.propertyId);
              }
            }
          }, error: () => {
            this.zenDialogSvc.openToast(false);
          }
        });
    } else {
      // To support the flow
      this.searchableProperties = [{key: this.lockedPropertyId, value: null}];
      this.setPropertyListItems();
    }
  }

  setPropertyListItems() {
    this.filteredProperties.next(this.searchableProperties); // Update autocomplete dropdown options

    if (this.lockedPropertyId && !this.propertyCtrl.value?.key) { // Handled locked property
      const property = this.searchableProperties.find(p => p.key === this.lockedPropertyId);
      if (property) {
        this.propertyCtrl.setValue(property);
      }
    }
  }

  loadPropertyMeters() {
    if (this.lockedCustomerId && this.lockedPropertyId) {
      this.meterV4Svc.getAllMetersInProperty(this.lockedCustomerId, Number(this.lockedPropertyId))
        .subscribe({
          next: result => {
            if (result) {
              this.currentMetersInProperty = result;
              this.setDefaultUtility();
            }
          }, error: error => {
            console.log('Error: Get property meters  ', error);
            this.zenDialogSvc.openToast(false);
          }
        });
    }
  }

  toggleShowMore() {
    this.showMore = !this.showMore;
  }

  init() {
    if (this.isEditMode) {
      combineLatest([
        this.orgMgtSvc.getStateUtilities(false),
        this.meterV4Svc.getMeterDetailsById(this.data.data.customerId, this.data.data.propertyId, this.data.data.id),
        this.meterV4Svc.bulkActivationCheckMeters(this.data.data.customerId, this.data.data.propertyId, [this.data.data.id])
      ]).pipe(take(1)).subscribe(([stateUtilities, meterDet, activationCheck]) => {
        this.meterDet = meterDet;
        this.addLockedCustomerInSearchList();
        this.loadPropertyMeters();

        this.stateUtilities = stateUtilities;
        this.disableActivationStatus = !activationCheck.activatable;
        if (this.isEditMode && this.meterDet) {
          this.form.patchValue(this.meterDet);
          this.controls.utilityAccountNum1.setValue(this.meterDet.utilityAccountNum1);
          this.controls.utilityAccountNum2.setValue(this.meterDet?.utilityAccountNum2);
          this.controls.utilityAccountNum3.setValue(this.meterDet?.utilityAccountNum3);
          this.handleUtilityChange();
          this.enableFormCheck();
        }
        this.selectedTags = meterDet.tags;
        this.enableFormCheck();
      }, error => {
        console.log('Error: Get meter details by Id ', error);
        this.zenDialogSvc.openToast(false);
      });
    } else {
      this.addLockedCustomerInSearchList();
      this.loadPropertyMeters();
      this.getStateUtilitiesFn();
      this.enableFormCheck();
    }
  }

  onCustomerChange(customerId: number) {
    this.initTagOptions();
    this.loadCustomerLens(customerId);
  }

  handleSelectionChange(event: MatSelectChange) {
    if (this.isEditMode) {
      if (event.source.value === false) {
        this.activatedMeter = false;
        if (this.meterDet === undefined || this.meterDet?.active === true) {
          this.deactivatedMeter = true;
        }
      } else {
        this.deactivatedMeter = false;
        if (this.meterDet === undefined || this.meterDet?.active === false) {
          this.activatedMeter = true;
        }
      }
    }
  }

  enableFormCheck() {
    if (this.customerCtrl.value && this.lenCtrl.value && this.propertyCtrl.value) {
      this.form.enable();
      if (this.disableActivationStatus) {
        this.controls.active.disable();
      }
      // Disable commodity type
      if (this.lockedCommodity) {
        this.controls.commodityType.disable();
      }
    } else {
      this.form.disable();
    }
    this.controls.propertyStreet1.disable();
    this.controls.propertyCity.disable();
    this.controls.propertyState.disable();
    this.controls.propertyZip.disable();
  }

  initTagOptions() {
    this.tagService.getTagOptions(TagType.METER, this.customerId).subscribe(options => {
      this.tagOptions = options.map(opt => {
        return {...opt, label: this.tagService.getTagOptionLabel(opt)};
      }).sort((a, b) => a.label.localeCompare(b.label));
    }, err => {
      console.log('Error: Get meter tab options ', err);
      this.zenDialogSvc.openToast(false);
    });
  }

  getStateUtilitiesFn() {
    this.orgMgtSvc.getStateUtilities(false).subscribe(stateUtilities => {
      this.stateUtilities = stateUtilities;
    }, error => {
      console.log('Error: Get state utilities ', error);
      this.zenDialogSvc.openToast(false);
    });
  }

  handleStateSelection() {
    const _state = this.controls.propertyState.value;
    const _isElectric = this.controls.commodityType.value === CommodityType.Electricity;
    const _isGas = this.controls.commodityType.value === CommodityType.Gas;
    this.utilities = [];

    if (_state && (_isElectric || _isGas)) {
      const _stateUtilities = this.stateUtilities?.find(su => su?.stateId === _state);

      if (_stateUtilities?.utilities) {
        this.utilities.push(..._stateUtilities.utilities
          .filter(u => (u.elecProvider && _isElectric) || (u.gasProvider && _isGas))
          .sort((a, b) => a.utilityName.localeCompare(b.utilityName)));
      }
    }
  }

  setDefaultZone() {
    this.form.controls.zoneId.setValue(this.meterDet?.zoneId);
  }

  setDefaultUtility() {
    if (this.currentMetersInProperty.length > 0) {
      const selectedCommodityType = this.controls.commodityType.value;
      if (!selectedCommodityType) {
        this.controls.utilityId.setValue(null);
      }
      // Get the first meter with the same commodity type and set the default utility to the same
      const meter = this.currentMetersInProperty.find(m => m.commodityType === selectedCommodityType);
      if (meter) {
        this.controls.utilityId.setValue(meter.utilityId);
        this.handleUtilityChange();
      } else {
        this.controls.utilityId.setValue(null);
      }
    }
  }

  checkDifferentUtility() {
    const selectedUtilityId: number = this.controls.utilityId.value;
    const selectedCommodityType: CommodityType = this.controls.commodityType.value;
    if (selectedUtilityId && selectedCommodityType && this.currentMetersInProperty) {
      // Get the meters with the same commodity type
      let metersWithSameCommodityType = this.currentMetersInProperty.filter(m => m.commodityType === selectedCommodityType);
      // Special case: If we're editing and there is only one meter (of the commodity type) in the property, it's the current meter
      if (this.isEditMode && metersWithSameCommodityType.length === 1) {
        this.selectedDifferentUtility = false;
        return;
      }
      // If the selected utility id is different from all the meters' utility id, then set selectedDifferentUtility to true
      // Good data: three meters, utilities A, A, and A.
      // If you select utility B, it's different from all three, so set selectedDifferentUtility to true.
      // Say you have bad data and you have a property with three meters under utilities A, B, and C
      // It defaults to selection A, but if you select B, it's not different from all three, but it's still different from the other two.
      // So selectedDifferentUtility should be true. That's why we use `some` and not `every`.
      this.selectedDifferentUtility = metersWithSameCommodityType.some(m => m.utilityId !== selectedUtilityId);
    } else {
      this.selectedDifferentUtility = false;
    }
  }

  handleAddTag(tag: Partial<Tag>) {
    this.selectedTags.push(tag);
    this.tagFilterCtrl.reset('');
  }

  handleRemoveTag(tag: Partial<Tag>) {
    this.selectedTags = this.selectedTags.filter(t => t.name !== tag.name || t.tagGroup?.name !== tag.tagGroup?.name);
  }

  filterStates(query: string) {
    let filteredStates = [...this.stateOptions];
    let search = this.controls.propertyState.value;
    if (!search) {
      return filteredStates;
    } else {
      search = search.toLowerCase();
      return this.stateOptions.filter(s => s.toLowerCase().includes(search));
    }
  }

  handleUtilityChange() {
    this.setValidationOnAllUANs(this.controls.utilityId.value, false, false);
    this.checkDifferentUtility();
  }

  // Check if we have validation data for this utilityId
  setValidationOnAllUANs(utilityId: number, clear = false, overwriteUtilityId = true) {
    if (utilityId) {
      if (clear) {
        // Clear UANs
        this.controls.utilityAccountNum1.markAsUntouched();
        this.controls.utilityAccountNum2.markAsUntouched();
        this.controls.utilityAccountNum1.reset();
        this.controls.utilityAccountNum2.reset();
        this.controls.utilityAccountNum3.reset();
      }
      if (overwriteUtilityId) {
        this.controls.utilityId.setValue(utilityId);
      }
      this.utilityService.getUtilityAccountNumbers(utilityId, this.controls.commodityType.value).subscribe(uans => {
        if (uans.length) {
          const [uan1, uan2, uan3] = orderBy(uans, 'accountOrder');
          this.uan1 = uan1;
          this.uan2 = uan2;
          this.uan3 = uan3;
          // Set the validators and masks for each UAN
          [this.uan1, this.controls.utilityAccountNum1, this.uan1Mask]
            = this.setUANValidation(this.uan1, this.controls.utilityAccountNum1, this.uan1Mask, 1);
          [this.uan2, this.controls.utilityAccountNum2, this.uan2Mask]
            = this.setUANValidation(this.uan2, this.controls.utilityAccountNum2, this.uan2Mask, 2);
          [this.uan3, this.controls.utilityAccountNum3, this.uan3Mask]
            = this.setUANValidation(this.uan3, this.controls.utilityAccountNum3, this.uan3Mask, 3);
          // Update the UAN controls with the new validators
          this.controls.utilityAccountNum1.updateValueAndValidity();
          this.controls.utilityAccountNum2.updateValueAndValidity();
          this.controls.utilityAccountNum3.updateValueAndValidity();
          this.setUanInitialValues();
          this.setUanErrorMessage(uan1, 1);
          this.setUanErrorMessage(uan2, 2);
          this.setUanErrorMessage(uan3, 3);
        } else {
          if (!this.isEditMode || !this.meterDet) {
            this.clearUanValidationAndValues();
          } else {
            // After changing the utility from the edit meter dialog, we should clear/update the validation w.r.t the uan API result.
            this.clearUanValidationAndValues(false);
          }
        }
      }, err => {
        // swallow this, if we don't have any validation that's ok
        console.log('Error: Get UAN details ', err);
        this.zenDialogSvc.openToast(false);
      });
    }
  }

  // Set the validators and masks for each UAN
  setUANValidation(uan: UtilityAccountNumber, uanControl: AbstractControl, uanMask, accountOrder?: number) {
    if (uan) {
      let validators = [];
      if (uan.required) {
        validators.push(Validators.required);
      }
      uanMask = {mask: this.getFormattedMask(uan), lazy: false, placeholderChar: '_'};
      if (uan.validationExpression) {
        validators.push(Validators.pattern(uan.validationExpression));
      }
      uanControl.setValidators(validators);
    } else if (accountOrder === 1) {
      let validators = [];
      validators.push(Validators.required);
      uanControl.setValidators(validators);
    } else {
      uanControl.setValidators([]);
    }
    return [uan, uanControl, uanMask];
  }

  // The below method handle paste event, and removed special chars from the paste value.
  handlePaste(event, fieldName: 'utilityAccountNum1' | 'utilityAccountNum2' | 'utilityAccountNum3') {
    event.preventDefault();
    // @ts-ignore
    let pasteValue = (event?.clipboardData || window?.clipboardData).getData('text');
    if (pasteValue) {
      let newVal = pasteValue?.replace(/[^\w\s]/gi, '')?.replace(/_/gi, ''); // Removing special chars
      if (newVal && this.form.get(fieldName)) {
         this.form.get(fieldName).setValue(newVal, {emitEvent: false});
      }
    }
  }

  // Set the initial values for each UAN to be used in checks later
  setUanInitialValues() {
    this.uan1InitialValue = this.getInitialUanValue(this.uan1, this.uan1Mask);
    this.uan2InitialValue = this.getInitialUanValue(this.uan2, this.uan2Mask);
    this.uan3InitialValue = this.getInitialUanValue(this.uan3, this.uan3Mask);
  }

  // Clear the utility and UAN information
  clearUtilityInformation() {
    this.controls.utilityId.reset();
    this.clearUanValidationAndValues();
  }

  // Clear out all UAN information regarding UAN masks, UAN validators, and the UAN controls
  clearUanValidationAndValues(resetValues = true) {
    this.uan1 = null;
    this.uan2 = null;
    this.uan3 = null;
    this.uan1Mask = {mask: String, placeholderChar: ''};
    this.uan2Mask = {mask: String, placeholderChar: ''};
    this.uan3Mask = {mask: String, placeholderChar: ''};
    this.uan1InitialValue = null;
    this.uan2InitialValue = null;
    this.uan3InitialValue = null;
    this.controls.utilityAccountNum1.setValidators([Validators.required]);
    this.controls.utilityAccountNum2.setValidators([]);
    this.controls.utilityAccountNum3.setValidators([]);

    if (resetValues) {
      setTimeout(() => {
        this.controls.utilityAccountNum1.reset();
        this.controls.utilityAccountNum2.reset();
        this.controls.utilityAccountNum3.reset();
      }, 1);
    }
  }

  // Format the mask for the UAN and add any escape characters needed
  getFormattedMask(uan: UtilityAccountNumber) {
    let mask = uan.mask;
    if (uan.minLength !== uan.maxLength) {
      mask = mask.substring(0, uan.minLength).concat('[', mask.substring(uan.minLength), ']');
    }
    return mask.replace(/0/g, '\\0');
  }

  submit(action: ZenDialogActionButton | ZenTableMenuOption) {
    this.errorMsg = null; // to clear error message
    if (action.label === 'Cancel') {
      this.dialogRef.close();
    } else {
      this.form.markAllAsTouched();
      if (this.form.valid) {
        // If selectedDifferentUtility is true, show confirmation dialog
        if (this.selectedDifferentUtility) {
          this.zenDialogSvc.showConfirmationDialog(
            'Conflicting ' + this._translations?.nomenclature?.utilityShort,
            `This utility selection conflicts with other meters under this property. Confirming this action will change other meters to this utility. Would you like to continue?`,
            () => this.setDefaultUtility(),
            () => this.saveMeter(action)
          );
        } else if (this.selectedDifferentZone) {
          this.zenDialogSvc.showConfirmationDialog(
            'Conflicting Zone',
            `This zone selection conflicts with other meters under this property. Confirming this action will change other meters to this zone. Would you like to continue?`,
            () => this.setDefaultZone(),
            () => this.saveMeter(action)
          );
        } else {
          this.saveMeter(action);
        }
      }
    }
  }

  get selectedDifferentZone(): boolean {
    const selectedZoneId: number = this.controls.zoneId.value;
    const selectedCommodityType: CommodityType = this.controls.commodityType.value;
    if (selectedZoneId && selectedCommodityType && this.STATES_WITH_ZONE.includes(this.propertyDet?.state) && this.currentMetersInProperty) {
      // Get the meters with the same commodity type
      let metersWithSameCommodityType = this.currentMetersInProperty.filter(m => m.commodityType === selectedCommodityType);
      // Special case: If we're editing and there is only one meter (of the commodity type) in the property, it's the current meter
      if (this.isEditMode && metersWithSameCommodityType.length === 1) {
        return false;
      } else {
        // If the selected zone id is different from all the meters' -> TRUE
        // Good data: three meters, zones A, A, and A.
        // If you select zone B, it's different from all three, so return -> TRUE.
        // Say you have bad data, and you have a property with three meters under zone A, B, and C
        // It defaults to selection A, but if you select B, it's not different from all three, but it's still different from the other two.
        // So return should be true. That's why we use `some` and not `every`.
        return metersWithSameCommodityType.some(m => m.zoneId !== selectedZoneId);
      }
    } else {
      return false;
    }
  }

  saveMeter(action: ZenDialogActionButton | ZenTableMenuOption) {
    this.form.markAllAsTouched();
    this.customerCtrl.markAllAsTouched();
    this.lenCtrl.markAllAsTouched();
    this.propertyCtrl.markAllAsTouched();
    let _data = {
      propertyId: this.propertyId,
      tags: this.selectedTags,
      ...this.form.value
    } as MeterAddUpdateModel;

    _data.activationChange = {active: _data.active};

    if (this.form.valid) {
      if (this.isEditMode) { // Edit Meter
        // We should send pathVariable existing propertyId from the meter details to support new propertyId edit/update/
        this.handleSubscribe(this.meterV4Svc.updateMeter(this.customerId, this.meterDet.propertyId, this.meterDet.id, _data), action);
      } else if (!this.isEditMode) { // Add Meter
        this.handleSubscribe(this.meterV4Svc.addMeter(this.customerId, this.propertyId, _data), action);
      }
    }
  }

  handleSubscribe(sub: Observable<MeterDetailsV4Model>, action: ZenDialogActionButton | ZenTableMenuOption) {
    this.disableDialogActions(true);
    sub.subscribe({
      next: meter => {
        action.command(meter);
        if (this.activatedMeter && !this.skipActiveStatusPopUps) {
          this.pfHelpSvc.openSuccessGuidance(this.pfHelpSvc.getReactivateSuccessBodyText([meter], ChangeStatusObjectType.METER));
          this.pfHelpSvc.handleAfterChangeStatus([meter], PortfolioTabsEnum.PROPERTIES, meter.customerId, meter.lenId, meter.propertyId);
        } else if (this.deactivatedMeter && !this.skipActiveStatusPopUps) {
          this.pfHelpSvc.openSuccessGuidance(this.pfHelpSvc.getDeactivateSuccessBodyText([meter], ChangeStatusObjectType.METER));
          this.pfHelpSvc.handleAfterChangeStatus([meter], PortfolioTabsEnum.PROPERTIES, meter.customerId, meter.lenId, meter.propertyId);
        } else {
          this.zenDialogSvc.openToast(true);
        }
        this.disableDialogActions(false);
        this.pfFiltersSvc.refreshFilters.next(true);
        this.dialogRef.close(meter);
      }, error: e => {
        console.log('Error: Meter Add/Update ', e);
        if (e.error && e.error.message) {
          this.errorMsg = e.error.message
        }
        this.disableDialogActions(false);
        this.zenDialogSvc.openToast(false);
      }
    });
  }

  disableDialogActions(disable: boolean) {
    this.disableButton = disable;
  }

  get showMeterType(): boolean {
    return Boolean(this.propertyDet?.type === CustomerAndPropertyType.REAL_ESTATE && this.propertyDet?.subType === PropertySubType.MULTI_FAMILY);
  }

  hasError(formControlName: string, formErrorName: string): boolean {
    return zenHasError(this.form, formControlName, formErrorName);
  }

  // Checks the UANs to reset validation or clear validation if UAN being changed is now blank
  updateValidators(currentValue, accountOrder: number, natGasMeterNoUan: boolean) {
    if (natGasMeterNoUan) {
      this.controls.utilityAccountNum1.reset();
      [this.uan1, this.controls.utilityAccountNum1, this.uan1Mask]
        = this.setUANValidation(this.uan1, this.controls.utilityAccountNum1, this.uan1Mask, 1);
    } else {
      switch (accountOrder) {
        case 1:
          if (currentValue === this.uan1InitialValue) {
            this.controls.utilityAccountNum1.reset();
          }
          [this.uan1, this.controls.utilityAccountNum1, this.uan1Mask]
            = this.setUANValidation(this.uan1, this.controls.utilityAccountNum1, this.uan1Mask, 1);
          break;
        case 2:
          if (currentValue === this.uan2InitialValue) {
            this.controls.utilityAccountNum2.reset();
          }
          [this.uan2, this.controls.utilityAccountNum2, this.uan2Mask]
            = this.setUANValidation(this.uan2, this.controls.utilityAccountNum2, this.uan2Mask, 2);
          break;
        case 3:
          if (currentValue === this.uan3InitialValue) {
            this.controls.utilityAccountNum3.reset();
          }
          [this.uan3, this.controls.utilityAccountNum3, this.uan3Mask]
            = this.setUANValidation(this.uan3, this.controls.utilityAccountNum3, this.uan3Mask, 3);
          break;
      }
    }
  }

  // Calculate the initial value for each provided UAN according to the mask and placeholder character
  getInitialUanValue(uan: UtilityAccountNumber, uanMask): String {
    let initialValue;
    if (uanMask?.mask) {
      initialValue = JSON.stringify(uanMask?.mask)?.replace(/\\/g, '');
      initialValue = initialValue?.replace(/\*/g, uanMask.placeholderChar);
    }
    return initialValue;
  }

  get isZoneSelectorDisabled(): boolean {
    return !this.form.controls.propertyZip.value
      || this.form.controls.zoneId.value === PROCUREMENT_UNAVAILABLE_ZONE_ID
      || this.form.controls.propertyZip.value.replace(/-|_/g, '').length < 5;
  }

  handleZipChange(propertyZip, propertyState) {
    const zip = propertyZip.replace(/-|_/g, '').substring(0, 5);
    if (zip.length < 5 && this.zoneOptions.length) {
      this.zoneOptions = [];
    }
    if (zip.length >= 5 && stateSupportsZone(propertyState)) {
      if (!this.isEditMode) {
        this.initAddMeterZonesDropdown(propertyState, zip);
      } else {
        this.initEditMeterZonesDropdown();
      }
    }
  }

  initAddMeterZonesDropdown(state: string, zip?: string) {
    // Get all zone options for state
    this.loadZones(state).subscribe(zones => {
      this.zoneOptions = [];
      zones.forEach((zone, idx) => {
        this.zoneOptions.push({
          label: zone.name,
          value: zone.id,
        })
      });

      // Initialize dropdown to mapped zipcode (if we have 1 mapping)
      this.loadZones(state, zip).subscribe(zipZones => {
        if (zipZones.length === 1) {
          // If procurement unavailable, we want to add this "option" to our list of zones for state
          if (zipZones[0].id === PROCUREMENT_UNAVAILABLE_ZONE_ID) {
            this.zoneOptions.push({ label: zipZones[0].name, value: zipZones[0].id });
          }
          // While creating a new meter on a new property, the zone should be auto populated based on property zip code.
          this.form.controls.zoneId.setValue(zipZones[0].id);
        }
      });
    });
  }

  initEditMeterZonesDropdown() {
    this.loadZonesMeterId(this.meterDet?.id).subscribe(zipZones => {
      this.zoneOptions = [];
      zipZones.forEach((zipZone, idx) => {
        if (zipZone.id !== PROCUREMENT_UNAVAILABLE_ZONE_ID) {
          this.zoneOptions.push({
           label: zipZone.name,
           value: zipZone.id,
          })
        }
        if (this.zoneOptions.some(zone => zone.value === this.meterDet.zoneId)) {
          // While editing a existing meter, the zone should be auto populated based the previously
          // chosen zone if that is an option in the current zone options
          this.form.controls.zoneId.setValue(this.meterDet.zoneId);
        }
      });
    })
  }

  loadZones(state: string, zip?: string): Observable<Zone[]> {
    return this.matrixPricingDataSvc.getZipCodeZoneList(state, zip).pipe(debounceTime(10), distinctUntilChanged());
  }

  loadZonesMeterId(meterId: number): Observable<Zone[]> {
    return this.matrixPricingDataSvc.getZipCodeZoneListByMeterId(meterId).pipe(debounceTime(10), distinctUntilChanged());
  }

  setUanErrorMessage(uan: UtilityAccountNumber, accountOrder: number) {
    let errorMessage: string;
    if (uan) {
      // If current UAN has the hint field filled in show it for the validation error message
      if (uan.hint && uan.hint.trim()) {
        errorMessage = uan.hint;
        // Otherwise, show the validation error message for the current UAN
      } else {
        errorMessage = uan.validationErrorMessage;
      }
    } else {
      errorMessage = '';
    }
    switch (accountOrder) {
      case 1:
        this.uan1ErrorMessage = errorMessage;
        break;
      case 2:
        this.uan2ErrorMessage = errorMessage;
        break;
      case 3:
        this.uan3ErrorMessage = errorMessage;
        break;
    }
  }

  getUanStyleClasses(uan: UtilityAccountNumber, fieldName: string, mbspacing: string) {
    return this.hasError(fieldName, 'pattern') ?
      uan ? mbspacing : 'remove-trailing-uan-space' :
      uan ? 'form-group' : mbspacing;
  }
}
